@charset "UTF-8";
.scrollbar {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.scrollbar::-webkit-scrollbar * {
  background: transparent;
  /* hide scroolbar */
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(25, 25, 25, 0.4);
  border-radius: 0px;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.2);
  background: rgba(50, 50, 50, 0.2);
  border-radius: 0px;
}

.iconpicker-items::-webkit-scrollbar * {
  background: transparent;
  /* hide scroolbar */
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.iconpicker-items::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.iconpicker-items::-webkit-scrollbar-thumb {
  background: rgba(25, 25, 25, 0.4);
  border-radius: 0px;
}
.iconpicker-items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.2);
  background: rgba(50, 50, 50, 0.2);
  border-radius: 0px;
}

*::-webkit-scrollbar * {
  background: transparent;
  /* hide scroolbar */
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-thumb {
  background: rgba(25, 25, 25, 0.4);
  border-radius: 0px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(50, 50, 50, 0.2);
  background: rgba(50, 50, 50, 0.2);
  border-radius: 0px;
}

/* Bootstrap 4 & Admin LTE */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #838ba2;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #f8ba00;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --cust-dark: #434b62;
  --cust-dark-nav: #222632;
  --purple: #6423ad;
  --blue: #0076ba;
  --red: #f11514;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #f39c12;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #b06f09;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled, .users-list, .contacts-list, .chart-legend {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dcdfe5;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #bfc3cf;
}

.table-hover .table-primary:hover {
  background-color: #cdd2da;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #cdd2da;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fdecb8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbdb7a;
}

.table-hover .table-warning:hover {
  background-color: #fce59f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fce59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-cust-dark,
.table-cust-dark > th,
.table-cust-dark > td {
  background-color: #cacdd3;
}
.table-cust-dark th,
.table-cust-dark td,
.table-cust-dark thead th,
.table-cust-dark tbody + tbody {
  border-color: #9da1ad;
}

.table-hover .table-cust-dark:hover {
  background-color: #bcc0c7;
}
.table-hover .table-cust-dark:hover > td,
.table-hover .table-cust-dark:hover > th {
  background-color: #bcc0c7;
}

.table-cust-dark-nav,
.table-cust-dark-nav > th,
.table-cust-dark-nav > td {
  background-color: #c1c2c6;
}
.table-cust-dark-nav th,
.table-cust-dark-nav td,
.table-cust-dark-nav thead th,
.table-cust-dark-nav tbody + tbody {
  border-color: #8c8e94;
}

.table-hover .table-cust-dark-nav:hover {
  background-color: #b4b5ba;
}
.table-hover .table-cust-dark-nav:hover > td,
.table-hover .table-cust-dark-nav:hover > th {
  background-color: #b4b5ba;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d4c1e8;
}
.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #ae8dd4;
}

.table-hover .table-purple:hover {
  background-color: #c7aee1;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #c7aee1;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #b8d9ec;
}
.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #7ab8db;
}

.table-hover .table-blue:hover {
  background-color: #a4cee7;
}
.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #a4cee7;
}

.table-red,
.table-red > th,
.table-red > td {
  background-color: #fbbdbd;
}
.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #f88585;
}

.table-hover .table-red:hover {
  background-color: #faa5a5;
}
.table-hover .table-red:hover > td,
.table-hover .table-red:hover > th {
  background-color: #faa5a5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.25rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #838ba2;
  border-color: #838ba2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #6d7792;
  border-color: #68718a;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #6d7792;
  border-color: #68718a;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(150, 156, 176, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #838ba2;
  border-color: #838ba2;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #68718a;
  border-color: #626b83;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(150, 156, 176, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #1F2D3D;
  background-color: #f8ba00;
  border-color: #f8ba00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #1F2D3D;
  background-color: #d29d00;
  border-color: #c59400;
}
.btn-warning:focus, .btn-warning.focus {
  color: #1F2D3D;
  background-color: #d29d00;
  border-color: #c59400;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(215, 165, 9, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #1F2D3D;
  background-color: #f8ba00;
  border-color: #f8ba00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #c59400;
  border-color: #b88a00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(215, 165, 9, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #1F2D3D;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #1F2D3D;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #1F2D3D;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(215, 218, 222, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #1F2D3D;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #1F2D3D;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(215, 218, 222, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-cust-dark {
  color: #ffffff;
  background-color: #434b62;
  border-color: #434b62;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cust-dark:hover {
  color: #ffffff;
  background-color: #333a4b;
  border-color: #2e3444;
}
.btn-cust-dark:focus, .btn-cust-dark.focus {
  color: #ffffff;
  background-color: #333a4b;
  border-color: #2e3444;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(95, 102, 122, 0.5);
}
.btn-cust-dark.disabled, .btn-cust-dark:disabled {
  color: #ffffff;
  background-color: #434b62;
  border-color: #434b62;
}
.btn-cust-dark:not(:disabled):not(.disabled):active, .btn-cust-dark:not(:disabled):not(.disabled).active, .show > .btn-cust-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #2e3444;
  border-color: #292e3c;
}
.btn-cust-dark:not(:disabled):not(.disabled):active:focus, .btn-cust-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-cust-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(95, 102, 122, 0.5);
}

.btn-cust-dark-nav {
  color: #ffffff;
  background-color: #222632;
  border-color: #222632;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cust-dark-nav:hover {
  color: #ffffff;
  background-color: #13151b;
  border-color: #0d0f14;
}
.btn-cust-dark-nav:focus, .btn-cust-dark-nav.focus {
  color: #ffffff;
  background-color: #13151b;
  border-color: #0d0f14;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(67, 71, 81, 0.5);
}
.btn-cust-dark-nav.disabled, .btn-cust-dark-nav:disabled {
  color: #ffffff;
  background-color: #222632;
  border-color: #222632;
}
.btn-cust-dark-nav:not(:disabled):not(.disabled):active, .btn-cust-dark-nav:not(:disabled):not(.disabled).active, .show > .btn-cust-dark-nav.dropdown-toggle {
  color: #ffffff;
  background-color: #0d0f14;
  border-color: #08090c;
}
.btn-cust-dark-nav:not(:disabled):not(.disabled):active:focus, .btn-cust-dark-nav:not(:disabled):not(.disabled).active:focus, .show > .btn-cust-dark-nav.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(67, 71, 81, 0.5);
}

.btn-purple {
  color: #ffffff;
  background-color: #6423ad;
  border-color: #6423ad;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple:hover {
  color: #ffffff;
  background-color: #521d8d;
  border-color: #4b1a83;
}
.btn-purple:focus, .btn-purple.focus {
  color: #ffffff;
  background-color: #521d8d;
  border-color: #4b1a83;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(123, 68, 185, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #ffffff;
  background-color: #6423ad;
  border-color: #6423ad;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #4b1a83;
  border-color: #451878;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(123, 68, 185, 0.5);
}

.btn-blue {
  color: #ffffff;
  background-color: #0076ba;
  border-color: #0076ba;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue:hover {
  color: #ffffff;
  background-color: #005e94;
  border-color: #005687;
}
.btn-blue:focus, .btn-blue.focus {
  color: #ffffff;
  background-color: #005e94;
  border-color: #005687;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(38, 139, 196, 0.5);
}
.btn-blue.disabled, .btn-blue:disabled {
  color: #ffffff;
  background-color: #0076ba;
  border-color: #0076ba;
}
.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active, .show > .btn-blue.dropdown-toggle {
  color: #ffffff;
  background-color: #005687;
  border-color: #004e7a;
}
.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(38, 139, 196, 0.5);
}

.btn-red {
  color: #ffffff;
  background-color: #f11514;
  border-color: #f11514;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red:hover {
  color: #ffffff;
  background-color: #d20d0d;
  border-color: #c60d0c;
}
.btn-red:focus, .btn-red.focus {
  color: #ffffff;
  background-color: #d20d0d;
  border-color: #c60d0c;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(243, 56, 55, 0.5);
}
.btn-red.disabled, .btn-red:disabled {
  color: #ffffff;
  background-color: #f11514;
  border-color: #f11514;
}
.btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active, .show > .btn-red.dropdown-toggle {
  color: #ffffff;
  background-color: #c60d0c;
  border-color: #ba0c0b;
}
.btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(243, 56, 55, 0.5);
}

.btn-outline-primary {
  color: #838ba2;
  border-color: #838ba2;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #838ba2;
  border-color: #838ba2;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 139, 162, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #838ba2;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #838ba2;
  border-color: #838ba2;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(131, 139, 162, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #f8ba00;
  border-color: #f8ba00;
}
.btn-outline-warning:hover {
  color: #1F2D3D;
  background-color: #f8ba00;
  border-color: #f8ba00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 186, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f8ba00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #1F2D3D;
  background-color: #f8ba00;
  border-color: #f8ba00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 186, 0, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #1F2D3D;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #1F2D3D;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-cust-dark {
  color: #434b62;
  border-color: #434b62;
}
.btn-outline-cust-dark:hover {
  color: #ffffff;
  background-color: #434b62;
  border-color: #434b62;
}
.btn-outline-cust-dark:focus, .btn-outline-cust-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 75, 98, 0.5);
}
.btn-outline-cust-dark.disabled, .btn-outline-cust-dark:disabled {
  color: #434b62;
  background-color: transparent;
}
.btn-outline-cust-dark:not(:disabled):not(.disabled):active, .btn-outline-cust-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-cust-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #434b62;
  border-color: #434b62;
}
.btn-outline-cust-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-cust-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cust-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(67, 75, 98, 0.5);
}

.btn-outline-cust-dark-nav {
  color: #222632;
  border-color: #222632;
}
.btn-outline-cust-dark-nav:hover {
  color: #ffffff;
  background-color: #222632;
  border-color: #222632;
}
.btn-outline-cust-dark-nav:focus, .btn-outline-cust-dark-nav.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 38, 50, 0.5);
}
.btn-outline-cust-dark-nav.disabled, .btn-outline-cust-dark-nav:disabled {
  color: #222632;
  background-color: transparent;
}
.btn-outline-cust-dark-nav:not(:disabled):not(.disabled):active, .btn-outline-cust-dark-nav:not(:disabled):not(.disabled).active, .show > .btn-outline-cust-dark-nav.dropdown-toggle {
  color: #ffffff;
  background-color: #222632;
  border-color: #222632;
}
.btn-outline-cust-dark-nav:not(:disabled):not(.disabled):active:focus, .btn-outline-cust-dark-nav:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-cust-dark-nav.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(34, 38, 50, 0.5);
}

.btn-outline-purple {
  color: #6423ad;
  border-color: #6423ad;
}
.btn-outline-purple:hover {
  color: #ffffff;
  background-color: #6423ad;
  border-color: #6423ad;
}
.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 35, 173, 0.5);
}
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #6423ad;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #6423ad;
  border-color: #6423ad;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(100, 35, 173, 0.5);
}

.btn-outline-blue {
  color: #0076ba;
  border-color: #0076ba;
}
.btn-outline-blue:hover {
  color: #ffffff;
  background-color: #0076ba;
  border-color: #0076ba;
}
.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 118, 186, 0.5);
}
.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #0076ba;
  background-color: transparent;
}
.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-blue.dropdown-toggle {
  color: #ffffff;
  background-color: #0076ba;
  border-color: #0076ba;
}
.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 118, 186, 0.5);
}

.btn-outline-red {
  color: #f11514;
  border-color: #f11514;
}
.btn-outline-red:hover {
  color: #ffffff;
  background-color: #f11514;
  border-color: #f11514;
}
.btn-outline-red:focus, .btn-outline-red.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 21, 20, 0.5);
}
.btn-outline-red.disabled, .btn-outline-red:disabled {
  color: #f11514;
  background-color: transparent;
}
.btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active, .show > .btn-outline-red.dropdown-toggle {
  color: #ffffff;
  background-color: #f11514;
  border-color: #f11514;
}
.btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 21, 20, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #f39c12;
  text-decoration: none;
}
.btn-link:hover {
  color: #b06f09;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 2.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
  box-shadow: none;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: 1px solid #adb5bd;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 3.25rem;
}
.custom-switch .custom-control-label::before {
  left: -3.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-3.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #dee2e6;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: 0px;
  background-color: transparent;
  border: 0px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.3125rem 0.5rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.75%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 0px);
  border-top-right-radius: calc(0.25rem - 0px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 0px);
  border-bottom-left-radius: calc(0.25rem - 0px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 0.5rem;
}

.card-header {
  padding: 0.75rem 0.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 0px) calc(0.25rem - 0px) 0 0;
}

.card-footer {
  padding: 0.75rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 0px) calc(0.25rem - 0px);
}

.card-header-tabs {
  margin-right: -0.25rem;
  margin-bottom: -0.75rem;
  margin-left: -0.25rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 0px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 0px);
  border-top-right-radius: calc(0.25rem - 0px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 0px);
  border-bottom-left-radius: calc(0.25rem - 0px);
}

.card-deck .card {
  margin-bottom: 7.5px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 7.5px;
    margin-bottom: 0;
    margin-left: 7.5px;
  }
}

.card-group > .card {
  margin-bottom: 7.5px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #838ba2;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #68718a;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(131, 139, 162, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #1F2D3D;
  background-color: #f8ba00;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #1F2D3D;
  background-color: #c59400;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 186, 0, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #1F2D3D;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #1F2D3D;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-cust-dark {
  color: #ffffff;
  background-color: #434b62;
}
a.badge-cust-dark:hover, a.badge-cust-dark:focus {
  color: #ffffff;
  background-color: #2e3444;
}
a.badge-cust-dark:focus, a.badge-cust-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 75, 98, 0.5);
}

.badge-cust-dark-nav {
  color: #ffffff;
  background-color: #222632;
}
a.badge-cust-dark-nav:hover, a.badge-cust-dark-nav:focus {
  color: #ffffff;
  background-color: #0d0f14;
}
a.badge-cust-dark-nav:focus, a.badge-cust-dark-nav.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 38, 50, 0.5);
}

.badge-purple {
  color: #ffffff;
  background-color: #6423ad;
}
a.badge-purple:hover, a.badge-purple:focus {
  color: #ffffff;
  background-color: #4b1a83;
}
a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 35, 173, 0.5);
}

.badge-blue {
  color: #ffffff;
  background-color: #0076ba;
}
a.badge-blue:hover, a.badge-blue:focus {
  color: #ffffff;
  background-color: #005687;
}
a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 118, 186, 0.5);
}

.badge-red {
  color: #ffffff;
  background-color: #f11514;
}
a.badge-red:hover, a.badge-red:focus {
  color: #ffffff;
  background-color: #c60d0c;
}
a.badge-red:focus, a.badge-red.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 21, 20, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #444854;
  background-color: #e6e8ec;
  border-color: #dcdfe5;
}
.alert-primary hr {
  border-top-color: #cdd2da;
}
.alert-primary .alert-link {
  color: #2d3038;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #816100;
  background-color: #fef1cc;
  border-color: #fdecb8;
}
.alert-warning hr {
  border-top-color: #fce59f;
}
.alert-warning .alert-link {
  color: #4e3b00;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-cust-dark {
  color: #232733;
  background-color: #d9dbe0;
  border-color: #cacdd3;
}
.alert-cust-dark hr {
  border-top-color: #bcc0c7;
}
.alert-cust-dark .alert-link {
  color: #0e1015;
}

.alert-cust-dark-nav {
  color: #12141a;
  background-color: #d3d4d6;
  border-color: #c1c2c6;
}
.alert-cust-dark-nav hr {
  border-top-color: #b4b5ba;
}
.alert-cust-dark-nav .alert-link {
  color: black;
}

.alert-purple {
  color: #34125a;
  background-color: #e0d3ef;
  border-color: #d4c1e8;
}
.alert-purple hr {
  border-top-color: #c7aee1;
}
.alert-purple .alert-link {
  color: #1b0a30;
}

.alert-blue {
  color: #003d61;
  background-color: #cce4f1;
  border-color: #b8d9ec;
}
.alert-blue hr {
  border-top-color: #a4cee7;
}
.alert-blue .alert-link {
  color: #001d2e;
}

.alert-red {
  color: #7d0b0a;
  background-color: #fcd0d0;
  border-color: #fbbdbd;
}
.alert-red hr {
  border-top-color: #faa5a5;
}
.alert-red .alert-link {
  color: #4e0706;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #444854;
  background-color: #dcdfe5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #444854;
  background-color: #cdd2da;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #444854;
  border-color: #444854;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #816100;
  background-color: #fdecb8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #816100;
  background-color: #fce59f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #816100;
  border-color: #816100;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-cust-dark {
  color: #232733;
  background-color: #cacdd3;
}
.list-group-item-cust-dark.list-group-item-action:hover, .list-group-item-cust-dark.list-group-item-action:focus {
  color: #232733;
  background-color: #bcc0c7;
}
.list-group-item-cust-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #232733;
  border-color: #232733;
}

.list-group-item-cust-dark-nav {
  color: #12141a;
  background-color: #c1c2c6;
}
.list-group-item-cust-dark-nav.list-group-item-action:hover, .list-group-item-cust-dark-nav.list-group-item-action:focus {
  color: #12141a;
  background-color: #b4b5ba;
}
.list-group-item-cust-dark-nav.list-group-item-action.active {
  color: #ffffff;
  background-color: #12141a;
  border-color: #12141a;
}

.list-group-item-purple {
  color: #34125a;
  background-color: #d4c1e8;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #34125a;
  background-color: #c7aee1;
}
.list-group-item-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: #34125a;
  border-color: #34125a;
}

.list-group-item-blue {
  color: #003d61;
  background-color: #b8d9ec;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #003d61;
  background-color: #a4cee7;
}
.list-group-item-blue.list-group-item-action.active {
  color: #ffffff;
  background-color: #003d61;
  border-color: #003d61;
}

.list-group-item-red {
  color: #7d0b0a;
  background-color: #fbbdbd;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #7d0b0a;
  background-color: #faa5a5;
}
.list-group-item-red.list-group-item-action.active {
  color: #ffffff;
  background-color: #7d0b0a;
  border-color: #7d0b0a;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary, .label-primary {
  background-color: #838ba2 !important;
}

a.bg-primary:hover, a.label-primary:hover, a.bg-primary:focus, a.label-primary:focus,
button.bg-primary:hover,
button.label-primary:hover,
button.bg-primary:focus,
button.label-primary:focus {
  background-color: #68718a !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success, .label-success, .alert-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.label-success:hover, a.alert-success:hover, a.bg-success:focus, a.label-success:focus, a.alert-success:focus,
button.bg-success:hover,
button.label-success:hover,
button.alert-success:hover,
button.bg-success:focus,
button.label-success:focus,
button.alert-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info, .label-info, .alert-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.label-info:hover, a.alert-info:hover, a.bg-info:focus, a.label-info:focus, a.alert-info:focus,
button.bg-info:hover,
button.label-info:hover,
button.alert-info:hover,
button.bg-info:focus,
button.label-info:focus,
button.alert-info:focus {
  background-color: #117a8b !important;
}

.bg-warning, .label-warning, .alert-warning {
  background-color: #f8ba00 !important;
}

a.bg-warning:hover, a.label-warning:hover, a.alert-warning:hover, a.bg-warning:focus, a.label-warning:focus, a.alert-warning:focus,
button.bg-warning:hover,
button.label-warning:hover,
button.alert-warning:hover,
button.bg-warning:focus,
button.label-warning:focus,
button.alert-warning:focus {
  background-color: #c59400 !important;
}

.bg-danger, .label-danger, .alert-danger,
.alert-error {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.label-danger:hover, a.alert-danger:hover,
a.alert-error:hover, a.bg-danger:focus, a.label-danger:focus, a.alert-danger:focus,
a.alert-error:focus,
button.bg-danger:hover,
button.label-danger:hover,
button.alert-danger:hover,
button.alert-error:hover,
button.bg-danger:focus,
button.label-danger:focus,
button.alert-danger:focus,
button.alert-error:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-cust-dark {
  background-color: #434b62 !important;
}

a.bg-cust-dark:hover, a.bg-cust-dark:focus,
button.bg-cust-dark:hover,
button.bg-cust-dark:focus {
  background-color: #2e3444 !important;
}

.bg-cust-dark-nav {
  background-color: #222632 !important;
}

a.bg-cust-dark-nav:hover, a.bg-cust-dark-nav:focus,
button.bg-cust-dark-nav:hover,
button.bg-cust-dark-nav:focus {
  background-color: #0d0f14 !important;
}

.bg-purple {
  background-color: #6423ad !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #4b1a83 !important;
}

.bg-blue {
  background-color: #0076ba !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #005687 !important;
}

.bg-red {
  background-color: #f11514 !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #c60d0c !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #838ba2 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #f8ba00 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-cust-dark {
  border-color: #434b62 !important;
}

.border-cust-dark-nav {
  border-color: #222632 !important;
}

.border-purple {
  border-color: #6423ad !important;
}

.border-blue {
  border-color: #0076ba !important;
}

.border-red {
  border-color: #f11514 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .info-box-icon, .info-box {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .info-box-icon {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .info-box-icon {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .progress-group,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .callout, .info-box, .card, .small-box,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .card-body.p-0 .table thead > tr > th:last-of-type,
.card-body.p-0 .table thead > tr > td:last-of-type,
.card-body.p-0 .table tbody > tr > th:last-of-type,
.card-body.p-0 .table tbody > tr > td:last-of-type,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .card-body.p-0 .table thead > tr > th:first-of-type,
.card-body.p-0 .table thead > tr > td:first-of-type,
.card-body.p-0 .table tbody > tr > th:first-of-type,
.card-body.p-0 .table tbody > tr > td:first-of-type,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #838ba2 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #5d657c !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #f8ba00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ac8100 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-cust-dark {
  color: #434b62 !important;
}

a.text-cust-dark:hover, a.text-cust-dark:focus {
  color: #242835 !important;
}

.text-cust-dark-nav {
  color: #222632 !important;
}

a.text-cust-dark-nav:hover, a.text-cust-dark-nav:focus {
  color: #030304 !important;
}

.text-purple {
  color: #6423ad !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #3f166d !important;
}

.text-blue {
  color: #0076ba !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #00456e !important;
}

.text-red {
  color: #f11514 !important;
}

a.text-red:hover, a.text-red:focus {
  color: #ae0b0a !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body,
.wrapper {
  min-height: 100%;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
}
.layout-boxed .wrapper {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.layout-boxed .wrapper, .layout-boxed .wrapper:before {
  margin: 0 auto;
  max-width: 1250px;
}

@media (min-width: 768px) {
  .content-wrapper,
  .main-footer,
  .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 230px;
    z-index: 3000;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .content-wrapper,
  .main-footer,
  .main-header {
    transition: none;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .main-footer,
  .sidebar-collapse .main-header {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .content-wrapper, .content-wrapper:before,
  .main-footer,
  .main-footer:before,
  .main-header,
  .main-header:before {
    margin-left: 0;
  }
}

.content-wrapper {
  background: #f4f6f9;
}
.content-wrapper > .content {
  padding: 1rem 1rem;
}

.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}
.main-sidebar, .main-sidebar:before {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 230px;
}
@media (prefers-reduced-motion: reduce) {
  .main-sidebar, .main-sidebar:before {
    transition: none;
  }
}
.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar:before {
  margin-left: -230px;
}
@media (max-width: 991.98px) {
  .main-sidebar, .main-sidebar:before {
    box-shadow: none !important;
    margin-left: -230px;
  }
  .sidebar-open .main-sidebar, .sidebar-open .main-sidebar:before {
    margin-left: 0;
  }
}

.main-footer {
  padding: 0.875rem;
  color: #555;
  border-top: 1px solid #dee2e6;
  background: #ffffff;
}

.content-header {
  padding: 15px 1rem;
}
.content-header h1 {
  font-size: 1.8rem;
  margin: 0;
}
.content-header .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  line-height: 1.8rem;
}

.hold-transition .content-wrapper,
.hold-transition .main-header,
.hold-transition .main-footer {
  transition: none !important;
}

/*
 * Component: Main Header
 * ----------------------
 */
.main-header {
  z-index: 1000;
}
.main-header .navbar-nav .nav-item {
  margin: 0;
}
.main-header .nav-link {
  position: relative;
  height: 2.5rem;
}
.main-header .navbar-nav[class*=-right] .dropdown-menu {
  margin-top: -3px;
  right: 0;
  left: auto;
}
@media (max-width: 575.98px) {
  .main-header .navbar-nav[class*=-right] .dropdown-menu {
    left: 0;
    right: auto;
  }
}

.navbar-img {
  height: 1.0625rem;
  width: auto;
}

.navbar-badge {
  position: absolute;
  top: 9px;
  right: 5px;
  font-size: 0.6rem;
  font-weight: 300;
  padding: 2px 4px;
}

.btn-navbar {
  border-left-width: 0;
  background-color: transparent;
}

.form-control-navbar {
  border-right-width: 0;
}
.form-control-navbar + .input-group-append {
  margin-left: 0;
}

.form-control-navbar,
.btn-navbar {
  transition: none;
}

.navbar-dark .form-control-navbar,
.navbar-dark .btn-navbar {
  background-color: hsla(100, 100%, 100%, 0.2);
  border: 0;
}
.navbar-dark .form-control-navbar::placeholder,
.navbar-dark .form-control-navbar + .input-group-append > .btn-navbar {
  color: hsla(100, 100%, 100%, 0.6);
}
.navbar-dark .form-control-navbar :-moz-placeholder {
  color: hsla(100, 100%, 100%, 0.6);
}
.navbar-dark .form-control-navbar ::-moz-placeholder {
  color: hsla(100, 100%, 100%, 0.6);
}
.navbar-dark .form-control-navbar :-ms-input-placeholder {
  color: hsla(100, 100%, 100%, 0.6);
}
.navbar-dark .form-control-navbar:focus, .navbar-dark .form-control-navbar:focus + .input-group-append .btn-navbar {
  border: 0 !important;
  background-color: hsla(100, 100%, 100%, 0.6);
  color: #343a40;
}

.navbar-light .form-control-navbar,
.navbar-light .btn-navbar {
  background-color: #f2f4f6;
  border: 0;
}
.navbar-light .form-control-navbar::placeholder,
.navbar-light .form-control-navbar + .input-group-append > .btn-navbar {
  color: hsla(0, 0%, 0%, 0.6);
}
.navbar-light .form-control-navbar :-moz-placeholder {
  color: hsla(0, 0%, 0%, 0.6);
}
.navbar-light .form-control-navbar ::-moz-placeholder {
  color: hsla(0, 0%, 0%, 0.6);
}
.navbar-light .form-control-navbar :-ms-input-placeholder {
  color: hsla(0, 0%, 0%, 0.6);
}
.navbar-light .form-control-navbar:focus, .navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar {
  border: 0 !important;
  background-color: #e9ecef;
  color: #343a40;
}

.brand-link {
  padding: 0.3125rem 0;
  font-size: 1.25rem;
  display: block;
  line-height: 1.5;
  white-space: nowrap;
}
.brand-link:hover {
  color: #ffffff;
  text-decoration: none;
}
[class*=sidebar-dark] .brand-link {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #4b545c;
}
[class*=sidebar-light] .brand-link {
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #dee2e6;
}

.brand-image {
  float: left;
  line-height: 0.8;
  max-height: 34px;
  width: auto;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -3px;
}

/**
 * Component: Sidebar
 * ------------------
 */
.main-sidebar {
  z-index: 1100;
  height: 100vh;
  overflow-y: hidden;
}

.sidebar {
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  overflow-y: auto;
  height: calc(100% - 4rem);
}

.user-panel {
  position: relative;
}
[class*=sidebar-dark] .user-panel {
  border-bottom: 1px solid #4f5962;
}
[class*=sidebar-light] .user-panel {
  border-bottom: 1px solid #dee2e6;
}
.user-panel,
.user-panel .info {
  overflow: hidden;
  white-space: nowrap;
}
.user-panel .image {
  padding-left: 0.8rem;
  display: inline-block;
}
.user-panel img {
  width: 2rem;
  height: auto;
}
.user-panel .info {
  display: inline-block;
  padding: 5px 5px 5px 10px;
}
.user-panel .status,
.user-panel .dropdown-menu {
  font-size: 0.75rem;
}

.nav-sidebar .nav-item > .nav-link {
  margin-bottom: 0.2rem;
}
.nav-sidebar .nav-item > .nav-link .right {
  transition: transform ease-in-out 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-item > .nav-link .right {
    transition: none;
  }
}
.nav-sidebar .nav-link > p > .right {
  position: absolute;
  right: 1rem;
  top: 12px;
}
.nav-sidebar .menu-open > .nav-treeview {
  display: block;
}
.nav-sidebar .menu-open > .nav-link .right {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.nav-sidebar > .nav-item {
  margin-bottom: 0;
}
.nav-sidebar > .nav-item .nav-icon {
  text-align: center;
  width: 2rem;
  font-size: 1.2rem;
  margin-right: 0.2rem;
}
.nav-sidebar > .nav-item .float-right {
  margin-top: 3px;
}
.nav-sidebar .nav-treeview {
  display: none;
  list-style: none;
  padding: 0;
}
.nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
  width: 2rem;
}
.nav-sidebar .nav-header {
  font-size: 0.9rem;
  padding: 0.5rem;
}
.nav-sidebar .nav-header:not(:first-of-type) {
  padding: 1.7rem 1rem 0.5rem 1rem;
}
.nav-sidebar .nav-link p {
  display: inline-block;
  margin: 0;
}

#sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1099;
}
@media (max-width: 991.98px) {
  .sidebar-open #sidebar-overlay {
    display: block;
  }
}

.sidebar-dark-primary {
  background-color: #343a40;
}
.sidebar-dark-primary .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-primary .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-primary .user-panel .status:hover, .sidebar-dark-primary .user-panel .status:focus, .sidebar-dark-primary .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-primary .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-primary .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-primary .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-primary .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #838ba2;
}
.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-primary .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-primary .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-primary .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-primary .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-primary .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-primary .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-primary .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-primary {
  background-color: #ffffff;
}
.sidebar-light-primary .user-panel a:hover {
  color: #212529;
}
.sidebar-light-primary .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-primary .user-panel .status:hover, .sidebar-light-primary .user-panel .status:focus, .sidebar-light-primary .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-primary .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-primary .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-primary .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-primary .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #838ba2;
}
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-primary .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-primary .sidebar a {
  color: #343a40;
}
.sidebar-light-primary .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-primary .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-primary .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-primary .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-primary .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-secondary {
  background-color: #343a40;
}
.sidebar-dark-secondary .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-secondary .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-secondary .user-panel .status:hover, .sidebar-dark-secondary .user-panel .status:focus, .sidebar-dark-secondary .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-secondary .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-secondary .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-secondary .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-secondary .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-secondary .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-secondary .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-secondary .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #6c757d;
}
.sidebar-dark-secondary .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-secondary .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-secondary .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-secondary .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-secondary .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-secondary .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-secondary .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-secondary .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-secondary {
  background-color: #ffffff;
}
.sidebar-light-secondary .user-panel a:hover {
  color: #212529;
}
.sidebar-light-secondary .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-secondary .user-panel .status:hover, .sidebar-light-secondary .user-panel .status:focus, .sidebar-light-secondary .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-secondary .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-secondary .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-secondary .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-secondary .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-secondary .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-secondary .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-secondary .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #6c757d;
}
.sidebar-light-secondary .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-secondary .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-secondary .sidebar a {
  color: #343a40;
}
.sidebar-light-secondary .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-secondary .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-secondary .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-secondary .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-secondary .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-success {
  background-color: #343a40;
}
.sidebar-dark-success .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-success .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-success .user-panel .status:hover, .sidebar-dark-success .user-panel .status:focus, .sidebar-dark-success .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-success .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-success .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-success .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-success .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-success .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-success .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-success .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #28a745;
}
.sidebar-dark-success .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-success .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-success .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-success .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-success .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-success .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-success .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-success .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-success {
  background-color: #ffffff;
}
.sidebar-light-success .user-panel a:hover {
  color: #212529;
}
.sidebar-light-success .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-success .user-panel .status:hover, .sidebar-light-success .user-panel .status:focus, .sidebar-light-success .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-success .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-success .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-success .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-success .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-success .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-success .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-success .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #28a745;
}
.sidebar-light-success .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-success .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-success .sidebar a {
  color: #343a40;
}
.sidebar-light-success .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-success .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-success .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-success .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-success .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-info {
  background-color: #343a40;
}
.sidebar-dark-info .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-info .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-info .user-panel .status:hover, .sidebar-dark-info .user-panel .status:focus, .sidebar-dark-info .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-info .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-info .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-info .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-info .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-info .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-info .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-info .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #17a2b8;
}
.sidebar-dark-info .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-info .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-info .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-info .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-info .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-info .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-info .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-info .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-info {
  background-color: #ffffff;
}
.sidebar-light-info .user-panel a:hover {
  color: #212529;
}
.sidebar-light-info .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-info .user-panel .status:hover, .sidebar-light-info .user-panel .status:focus, .sidebar-light-info .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-info .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-info .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-info .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-info .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-info .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-info .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-info .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #17a2b8;
}
.sidebar-light-info .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-info .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-info .sidebar a {
  color: #343a40;
}
.sidebar-light-info .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-info .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-info .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-info .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-info .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-warning {
  background-color: #343a40;
}
.sidebar-dark-warning .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-warning .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-warning .user-panel .status:hover, .sidebar-dark-warning .user-panel .status:focus, .sidebar-dark-warning .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-warning .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-warning .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-warning .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-warning .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-warning .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-warning .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-warning .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f8ba00;
}
.sidebar-dark-warning .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-warning .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-warning .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-warning .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-warning .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-warning .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-warning .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-warning .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-warning {
  background-color: #ffffff;
}
.sidebar-light-warning .user-panel a:hover {
  color: #212529;
}
.sidebar-light-warning .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-warning .user-panel .status:hover, .sidebar-light-warning .user-panel .status:focus, .sidebar-light-warning .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-warning .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-warning .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-warning .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-warning .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-warning .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-warning .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-warning .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f8ba00;
}
.sidebar-light-warning .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-warning .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-warning .sidebar a {
  color: #343a40;
}
.sidebar-light-warning .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-warning .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-warning .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-warning .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-warning .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-danger {
  background-color: #343a40;
}
.sidebar-dark-danger .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-danger .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-danger .user-panel .status:hover, .sidebar-dark-danger .user-panel .status:focus, .sidebar-dark-danger .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-danger .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-danger .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-danger .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-danger .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-danger .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-danger .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-danger .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #dc3545;
}
.sidebar-dark-danger .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-danger .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-danger .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-danger .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-danger .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-danger .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-danger .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-danger .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-danger {
  background-color: #ffffff;
}
.sidebar-light-danger .user-panel a:hover {
  color: #212529;
}
.sidebar-light-danger .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-danger .user-panel .status:hover, .sidebar-light-danger .user-panel .status:focus, .sidebar-light-danger .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-danger .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-danger .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-danger .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-danger .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-danger .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-danger .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-danger .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #dc3545;
}
.sidebar-light-danger .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-danger .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-danger .sidebar a {
  color: #343a40;
}
.sidebar-light-danger .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-danger .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-danger .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-danger .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-danger .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-light {
  background-color: #343a40;
}
.sidebar-dark-light .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-light .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-light .user-panel .status:hover, .sidebar-dark-light .user-panel .status:focus, .sidebar-dark-light .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-light .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-light .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-light .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-light .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-light .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-light .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-light .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f8f9fa;
}
.sidebar-dark-light .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-light .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-light .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-light .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-light .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-light .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-light .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-light .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-light {
  background-color: #ffffff;
}
.sidebar-light-light .user-panel a:hover {
  color: #212529;
}
.sidebar-light-light .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-light .user-panel .status:hover, .sidebar-light-light .user-panel .status:focus, .sidebar-light-light .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-light .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-light .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-light .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-light .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-light .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-light .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-light .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f8f9fa;
}
.sidebar-light-light .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-light .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-light .sidebar a {
  color: #343a40;
}
.sidebar-light-light .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-light .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-light .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-light .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-light .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-dark {
  background-color: #343a40;
}
.sidebar-dark-dark .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-dark .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-dark .user-panel .status:hover, .sidebar-dark-dark .user-panel .status:focus, .sidebar-dark-dark .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-dark .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-dark .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-dark .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-dark .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-dark .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-dark .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-dark .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #343a40;
}
.sidebar-dark-dark .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-dark .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-dark .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-dark .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-dark .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-dark .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-dark .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-dark .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-dark {
  background-color: #ffffff;
}
.sidebar-light-dark .user-panel a:hover {
  color: #212529;
}
.sidebar-light-dark .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-dark .user-panel .status:hover, .sidebar-light-dark .user-panel .status:focus, .sidebar-light-dark .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-dark .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-dark .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-dark .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-dark .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-dark .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-dark .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-dark .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #343a40;
}
.sidebar-light-dark .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-dark .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-dark .sidebar a {
  color: #343a40;
}
.sidebar-light-dark .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-dark .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-dark .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-dark .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-dark .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-cust-dark {
  background-color: #343a40;
}
.sidebar-dark-cust-dark .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-cust-dark .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark .user-panel .status:hover, .sidebar-dark-cust-dark .user-panel .status:focus, .sidebar-dark-cust-dark .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-cust-dark .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-cust-dark .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-cust-dark .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-cust-dark .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-cust-dark .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #434b62;
}
.sidebar-dark-cust-dark .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-cust-dark .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-cust-dark .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-cust-dark .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-cust-dark .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-cust-dark {
  background-color: #ffffff;
}
.sidebar-light-cust-dark .user-panel a:hover {
  color: #212529;
}
.sidebar-light-cust-dark .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-cust-dark .user-panel .status:hover, .sidebar-light-cust-dark .user-panel .status:focus, .sidebar-light-cust-dark .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-cust-dark .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-cust-dark .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-cust-dark .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-cust-dark .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-cust-dark .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-cust-dark .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-cust-dark .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #434b62;
}
.sidebar-light-cust-dark .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-cust-dark .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-cust-dark .sidebar a {
  color: #343a40;
}
.sidebar-light-cust-dark .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-cust-dark .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-cust-dark .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-cust-dark .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-cust-dark .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-cust-dark-nav {
  background-color: #343a40;
}
.sidebar-dark-cust-dark-nav .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-cust-dark-nav .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark-nav .user-panel .status:hover, .sidebar-dark-cust-dark-nav .user-panel .status:focus, .sidebar-dark-cust-dark-nav .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-cust-dark-nav .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-cust-dark-nav .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #222632;
}
.sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-cust-dark-nav .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-cust-dark-nav .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark-nav .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-cust-dark-nav .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-cust-dark-nav .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-cust-dark-nav .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-cust-dark-nav .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-cust-dark-nav {
  background-color: #ffffff;
}
.sidebar-light-cust-dark-nav .user-panel a:hover {
  color: #212529;
}
.sidebar-light-cust-dark-nav .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-cust-dark-nav .user-panel .status:hover, .sidebar-light-cust-dark-nav .user-panel .status:focus, .sidebar-light-cust-dark-nav .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-cust-dark-nav .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-cust-dark-nav .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-cust-dark-nav .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-cust-dark-nav .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-cust-dark-nav .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-cust-dark-nav .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-cust-dark-nav .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #222632;
}
.sidebar-light-cust-dark-nav .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-cust-dark-nav .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-cust-dark-nav .sidebar a {
  color: #343a40;
}
.sidebar-light-cust-dark-nav .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-cust-dark-nav .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-cust-dark-nav .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-cust-dark-nav .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-cust-dark-nav .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-purple {
  background-color: #343a40;
}
.sidebar-dark-purple .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-purple .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-purple .user-panel .status:hover, .sidebar-dark-purple .user-panel .status:focus, .sidebar-dark-purple .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-purple .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-purple .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-purple .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-purple .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-purple .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-purple .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-purple .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #6423ad;
}
.sidebar-dark-purple .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-purple .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-purple .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-purple .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-purple .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-purple .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-purple .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-purple .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-purple {
  background-color: #ffffff;
}
.sidebar-light-purple .user-panel a:hover {
  color: #212529;
}
.sidebar-light-purple .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-purple .user-panel .status:hover, .sidebar-light-purple .user-panel .status:focus, .sidebar-light-purple .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-purple .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-purple .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-purple .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-purple .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-purple .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-purple .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-purple .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #6423ad;
}
.sidebar-light-purple .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-purple .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-purple .sidebar a {
  color: #343a40;
}
.sidebar-light-purple .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-purple .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-purple .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-purple .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-purple .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-blue {
  background-color: #343a40;
}
.sidebar-dark-blue .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-blue .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-blue .user-panel .status:hover, .sidebar-dark-blue .user-panel .status:focus, .sidebar-dark-blue .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-blue .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-blue .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-blue .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-blue .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-blue .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-blue .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-blue .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #0076ba;
}
.sidebar-dark-blue .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-blue .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-blue .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-blue .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-blue .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-blue .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-blue .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-blue .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-blue {
  background-color: #ffffff;
}
.sidebar-light-blue .user-panel a:hover {
  color: #212529;
}
.sidebar-light-blue .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-blue .user-panel .status:hover, .sidebar-light-blue .user-panel .status:focus, .sidebar-light-blue .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-blue .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-blue .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-blue .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-blue .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-blue .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-blue .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-blue .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #0076ba;
}
.sidebar-light-blue .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-blue .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-blue .sidebar a {
  color: #343a40;
}
.sidebar-light-blue .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-blue .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-blue .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-blue .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-blue .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

.sidebar-dark-red {
  background-color: #343a40;
}
.sidebar-dark-red .user-panel a:hover {
  color: #ffffff;
}
.sidebar-dark-red .user-panel .status {
  color: #C2C7D0;
  background: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-red .user-panel .status:hover, .sidebar-dark-red .user-panel .status:focus, .sidebar-dark-red .user-panel .status:active {
  color: #ffffff;
  background: rgba(245, 255, 240, 0.1);
}
.sidebar-dark-red .user-panel .dropdown-menu {
  border-color: rgba(238, 255, 230, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-dark-red .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-dark-red .nav-sidebar > .nav-item > .nav-link:active, .sidebar-dark-red .nav-sidebar > .nav-item > .nav-link:focus {
  color: #C2C7D0;
}
.sidebar-dark-red .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-dark-red .nav-sidebar > .nav-item:hover > .nav-link {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-red .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f11514;
}
.sidebar-dark-red .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-dark-red .nav-header {
  color: #d0d4db;
  background: inherit;
}
.sidebar-dark-red .sidebar a {
  color: #C2C7D0;
}
.sidebar-dark-red .sidebar a:hover {
  text-decoration: none;
}
.sidebar-dark-red .nav-treeview > .nav-item > .nav-link {
  color: #C2C7D0;
}
.sidebar-dark-red .nav-treeview > .nav-item > .nav-link:hover {
  color: #ffffff;
  background-color: hsla(100, 100%, 100%, 0.1);
}
.sidebar-dark-red .nav-treeview > .nav-item > .nav-link.active, .sidebar-dark-red .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #343a40;
  background-color: hsla(100, 100%, 100%, 0.9);
}

.sidebar-light-red {
  background-color: #ffffff;
}
.sidebar-light-red .user-panel a:hover {
  color: #212529;
}
.sidebar-light-red .user-panel .status {
  color: #343a40;
  background: #f4f4f5;
}
.sidebar-light-red .user-panel .status:hover, .sidebar-light-red .user-panel .status:focus, .sidebar-light-red .user-panel .status:active {
  color: #212529;
  background: #ececed;
}
.sidebar-light-red .user-panel .dropdown-menu {
  border-color: #e7e7e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.sidebar-light-red .user-panel .dropdown-item {
  color: #212529;
}
.sidebar-light-red .nav-sidebar > .nav-item > .nav-link:active, .sidebar-light-red .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}
.sidebar-light-red .nav-sidebar > .nav-item.menu-open > .nav-link, .sidebar-light-red .nav-sidebar > .nav-item:hover > .nav-link {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-red .nav-sidebar > .nav-item > .nav-link.active {
  color: #ffffff;
  background-color: #f11514;
}
.sidebar-light-red .nav-sidebar > .nav-item > .nav-treeview {
  background: transparent;
}
.sidebar-light-red .nav-header {
  color: #292d32;
  background: inherit;
}
.sidebar-light-red .sidebar a {
  color: #343a40;
}
.sidebar-light-red .sidebar a:hover {
  text-decoration: none;
}
.sidebar-light-red .nav-treeview > .nav-item > .nav-link {
  color: #777;
}
.sidebar-light-red .nav-treeview > .nav-item > .nav-link.active, .sidebar-light-red .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #212529;
  background-color: #f4f4f5;
}
.sidebar-light-red .nav-treeview > .nav-item > .nav-link:hover {
  background-color: #f4f4f5;
}

/*
 * Component: Sidebar Mini
 */
@media (min-width: 992px) {
  .sidebar-mini .nav-sidebar,
  .sidebar-mini .nav-sidebar > .nav-header,
  .sidebar-mini .nav-sidebar .nav-link {
    white-space: nowrap;
    overflow: hidden;
  }
  .sidebar-mini.sidebar-collapse .d-hidden-mini {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 4rem !important;
  }
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-header {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .brand-text {
    opacity: 0;
    margin-left: -10px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar, .sidebar-mini.sidebar-collapse .main-sidebar:before {
    margin-left: 0;
    width: 4rem;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel .image {
    float: none;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover {
    width: 230px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel {
    text-align: left;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel .image {
    float: left;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .info,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-text {
    opacity: 1;
    margin-left: 0;
    display: inline-block;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-image {
    margin-right: 0.5rem;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .sidebar-form,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .info {
    display: block !important;
    -webkit-transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar > .nav-item > .nav-link > span {
    display: inline-block !important;
  }
  .sidebar-mini.sidebar-collapse .visible-sidebar-mini {
    display: block !important;
  }
}

.nav-sidebar {
  position: relative;
}
.nav-sidebar:hover {
  overflow: visible;
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;
}
.nav-sidebar .nav-item > .nav-link > .float-right {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.sidebar .user-panel .info {
  transition: margin-left 0.3s linear, opacity 0.5s ease;
}
@media (prefers-reduced-motion: reduce) {
  .sidebar .nav-link p,
  .main-sidebar .brand-text,
  .sidebar .user-panel .info {
    transition: none;
  }
}

/*
 * Component: Control sidebar. By default, this is the right sidebar.
 */
.control-sidebar {
  position: absolute;
  top: 2.125rem;
  z-index: 830;
}
.control-sidebar, .control-sidebar:before {
  width: 250px;
  right: -250px;
  bottom: 0;
  transition: right 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .control-sidebar, .control-sidebar:before {
    transition: none;
  }
}
.control-sidebar:before {
  top: 0;
  display: block;
  position: fixed;
  content: " ";
  z-index: -1;
}

@media (min-width: 768px) {
  .control-sidebar-open .control-sidebar, .control-sidebar-open .control-sidebar:before {
    right: 0;
  }
  .control-sidebar-open .content-wrapper,
  .control-sidebar-open .main-footer {
    margin-right: 250px;
  }
}
@media (max-width: 991.98px) {
  .control-sidebar-open .control-sidebar, .control-sidebar-open .control-sidebar:before {
    right: 0;
  }
}

.control-sidebar-slide-open .control-sidebar, .control-sidebar-slide-open .control-sidebar:before {
  right: 0;
}

.control-sidebar-dark,
.control-sidebar-dark a,
.control-sidebar-dark .nav-link {
  color: #C2C7D0;
}
.control-sidebar-dark, .control-sidebar-dark:before {
  background: #343a40;
}
.control-sidebar-dark a:hover {
  color: #ffffff;
}
.control-sidebar-dark h1,
.control-sidebar-dark h2,
.control-sidebar-dark h3,
.control-sidebar-dark h4,
.control-sidebar-dark h5,
.control-sidebar-dark h6,
.control-sidebar-dark label {
  color: #ffffff;
}
.control-sidebar-dark .nav-tabs {
  border-bottom: 0;
  background-color: hsla(100, 100%, 100%, 0.1);
  margin-bottom: 5px;
}
.control-sidebar-dark .nav-tabs .nav-item {
  margin: 0;
}
.control-sidebar-dark .nav-tabs .nav-link {
  position: relative;
  border-radius: 0;
  text-align: center;
  padding: 10px 20px;
}
.control-sidebar-dark .nav-tabs .nav-link, .control-sidebar-dark .nav-tabs .nav-link:hover, .control-sidebar-dark .nav-tabs .nav-link:active, .control-sidebar-dark .nav-tabs .nav-link:focus, .control-sidebar-dark .nav-tabs .nav-link.active {
  border: 0;
}
.control-sidebar-dark .nav-tabs .nav-link:hover, .control-sidebar-dark .nav-tabs .nav-link:active, .control-sidebar-dark .nav-tabs .nav-link:focus, .control-sidebar-dark .nav-tabs .nav-link.active {
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
  color: #ffffff;
}
.control-sidebar-dark .nav-tabs .nav-link.active {
  background-color: #343a40;
}
.control-sidebar-dark .tab-pane {
  padding: 10px 15px;
}

.control-sidebar-light {
  color: #4b545c;
}
.control-sidebar-light, .control-sidebar-light:before {
  background: #ffffff;
  border-left: 1px solid #adb5bd;
}

/*
 * Component: Dropdown menus
 * -------------------------
 */
.dropdown-item-title {
  font-size: 0.875rem;
  margin: 0;
}

.dropdown-menu-lg {
  min-width: 280px;
  max-width: 300px;
  padding: 0;
}
.dropdown-menu-lg .dropdown-divider {
  margin: 0;
}
.dropdown-menu-lg .dropdown-item {
  padding: 0.5rem 1rem;
}
.dropdown-menu-lg p {
  white-space: normal;
  margin: 0;
}

.dropdown-footer,
.dropdown-header {
  text-align: center;
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

/* Add fade animation to dropdown menus by appending
 the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/
.open:not(.dropup) > .animated-dropdown-menu {
  backface-visibility: visible !important;
  animation: flipInX 0.7s both;
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transition-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}
/* Fix dropdown menu in navbars */
.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}

@media (max-width: 767.98px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
  }
  .navbar-custom-menu > .navbar-nav > li {
    position: static;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 5%;
    left: auto;
    border: 1px solid #ddd;
    background: #ffffff;
  }
}
/*
 * Component: Form
 * ---------------
 */
.form-group.has-icon {
  position: relative;
}
.form-group.has-icon .form-control {
  padding-right: 35px;
}
.form-group.has-icon .form-icon {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 0;
  padding: 0.375rem 0.75rem;
  border: 0;
  background-color: transparent;
  font-size: 1rem;
}

/* button groups */
.btn-group-vertical .btn.btn-flat:first-of-type, .btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}

/* Support Font Awesome icons in form-control */
.form-control-feedback.fa {
  line-height: calc(2.25rem + 2px);
}

.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: calc(2.875rem + 2px);
}

.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: calc(1.8125rem);
}

label:not(.form-check-label, .custom-file-label) {
  font-weight: 700;
}

/*
 * Component: Progress Bar
 * -----------------------
 */
.progress {
  box-shadow: none;
  border-radius: 1px;
}

.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 7px;
}

.progress-xxs {
  height: 3px;
}

.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
}
.progress.vertical > .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress.vertical.sm, .progress.vertical.progress-sm {
  width: 20px;
}
.progress.vertical.xs, .progress.vertical.progress-xs {
  width: 10px;
}
.progress.vertical.xxs, .progress.vertical.progress-xxs {
  width: 3px;
}

.table tr > td .progress {
  margin: 0;
}

/*
 * Component: Small Box
 * --------------------
 */
.small-box {
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  margin-bottom: 20px;
}
.small-box > .inner {
  padding: 10px;
}
.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.small-box > .small-box-footer:hover {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.15);
}
.small-box h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}
.small-box p {
  font-size: 15px;
}
.small-box p > small {
  display: block;
  color: #f9f9f9;
  font-size: 13px;
  margin-top: 5px;
}
.small-box h3, .small-box p {
  z-index: 5;
}
.small-box .icon {
  transition: all 0.3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}
.small-box:hover {
  text-decoration: none;
}
.small-box:hover .icon {
  font-size: 95px;
}

@media (max-width: 767.98px) {
  .small-box {
    text-align: center;
  }
  .small-box .icon {
    display: none;
  }
  .small-box p {
    font-size: 12px;
  }
}
/*
 * Component: Box
 * --------------
 */
.card {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
}
.card.bg-dark,
.card.bg-dark .card-body {
  color: #ffffff;
}
.card.collapsed-card .card-body,
.card.collapsed-card .card-footer {
  display: none;
}
.card .nav.flex-column > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin: 0;
}
.card .nav.flex-column > li:last-of-type {
  border-bottom: none;
}
.card.height-control .card-body {
  max-height: 300px;
  overflow: auto;
}
.card .border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.card .border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.card > .overlay,
.card > .loading-img,
.overlay-wrapper > .overlay,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.25rem;
}
.card .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.card .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}

.card-header::after,
.card-body::after,
.card-footer::after {
  display: block;
  clear: both;
  content: "";
}

.card-header {
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.collapsed-card .card-header {
  border-bottom: none;
}
.card-header > .card-tools {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}
.card-header > .card-tools [data-toggle=tooltip] {
  position: relative;
}

.card-title {
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0;
}

.btn-tool {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  background: transparent;
  color: #adb5bd;
}
.btn-group.show .btn-tool, .btn-tool:hover {
  color: #495057;
}
.show .btn-tool, .btn-tool:focus {
  box-shadow: none !important;
}

.card-body > .table {
  margin-bottom: 0;
}
.card-body > .table > thead > tr > th,
.card-body > .table > thead > tr > td {
  border-top-width: 0;
}
.card-body .fc {
  margin-top: 5px;
}
.card-body .full-width-chart {
  margin: -19px;
}
.card-body.p-0 .full-width-chart {
  margin: -9px;
}

.chart-legend {
  margin: 10px 0;
}
@media (max-width: 576px) {
  .chart-legend > li {
    float: left;
    margin-right: 10px;
  }
}

.card-comments {
  background: #f7f7f7;
}
.card-comments .card-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.card-comments .card-comment::after {
  display: block;
  clear: both;
  content: "";
}
.card-comments .card-comment:last-of-type {
  border-bottom: 0;
}
.card-comments .card-comment:first-of-type {
  padding-top: 0;
}
.card-comments .card-comment img {
  float: left;
}
.card-comments .comment-text {
  margin-left: 40px;
  color: #555;
}
.card-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}
.card-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}

.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}
.todo-list > li {
  border-radius: 2px;
  padding: 10px;
  background: #f4f4f4;
  margin-bottom: 2px;
  border-left: 2px solid #e6e7e8;
  color: #444;
}
.todo-list > li:last-of-type {
  margin-bottom: 0;
}
.todo-list > li > input[type=checkbox] {
  margin: 0 10px 0 5px;
}
.todo-list > li .text {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}
.todo-list > li .label {
  margin-left: 10px;
  font-size: 9px;
}
.todo-list > li .tools {
  display: none;
  float: right;
  color: #dc3545;
}
.todo-list > li .tools > .fa, .todo-list > li .tools > .glyphicon, .todo-list > li .tools > .ion {
  margin-right: 5px;
  cursor: pointer;
}
.todo-list > li:hover .tools {
  display: inline-block;
}
.todo-list > li.done {
  color: #999;
}
.todo-list > li.done .text {
  text-decoration: line-through;
  font-weight: 500;
}
.todo-list > li.done .label {
  background: #adb5bd !important;
}
.todo-list .danger {
  border-left-color: #dc3545;
}
.todo-list .warning {
  border-left-color: #f8ba00;
}
.todo-list .info {
  border-left-color: #17a2b8;
}
.todo-list .success {
  border-left-color: #28a745;
}
.todo-list .primary {
  border-left-color: #838ba2;
}
.todo-list .handle {
  display: inline-block;
  cursor: move;
  margin: 0 5px;
}

.card-input {
  max-width: 200px;
}

.card-primary:not(.card-outline) .card-header {
  background-color: #838ba2;
  border-bottom: 0;
}
.card-primary:not(.card-outline) .card-header,
.card-primary:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-primary.card-outline {
  border-top: 3px solid #838ba2;
}

.bg-primary .btn-tool, .label-primary .btn-tool,
.bg-primary-gradient .btn-tool,
.card-primary:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-primary .btn-tool:hover, .label-primary .btn-tool:hover,
.bg-primary-gradient .btn-tool:hover,
.card-primary:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-secondary:not(.card-outline) .card-header {
  background-color: #6c757d;
  border-bottom: 0;
}
.card-secondary:not(.card-outline) .card-header,
.card-secondary:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-secondary.card-outline {
  border-top: 3px solid #6c757d;
}

.bg-secondary .btn-tool,
.bg-secondary-gradient .btn-tool,
.card-secondary:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-secondary .btn-tool:hover,
.bg-secondary-gradient .btn-tool:hover,
.card-secondary:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-success:not(.card-outline) .card-header {
  background-color: #28a745;
  border-bottom: 0;
}
.card-success:not(.card-outline) .card-header,
.card-success:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-success.card-outline {
  border-top: 3px solid #28a745;
}

.bg-success .btn-tool, .label-success .btn-tool, .alert-success .btn-tool,
.bg-success-gradient .btn-tool,
.card-success:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-success .btn-tool:hover, .label-success .btn-tool:hover, .alert-success .btn-tool:hover,
.bg-success-gradient .btn-tool:hover,
.card-success:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-info:not(.card-outline) .card-header {
  background-color: #17a2b8;
  border-bottom: 0;
}
.card-info:not(.card-outline) .card-header,
.card-info:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-info.card-outline {
  border-top: 3px solid #17a2b8;
}

.bg-info .btn-tool, .label-info .btn-tool, .alert-info .btn-tool,
.bg-info-gradient .btn-tool,
.card-info:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-info .btn-tool:hover, .label-info .btn-tool:hover, .alert-info .btn-tool:hover,
.bg-info-gradient .btn-tool:hover,
.card-info:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-warning:not(.card-outline) .card-header {
  background-color: #f8ba00;
  border-bottom: 0;
}
.card-warning:not(.card-outline) .card-header,
.card-warning:not(.card-outline) .card-header a {
  color: #1F2D3D;
}
.card-warning.card-outline {
  border-top: 3px solid #f8ba00;
}

.bg-warning .btn-tool, .label-warning .btn-tool, .alert-warning .btn-tool,
.bg-warning-gradient .btn-tool,
.card-warning:not(.card-outline) .btn-tool {
  color: rgba(31, 45, 61, 0.8);
}
.bg-warning .btn-tool:hover, .label-warning .btn-tool:hover, .alert-warning .btn-tool:hover,
.bg-warning-gradient .btn-tool:hover,
.card-warning:not(.card-outline) .btn-tool:hover {
  color: #1F2D3D;
}

.card-danger:not(.card-outline) .card-header {
  background-color: #dc3545;
  border-bottom: 0;
}
.card-danger:not(.card-outline) .card-header,
.card-danger:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-danger.card-outline {
  border-top: 3px solid #dc3545;
}

.bg-danger .btn-tool, .label-danger .btn-tool, .alert-danger .btn-tool,
.alert-error .btn-tool,
.bg-danger-gradient .btn-tool,
.card-danger:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-danger .btn-tool:hover, .label-danger .btn-tool:hover, .alert-danger .btn-tool:hover,
.alert-error .btn-tool:hover,
.bg-danger-gradient .btn-tool:hover,
.card-danger:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-light:not(.card-outline) .card-header {
  background-color: #f8f9fa;
  border-bottom: 0;
}
.card-light:not(.card-outline) .card-header,
.card-light:not(.card-outline) .card-header a {
  color: #1F2D3D;
}
.card-light.card-outline {
  border-top: 3px solid #f8f9fa;
}

.bg-light .btn-tool,
.bg-light-gradient .btn-tool,
.card-light:not(.card-outline) .btn-tool {
  color: rgba(31, 45, 61, 0.8);
}
.bg-light .btn-tool:hover,
.bg-light-gradient .btn-tool:hover,
.card-light:not(.card-outline) .btn-tool:hover {
  color: #1F2D3D;
}

.card-dark:not(.card-outline) .card-header {
  background-color: #343a40;
  border-bottom: 0;
}
.card-dark:not(.card-outline) .card-header,
.card-dark:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-dark.card-outline {
  border-top: 3px solid #343a40;
}

.bg-dark .btn-tool,
.bg-dark-gradient .btn-tool,
.card-dark:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-dark .btn-tool:hover,
.bg-dark-gradient .btn-tool:hover,
.card-dark:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-cust-dark:not(.card-outline) .card-header {
  background-color: #434b62;
  border-bottom: 0;
}
.card-cust-dark:not(.card-outline) .card-header,
.card-cust-dark:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-cust-dark.card-outline {
  border-top: 3px solid #434b62;
}

.bg-cust-dark .btn-tool,
.bg-cust-dark-gradient .btn-tool,
.card-cust-dark:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-cust-dark .btn-tool:hover,
.bg-cust-dark-gradient .btn-tool:hover,
.card-cust-dark:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-cust-dark-nav:not(.card-outline) .card-header {
  background-color: #222632;
  border-bottom: 0;
}
.card-cust-dark-nav:not(.card-outline) .card-header,
.card-cust-dark-nav:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-cust-dark-nav.card-outline {
  border-top: 3px solid #222632;
}

.bg-cust-dark-nav .btn-tool,
.bg-cust-dark-nav-gradient .btn-tool,
.card-cust-dark-nav:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-cust-dark-nav .btn-tool:hover,
.bg-cust-dark-nav-gradient .btn-tool:hover,
.card-cust-dark-nav:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-purple:not(.card-outline) .card-header {
  background-color: #6423ad;
  border-bottom: 0;
}
.card-purple:not(.card-outline) .card-header,
.card-purple:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-purple.card-outline {
  border-top: 3px solid #6423ad;
}

.bg-purple .btn-tool,
.bg-purple-gradient .btn-tool,
.card-purple:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-purple .btn-tool:hover,
.bg-purple-gradient .btn-tool:hover,
.card-purple:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-blue:not(.card-outline) .card-header {
  background-color: #0076ba;
  border-bottom: 0;
}
.card-blue:not(.card-outline) .card-header,
.card-blue:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-blue.card-outline {
  border-top: 3px solid #0076ba;
}

.bg-blue .btn-tool,
.bg-blue-gradient .btn-tool,
.card-blue:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-blue .btn-tool:hover,
.bg-blue-gradient .btn-tool:hover,
.card-blue:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

.card-red:not(.card-outline) .card-header {
  background-color: #f11514;
  border-bottom: 0;
}
.card-red:not(.card-outline) .card-header,
.card-red:not(.card-outline) .card-header a {
  color: #ffffff;
}
.card-red.card-outline {
  border-top: 3px solid #f11514;
}

.bg-red .btn-tool,
.bg-red-gradient .btn-tool,
.card-red:not(.card-outline) .btn-tool {
  color: rgba(255, 255, 255, 0.8);
}
.bg-red .btn-tool:hover,
.bg-red-gradient .btn-tool:hover,
.card-red:not(.card-outline) .btn-tool:hover {
  color: #ffffff;
}

/*
 * Component: Info Box
 * -------------------
 */
.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  padding: 0.5rem;
  min-height: 80px;
  background: #ffffff;
}
.info-box .progress {
  background-color: rgba(0, 0, 0, 0.125);
  margin: 5px 0;
  height: 2px;
}
.info-box .progress .progress-bar {
  background-color: #ffffff;
}

.info-box-icon {
  border-radius: 0.25rem;
  display: block;
  width: 70px;
  text-align: center;
  font-size: 30px;
}
.info-box-icon > img {
  max-width: 100%;
}

.info-box-content {
  padding: 5px 10px;
  flex: 1;
}

.info-box-number {
  display: block;
  font-weight: 700;
}

.progress-description,
.info-box-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box.bg-primary, .info-box.label-primary,
.info-box.bg-primary-gradient {
  color: #ffffff;
}
.info-box.bg-primary .progress-bar, .info-box.label-primary .progress-bar,
.info-box.bg-primary-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-secondary,
.info-box.bg-secondary-gradient {
  color: #ffffff;
}
.info-box.bg-secondary .progress-bar,
.info-box.bg-secondary-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-success, .info-box.label-success, .info-box.alert-success,
.info-box.bg-success-gradient {
  color: #ffffff;
}
.info-box.bg-success .progress-bar, .info-box.label-success .progress-bar, .info-box.alert-success .progress-bar,
.info-box.bg-success-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-info, .info-box.label-info, .info-box.alert-info,
.info-box.bg-info-gradient {
  color: #ffffff;
}
.info-box.bg-info .progress-bar, .info-box.label-info .progress-bar, .info-box.alert-info .progress-bar,
.info-box.bg-info-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-warning, .info-box.label-warning, .info-box.alert-warning,
.info-box.bg-warning-gradient {
  color: #1F2D3D;
}
.info-box.bg-warning .progress-bar, .info-box.label-warning .progress-bar, .info-box.alert-warning .progress-bar,
.info-box.bg-warning-gradient .progress-bar {
  background-color: #1F2D3D;
}

.info-box.bg-danger, .info-box.label-danger, .info-box.alert-danger,
.info-box.alert-error,
.info-box.bg-danger-gradient {
  color: #ffffff;
}
.info-box.bg-danger .progress-bar, .info-box.label-danger .progress-bar, .info-box.alert-danger .progress-bar,
.info-box.alert-error .progress-bar,
.info-box.bg-danger-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-light,
.info-box.bg-light-gradient {
  color: #1F2D3D;
}
.info-box.bg-light .progress-bar,
.info-box.bg-light-gradient .progress-bar {
  background-color: #1F2D3D;
}

.info-box.bg-dark,
.info-box.bg-dark-gradient {
  color: #ffffff;
}
.info-box.bg-dark .progress-bar,
.info-box.bg-dark-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-cust-dark,
.info-box.bg-cust-dark-gradient {
  color: #ffffff;
}
.info-box.bg-cust-dark .progress-bar,
.info-box.bg-cust-dark-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-cust-dark-nav,
.info-box.bg-cust-dark-nav-gradient {
  color: #ffffff;
}
.info-box.bg-cust-dark-nav .progress-bar,
.info-box.bg-cust-dark-nav-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-purple,
.info-box.bg-purple-gradient {
  color: #ffffff;
}
.info-box.bg-purple .progress-bar,
.info-box.bg-purple-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-blue,
.info-box.bg-blue-gradient {
  color: #ffffff;
}
.info-box.bg-blue .progress-bar,
.info-box.bg-blue-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box.bg-red,
.info-box.bg-red-gradient {
  color: #ffffff;
}
.info-box.bg-red .progress-bar,
.info-box.bg-red-gradient .progress-bar {
  background-color: #ffffff;
}

.info-box-more {
  display: block;
}

.progress-description {
  margin: 0;
}

/*
 * Component: Timeline
 * -------------------
 */
.timeline {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 0.25rem;
}
.timeline > li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}
.timeline > li::after {
  display: block;
  clear: both;
  content: "";
}
.timeline > li > .timeline-item {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  margin-top: 0;
  background: #ffffff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}
.timeline > li > .timeline-item > .time {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px;
}
.timeline > li > .timeline-item > .timeline-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
}
.timeline > li > .timeline-item > .timeline-header > a {
  font-weight: 600;
}
.timeline > li > .timeline-item > .timeline-body, .timeline > li > .timeline-item > .timeline-footer {
  padding: 10px;
}
.timeline > li > .fa,
.timeline > li > .glyphicon,
.timeline > li > .ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  background: #adb5bd;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}
.timeline > .time-label > span {
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 4px;
}

.timeline-inverse > li > .timeline-item {
  background: #f8f9fa;
  border: 1px solid #ddd;
  box-shadow: none;
}
.timeline-inverse > li > .timeline-item > .timeline-header {
  border-bottom-color: #ddd;
}

/*
 * Component: Button
 * -----------------
 */
.btn.btn-flat {
  border-radius: 0;
  box-shadow: none;
  border-width: 1px;
}
.btn.btn-file {
  position: relative;
  overflow: hidden;
}
.btn.btn-file > input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
}
.btn-default:hover, .btn-default:active, .btn-default.hover {
  background-color: #e7e7e7;
}

.btn-app {
  border-radius: 3px;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
}
.btn-app > .fa,
.btn-app > .glyphicon,
.btn-app > .ion {
  font-size: 20px;
  display: block;
}
.btn-app:hover {
  background: #f4f4f4;
  color: #444;
  border-color: #aaa;
}
.btn-app:active, .btn-app:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-app > .badge {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 10px;
  font-weight: 400;
}

/*
 * Component: Callout
 * ------------------
 */
.callout {
  border-radius: 0.25rem;
  background-color: #ffffff;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-left: 5px solid #eee;
}
.callout a {
  color: #ffffff;
  text-decoration: underline;
}
.callout a:hover {
  color: #eee;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout.callout-danger {
  border-left-color: #bd2130;
}
.callout.callout-warning {
  border-left-color: #c59400;
}
.callout.callout-info {
  border-left-color: #117a8b;
}
.callout.callout-success {
  border-left-color: #1e7e34;
}

/*
 * Component: alert
 * ----------------
 */
.alert .icon {
  margin-right: 10px;
}
.alert .close {
  color: #000;
  opacity: 0.2;
}
.alert .close:hover {
  opacity: 0.5;
}
.alert a {
  color: #ffffff;
  text-decoration: underline;
}

.alert-success {
  border-color: #23923d;
}

.alert-danger,
.alert-error {
  border-color: #d32535;
}

.alert-warning {
  border-color: #dfa700;
}

.alert-info {
  border-color: #148ea1;
}

/*
 * Component: Nav
 * --------------
 */
.nav-pills .nav-link {
  color: #6c757d;
}
.nav-pills .nav-link:not(.active):hover {
  color: #838ba2;
}

/*
 * Component: Products List
 * ------------------------
 */
.products-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.products-list > .item {
  border-radius: 0.25rem;
  padding: 10px 0;
  background: #ffffff;
}
.products-list > .item::after {
  display: block;
  clear: both;
  content: "";
}
.products-list .product-img {
  float: left;
}
.products-list .product-img img {
  width: 50px;
  height: 50px;
}
.products-list .product-info {
  margin-left: 60px;
}
.products-list .product-title {
  font-weight: 600;
}
.products-list .product-description {
  display: block;
  color: #6c757d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-list-in-card > .item {
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.product-list-in-card > .item:last-of-type {
  border-bottom-width: 0;
}

/*
 * Component: Table
 * ----------------
 */
.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0;
}

.table.text-center, .table.text-center td, .table.text-center th {
  text-align: center;
}

.table-valign-middle thead > tr > th,
.table-valign-middle thead > tr > td,
.table-valign-middle tbody > tr > th,
.table-valign-middle tbody > tr > td {
  vertical-align: middle;
}

/*
 * Component: Label
 * ----------------
 */
.label-default {
  background-color: #adb5bd;
  color: #444;
}

/*
 * Component: Direct Chat
 * ----------------------
 */
.direct-chat .card-body {
  position: relative;
  overflow-x: hidden;
  padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto;
}

.direct-chat-msg,
.direct-chat-text {
  display: block;
}

.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg::after {
  display: block;
  clear: both;
  content: "";
}

.direct-chat-messages,
.direct-chat-contacts {
  transition: transform 0.5s ease-in-out;
}

.direct-chat-text {
  border-radius: 0.3rem;
  position: relative;
  padding: 5px 10px;
  background: #d2d6de;
  border: 1px solid #d2d6de;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-text:after, .direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #d2d6de;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after, .right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #d2d6de;
}

.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.right .direct-chat-img {
  float: right;
}

.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 0.75rem;
}

.direct-chat-name {
  font-weight: 600;
}

.direct-chat-timestamp {
  color: #999;
}

.direct-chat-contacts-open .direct-chat-contacts {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.direct-chat-contacts {
  -webkit-transform: translate(101%, 0);
  -ms-transform: translate(101%, 0);
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #ffffff;
  overflow: auto;
}

.contacts-list > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}
.contacts-list > li::after {
  display: block;
  clear: both;
  content: "";
}
.contacts-list > li:last-of-type {
  border-bottom: none;
}

.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}

.contacts-list-info {
  margin-left: 45px;
  color: #ffffff;
}

.contacts-list-name,
.contacts-list-status {
  display: block;
}

.contacts-list-name {
  font-weight: 600;
}

.contacts-list-status {
  font-size: 0.75rem;
}

.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}

.contacts-list-msg {
  color: #999;
}

.direct-chat-danger .right > .direct-chat-text {
  background: #dc3545;
  border-color: #dc3545;
  color: #ffffff;
}
.direct-chat-danger .right > .direct-chat-text:after, .direct-chat-danger .right > .direct-chat-text:before {
  border-left-color: #dc3545;
}

.direct-chat-primary .right > .direct-chat-text {
  background: #838ba2;
  border-color: #838ba2;
  color: #ffffff;
}
.direct-chat-primary .right > .direct-chat-text:after, .direct-chat-primary .right > .direct-chat-text:before {
  border-left-color: #838ba2;
}

.direct-chat-warning .right > .direct-chat-text {
  background: #f8ba00;
  border-color: #f8ba00;
  color: #1F2D3D;
}
.direct-chat-warning .right > .direct-chat-text:after, .direct-chat-warning .right > .direct-chat-text:before {
  border-left-color: #f8ba00;
}

.direct-chat-info .right > .direct-chat-text {
  background: #17a2b8;
  border-color: #17a2b8;
  color: #ffffff;
}
.direct-chat-info .right > .direct-chat-text:after, .direct-chat-info .right > .direct-chat-text:before {
  border-left-color: #17a2b8;
}

.direct-chat-success .right > .direct-chat-text {
  background: #28a745;
  border-color: #28a745;
  color: #ffffff;
}
.direct-chat-success .right > .direct-chat-text:after, .direct-chat-success .right > .direct-chat-text:before {
  border-left-color: #28a745;
}

/*
 * Component: Users List
 * ---------------------
 */
.users-list > li {
  width: 25%;
  float: left;
  padding: 10px;
  text-align: center;
}
.users-list > li img {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
}
.users-list > li > a:hover,
.users-list > li > a:hover .users-list-name {
  color: #999;
}

.users-list-name,
.users-list-date {
  display: block;
}

.users-list-name {
  font-size: 0.75rem;
  color: #444;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users-list-date {
  color: #999;
  font-size: 12px;
}

/*
 * Component: Carousel
 * -------------------
 */
.carousel-control.left, .carousel-control.right {
  background-image: none;
}
.carousel-control > .fa {
  font-size: 40px;
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -20px;
}

/*
 * Component: Social Widgets
 * -------------------------
 */
.card-widget {
  border: none;
  position: relative;
}

.widget-user .widget-user-header {
  padding: 1rem;
  height: 120px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.widget-user .widget-user-username {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.widget-user .widget-user-desc {
  margin-top: 0;
}
.widget-user .widget-user-image {
  position: absolute;
  top: 65px;
  left: 50%;
  margin-left: -45px;
}
.widget-user .widget-user-image > img {
  width: 90px;
  height: auto;
  border: 3px solid #ffffff;
}
.widget-user .card-footer {
  padding-top: 40px;
}

.widget-user-2 .widget-user-header {
  padding: 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.widget-user-2 .widget-user-username {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 300;
}
.widget-user-2 .widget-user-desc {
  margin-top: 0;
}
.widget-user-2 .widget-user-username,
.widget-user-2 .widget-user-desc {
  margin-left: 75px;
}
.widget-user-2 .widget-user-image > img {
  width: 65px;
  height: auto;
  float: left;
}

/*
 * General: Miscellaneous
 * ----------------------
 */
a.text-muted:hover {
  color: #838ba2 !important;
}

.border-transparent {
  border-color: transparent !important;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}
.description-block.margin-bottom {
  margin-bottom: 25px;
}
.description-block > .description-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}
.description-block > .description-text {
  text-transform: uppercase;
}

.bg-primary, .label-primary,
.bg-primary a,
.label-primary a {
  color: #ffffff !important;
}

.bg-secondary,
.bg-secondary a {
  color: #ffffff !important;
}

.bg-success, .alert-success, .label-success,
.bg-success a,
.alert-success a,
.label-success a {
  color: #ffffff !important;
}

.bg-info, .alert-info, .label-info,
.bg-info a,
.alert-info a,
.label-info a {
  color: #ffffff !important;
}

.bg-warning, .alert-warning, .label-warning,
.bg-warning a,
.alert-warning a,
.label-warning a {
  color: #1F2D3D !important;
}

.bg-danger, .alert-danger,
.alert-error, .label-danger,
.bg-danger a,
.alert-danger a,
.alert-error a,
.label-danger a {
  color: #ffffff !important;
}

.bg-light,
.bg-light a {
  color: #1F2D3D !important;
}

.bg-dark,
.bg-dark a {
  color: #ffffff !important;
}

.bg-cust-dark,
.bg-cust-dark a {
  color: #ffffff !important;
}

.bg-cust-dark-nav,
.bg-cust-dark-nav a {
  color: #ffffff !important;
}

.bg-purple,
.bg-purple a {
  color: #ffffff !important;
}

.bg-blue,
.bg-blue a {
  color: #ffffff !important;
}

.bg-red,
.bg-red a {
  color: #ffffff !important;
}

.bg-gray {
  color: #000;
  background-color: #adb5bd;
}

.bg-gray-light {
  background-color: #f2f4f5;
  color: #1F2D3D !important;
}

.bg-black {
  background-color: #000;
  color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff;
  color: #1F2D3D !important;
}

[class^=bg-].disabled {
  opacity: 0.65;
}

.link-muted {
  color: #5d6974;
}
.link-muted:hover, .link-muted:focus {
  color: #464f58;
}

.link-black {
  color: #666;
}
.link-black:hover, .link-black:focus {
  color: #999;
}

.hide {
  display: none !important;
}

.no-border {
  border: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.list-unstyled, .chart-legend, .contacts-list, .users-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-group-unbordered > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.flat {
  border-radius: 0 !important;
}

.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}

.bg-primary-gradient {
  background: #838ba2;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #838ba2), color-stop(1, #a0a6b8));
  background: -ms-linear-gradient(bottom, #838ba2, #a0a6b8);
  background: -moz-linear-gradient(center bottom, #838ba2 0%, #a0a6b8 100%);
  background: -o-linear-gradient(#a0a6b8, #838ba2);
  color: #ffffff;
}

.bg-secondary-gradient {
  background: #6c757d;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #6c757d), color-stop(1, #868e96));
  background: -ms-linear-gradient(bottom, #6c757d, #868e96);
  background: -moz-linear-gradient(center bottom, #6c757d 0%, #868e96 100%);
  background: -o-linear-gradient(#868e96, #6c757d);
  color: #ffffff;
}

.bg-success-gradient {
  background: #28a745;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #28a745), color-stop(1, #34ce57));
  background: -ms-linear-gradient(bottom, #28a745, #34ce57);
  background: -moz-linear-gradient(center bottom, #28a745 0%, #34ce57 100%);
  background: -o-linear-gradient(#34ce57, #28a745);
  color: #ffffff;
}

.bg-info-gradient {
  background: #17a2b8;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #17a2b8), color-stop(1, #1fc8e3));
  background: -ms-linear-gradient(bottom, #17a2b8, #1fc8e3);
  background: -moz-linear-gradient(center bottom, #17a2b8 0%, #1fc8e3 100%);
  background: -o-linear-gradient(#1fc8e3, #17a2b8);
  color: #ffffff;
}

.bg-warning-gradient {
  background: #f8ba00;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f8ba00), color-stop(1, #ffca2c));
  background: -ms-linear-gradient(bottom, #f8ba00, #ffca2c);
  background: -moz-linear-gradient(center bottom, #f8ba00 0%, #ffca2c 100%);
  background: -o-linear-gradient(#ffca2c, #f8ba00);
  color: #1F2D3D;
}

.bg-danger-gradient {
  background: #dc3545;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #dc3545), color-stop(1, #e4606d));
  background: -ms-linear-gradient(bottom, #dc3545, #e4606d);
  background: -moz-linear-gradient(center bottom, #dc3545 0%, #e4606d 100%);
  background: -o-linear-gradient(#e4606d, #dc3545);
  color: #ffffff;
}

.bg-light-gradient {
  background: #f8f9fa;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f8f9fa), color-stop(1, white));
  background: -ms-linear-gradient(bottom, #f8f9fa, white);
  background: -moz-linear-gradient(center bottom, #f8f9fa 0%, white 100%);
  background: -o-linear-gradient(white, #f8f9fa);
  color: #1F2D3D;
}

.bg-dark-gradient {
  background: #343a40;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #343a40), color-stop(1, #4b545c));
  background: -ms-linear-gradient(bottom, #343a40, #4b545c);
  background: -moz-linear-gradient(center bottom, #343a40 0%, #4b545c 100%);
  background: -o-linear-gradient(#4b545c, #343a40);
  color: #ffffff;
}

.bg-cust-dark-gradient {
  background: #434b62;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #434b62), color-stop(1, #586280));
  background: -ms-linear-gradient(bottom, #434b62, #586280);
  background: -moz-linear-gradient(center bottom, #434b62 0%, #586280 100%);
  background: -o-linear-gradient(#586280, #434b62);
  color: #ffffff;
}

.bg-cust-dark-nav-gradient {
  background: #222632;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #222632), color-stop(1, #373d50));
  background: -ms-linear-gradient(bottom, #222632, #373d50);
  background: -moz-linear-gradient(center bottom, #222632 0%, #373d50 100%);
  background: -o-linear-gradient(#373d50, #222632);
  color: #ffffff;
}

.bg-purple-gradient {
  background: #6423ad;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #6423ad), color-stop(1, #7d2ed5));
  background: -ms-linear-gradient(bottom, #6423ad, #7d2ed5);
  background: -moz-linear-gradient(center bottom, #6423ad 0%, #7d2ed5 100%);
  background: -o-linear-gradient(#7d2ed5, #6423ad);
  color: #ffffff;
}

.bg-blue-gradient {
  background: #0076ba;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #0076ba), color-stop(1, #0096ed));
  background: -ms-linear-gradient(bottom, #0076ba, #0096ed);
  background: -moz-linear-gradient(center bottom, #0076ba 0%, #0096ed 100%);
  background: -o-linear-gradient(#0096ed, #0076ba);
  color: #ffffff;
}

.bg-red-gradient {
  background: #f11514;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f11514), color-stop(1, #f44544));
  background: -ms-linear-gradient(bottom, #f11514, #f44544);
  background: -moz-linear-gradient(center bottom, #f11514 0%, #f44544 100%);
  background: -o-linear-gradient(#f44544, #f11514);
  color: #ffffff;
}

.description-block .description-icon {
  font-size: 16px;
}

.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}

.list-seperator {
  height: 1px;
  background: rgba(0, 0, 0, 0.125);
  margin: 15px 0 9px 0;
}

.list-link > a {
  padding: 4px;
  color: #777;
}
.list-link > a:hover {
  color: #222;
}

.user-block::after {
  display: block;
  clear: both;
  content: "";
}
.user-block img {
  width: 40px;
  height: 40px;
  float: left;
}
.user-block .username,
.user-block .description,
.user-block .comment {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
}
.user-block .description {
  color: #999;
  font-size: 13px;
}
.user-block.user-block-sm .username,
.user-block.user-block-sm .description,
.user-block.user-block-sm .comment {
  margin-left: 40px;
}
.user-block.user-block-sm .username {
  font-size: 14px;
}

.img-sm, .card-comments .card-comment img, .user-block.user-block-sm img,
.img-md,
.img-lg {
  float: left;
}

.img-sm, .card-comments .card-comment img, .user-block.user-block-sm img {
  width: 30px !important;
  height: 30px !important;
}
.img-sm + .img-push, .card-comments .card-comment img + .img-push, .user-block.user-block-sm img + .img-push {
  margin-left: 40px;
}

.img-md {
  width: 60px;
  height: 60px;
}
.img-md + .img-push {
  margin-left: 70px;
}

.img-lg {
  width: 100px;
  height: 100px;
}
.img-lg + .img-push {
  margin-left: 110px;
}

.img-bordered {
  border: 3px solid #adb5bd;
  padding: 3px;
}

.img-bordered-sm {
  border: 2px solid #adb5bd;
  padding: 2px;
}

.img-rounded {
  border-radius: 0.25rem;
}

.img-circle {
  border-radius: 50%;
}

.img-size-64,
.img-size-50,
.img-size-32 {
  height: auto;
}

.img-size-64 {
  width: 64px;
}

.img-size-50 {
  width: 50px;
}

.img-size-32 {
  width: 32px;
}

.size-32,
.size-40,
.size-50 {
  display: block;
  text-align: center;
}

.size-32 {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.size-40 {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.size-50 {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.attachment-block {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;
}
.attachment-block .attachment-img {
  max-width: 100px;
  max-height: 100px;
  height: auto;
  float: left;
}
.attachment-block .attachment-pushed {
  margin-left: 110px;
}
.attachment-block .attachment-heading {
  margin: 0;
}
.attachment-block .attachment-text {
  color: #555;
}

.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}

.chart {
  position: relative;
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

/*
 * Misc: print
 * -----------
 */
@media print {
  .no-print, .main-sidebar,
  .main-header,
  .content-header {
    display: none !important;
  }
  .content-wrapper,
  .main-footer {
    margin-left: 0 !important;
    min-height: 0 !important;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .layout-fixed .content-wrapper {
    padding-top: 0 !important;
  }
  .invoice {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
  }
  .invoice-col {
    float: left;
    width: 33.3333333%;
  }
  .table-responsive {
    overflow: auto;
  }
  .table-responsive > .table tr th,
  .table-responsive > .table tr td {
    white-space: normal !important;
  }
}
.text-bold, .text-bold.table td, .text-bold.table th {
  font-weight: 700;
}

.text-sm {
  font-size: 0.75rem;
}

.text-xs {
  font-size: 0.875rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.75rem;
}

.elevation-0 {
  box-shadow: none;
}

.elevation-1, .sidebar-sgb .nav-sidebar .nav-item > .nav-link.active, .skin-sgb-night .main-sidebar .nav-sidebar .nav-item > .nav-link.active, .skin-sgb .main-sidebar .nav-sidebar .nav-item > .nav-link.active, .sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-secondary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-secondary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-success .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-success .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-info .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-info .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-warning .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-warning .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-danger .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-danger .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-light .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-light .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-dark .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-dark .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-cust-dark .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-cust-dark .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-cust-dark-nav .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-cust-dark-nav .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-purple .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-purple .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-blue .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-blue .nav-sidebar > .nav-item > .nav-link.active, .sidebar-dark-red .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-red .nav-sidebar > .nav-item > .nav-link.active, .callout {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.elevation-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.elevation-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.elevation-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.elevation-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/*
// Simple import - maybe later when adminlte dev3 will be updated with latest bootstrap variables

// AdminLTE Bootstrap variables
@import "variables/bootstrap";  // Custom AdminLTE bootstrap-variables overrides
@import "variables/adminlte";   // Custom AdminLTE variable overrides

// Bootstrap library
@import "ember-bootstrap/bootstrap";

// AdminLTE raw
@import "AdminLTE-raw";
*/
/* custom colors */
.bg-bg {
  background-color: #3b367f !important;
}

.text-bg {
  color: #3b367f !important;
}

:not(.jstree) .text-bg i:before,
:not(.jstree) i.text-bg:before {
  background: linear-gradient(180deg, #3b367f 0%, #1a1837 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-bg {
  border: 1px solid #3b367f;
  border-color: #3b367f !important;
}

.bg-bg.darken-1 {
  background-color: #332e6d !important;
}

.bg-bg.lighten-1 {
  background-color: #433e91 !important;
}

.bg-bg.darken-2 {
  background-color: #2a275b !important;
}

.bg-bg.lighten-2 {
  background-color: #4c45a3 !important;
}

.bg-bg.darken-3 {
  background-color: #221f49 !important;
}

.bg-bg.lighten-3 {
  background-color: #554eb4 !important;
}

.bg-bg.darken-4 {
  background-color: #1a1837 !important;
}

.bg-bg.lighten-4 {
  background-color: #6660bb !important;
}

.bg-red {
  background-color: #f11514 !important;
}

.text-red {
  color: #f11514 !important;
}

:not(.jstree) .text-red i:before,
:not(.jstree) i.text-red:before {
  background: linear-gradient(180deg, #f11514 0%, #960a09 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-red {
  border: 1px solid #f11514;
  border-color: #f11514 !important;
}

.bg-red.darken-1 {
  background-color: #de0e0d !important;
}

.bg-red.lighten-1 {
  background-color: #f22d2c !important;
}

.bg-red.darken-2 {
  background-color: #c60d0c !important;
}

.bg-red.lighten-2 {
  background-color: #f44544 !important;
}

.bg-red.darken-3 {
  background-color: #ae0b0a !important;
}

.bg-red.lighten-3 {
  background-color: #f55d5c !important;
}

.bg-red.darken-4 {
  background-color: #960a09 !important;
}

.bg-red.lighten-4 {
  background-color: #f77574 !important;
}

.bg-green {
  background-color: #22c323 !important;
}

.text-green {
  color: #22c323 !important;
}

:not(.jstree) .text-green i:before,
:not(.jstree) i.text-green:before {
  background: linear-gradient(180deg, #22c323 0%, #136c13 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-green {
  border: 1px solid #22c323;
  border-color: #22c323 !important;
}

.bg-green.darken-1 {
  background-color: #1ead1f !important;
}

.bg-green.lighten-1 {
  background-color: #26d927 !important;
}

.bg-green.darken-2 {
  background-color: #1a981b !important;
}

.bg-green.lighten-2 {
  background-color: #3bdd3c !important;
}

.bg-green.darken-3 {
  background-color: #178217 !important;
}

.bg-green.lighten-3 {
  background-color: #51e152 !important;
}

.bg-green.darken-4 {
  background-color: #136c13 !important;
}

.bg-green.lighten-4 {
  background-color: #67e467 !important;
}

.bg-highlight1 {
  background-color: #fb2684 !important;
}

.text-highlight1 {
  color: #fb2684 !important;
}

:not(.jstree) .text-highlight1 i:before,
:not(.jstree) i.text-highlight1:before {
  background: linear-gradient(180deg, #fb2684 0%, #b80353 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-highlight1 {
  border: 1px solid #fb2684;
  border-color: #fb2684 !important;
}

.bg-highlight1.darken-1 {
  background-color: #fb0d76 !important;
}

.bg-highlight1.lighten-1 {
  background-color: #fb3f92 !important;
}

.bg-highlight1.darken-2 {
  background-color: #ea046a !important;
}

.bg-highlight1.lighten-2 {
  background-color: #fc58a0 !important;
}

.bg-highlight1.darken-3 {
  background-color: #d1045e !important;
}

.bg-highlight1.lighten-3 {
  background-color: #fc71af !important;
}

.bg-highlight1.darken-4 {
  background-color: #b80353 !important;
}

.bg-highlight1.lighten-4 {
  background-color: #fd8abd !important;
}

.bg-highlight2 {
  background-color: #a922a9 !important;
}

.text-highlight2 {
  color: #a922a9 !important;
}

:not(.jstree) .text-highlight2 i:before,
:not(.jstree) i.text-highlight2:before {
  background: linear-gradient(180deg, #a922a9 0%, #541154 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-highlight2 {
  border: 1px solid #a922a9;
  border-color: #a922a9 !important;
}

.bg-highlight2.darken-1 {
  background-color: #941e94 !important;
}

.bg-highlight2.lighten-1 {
  background-color: #be26be !important;
}

.bg-highlight2.darken-2 {
  background-color: #7f197f !important;
}

.bg-highlight2.lighten-2 {
  background-color: #d32bd3 !important;
}

.bg-highlight2.darken-3 {
  background-color: #691569 !important;
}

.bg-highlight2.lighten-3 {
  background-color: #d83fd8 !important;
}

.bg-highlight2.darken-4 {
  background-color: #541154 !important;
}

.bg-highlight2.lighten-4 {
  background-color: #dd54dd !important;
}

.bg-light-blue {
  background-color: #3c8dbc !important;
}

.text-light-blue {
  color: #3c8dbc !important;
}

:not(.jstree) .text-light-blue i:before,
:not(.jstree) i.text-light-blue:before {
  background: linear-gradient(180deg, #3c8dbc 0%, #23536f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-light-blue {
  border: 1px solid #3c8dbc;
  border-color: #3c8dbc !important;
}

.bg-light-blue.darken-1 {
  background-color: #367fa9 !important;
}

.bg-light-blue.lighten-1 {
  background-color: #4c99c6 !important;
}

.bg-light-blue.darken-2 {
  background-color: #307095 !important;
}

.bg-light-blue.lighten-2 {
  background-color: #5fa4cc !important;
}

.bg-light-blue.darken-3 {
  background-color: #296282 !important;
}

.bg-light-blue.lighten-3 {
  background-color: #72afd2 !important;
}

.bg-light-blue.darken-4 {
  background-color: #23536f !important;
}

.bg-light-blue.lighten-4 {
  background-color: #86bad8 !important;
}

.bg-light-blue-2 {
  background-color: #2c85f5 !important;
}

.text-light-blue-2 {
  color: #2c85f5 !important;
}

:not(.jstree) .text-light-blue-2 i:before,
:not(.jstree) i.text-light-blue-2:before {
  background: linear-gradient(180deg, #2c85f5 0%, #0854b3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-light-blue-2 {
  border: 1px solid #2c85f5;
  border-color: #2c85f5 !important;
}

.bg-light-blue-2.darken-1 {
  background-color: #1477f4 !important;
}

.bg-light-blue-2.lighten-1 {
  background-color: #4493f6 !important;
}

.bg-light-blue-2.darken-2 {
  background-color: #0b6be3 !important;
}

.bg-light-blue-2.lighten-2 {
  background-color: #5da1f7 !important;
}

.bg-light-blue-2.darken-3 {
  background-color: #0a5fcb !important;
}

.bg-light-blue-2.lighten-3 {
  background-color: #75aff8 !important;
}

.bg-light-blue-2.darken-4 {
  background-color: #0854b3 !important;
}

.bg-light-blue-2.lighten-4 {
  background-color: #8dbdfa !important;
}

.bg-primary, .label-primary {
  background-color: #3c8dbc !important;
}

.text-primary {
  color: #3c8dbc !important;
}

:not(.jstree) .text-primary i:before,
:not(.jstree) i.text-primary:before {
  background: linear-gradient(180deg, #3c8dbc 0%, #23536f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-primary {
  border: 1px solid #3c8dbc;
  border-color: #3c8dbc !important;
}

.bg-primary.darken-1, .darken-1.label-primary {
  background-color: #367fa9 !important;
}

.bg-primary.lighten-1, .lighten-1.label-primary {
  background-color: #4c99c6 !important;
}

.bg-primary.darken-2, .darken-2.label-primary {
  background-color: #307095 !important;
}

.bg-primary.lighten-2, .lighten-2.label-primary {
  background-color: #5fa4cc !important;
}

.bg-primary.darken-3, .darken-3.label-primary {
  background-color: #296282 !important;
}

.bg-primary.lighten-3, .lighten-3.label-primary {
  background-color: #72afd2 !important;
}

.bg-primary.darken-4, .darken-4.label-primary {
  background-color: #23536f !important;
}

.bg-primary.lighten-4, .lighten-4.label-primary {
  background-color: #86bad8 !important;
}

.bg-info, .alert-info, .label-info {
  background-color: #00c0ef !important;
}

.text-info {
  color: #00c0ef !important;
}

:not(.jstree) .text-info i:before,
:not(.jstree) i.text-info:before {
  background: linear-gradient(180deg, #00c0ef 0%, #006e89 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-info {
  border: 1px solid #00c0ef;
  border-color: #00c0ef !important;
}

.bg-info.darken-1, .darken-1.alert-info, .darken-1.label-info {
  background-color: #00acd6 !important;
}

.bg-info.lighten-1, .lighten-1.alert-info, .lighten-1.label-info {
  background-color: #0acfff !important;
}

.bg-info.darken-2, .darken-2.alert-info, .darken-2.label-info {
  background-color: #0097bc !important;
}

.bg-info.lighten-2, .lighten-2.alert-info, .lighten-2.label-info {
  background-color: #23d4ff !important;
}

.bg-info.darken-3, .darken-3.alert-info, .darken-3.label-info {
  background-color: #0083a3 !important;
}

.bg-info.lighten-3, .lighten-3.alert-info, .lighten-3.label-info {
  background-color: #3dd9ff !important;
}

.bg-info.darken-4, .darken-4.alert-info, .darken-4.label-info {
  background-color: #006e89 !important;
}

.bg-info.lighten-4, .lighten-4.alert-info, .lighten-4.label-info {
  background-color: #56deff !important;
}

.bg-success, .alert-success, .label-success {
  background-color: #00a65a !important;
}

.text-success {
  color: #00a65a !important;
}

:not(.jstree) .text-success i:before,
:not(.jstree) i.text-success:before {
  background: linear-gradient(180deg, #00a65a 0%, #004023 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-success {
  border: 1px solid #00a65a;
  border-color: #00a65a !important;
}

.bg-success.darken-1, .darken-1.alert-success, .darken-1.label-success {
  background-color: #008d4c !important;
}

.bg-success.lighten-1, .lighten-1.alert-success, .lighten-1.label-success {
  background-color: #00c068 !important;
}

.bg-success.darken-2, .darken-2.alert-success, .darken-2.label-success {
  background-color: #00733e !important;
}

.bg-success.lighten-2, .lighten-2.alert-success, .lighten-2.label-success {
  background-color: #00d976 !important;
}

.bg-success.darken-3, .darken-3.alert-success, .darken-3.label-success {
  background-color: #005a31 !important;
}

.bg-success.lighten-3, .lighten-3.alert-success, .lighten-3.label-success {
  background-color: #00f383 !important;
}

.bg-success.darken-4, .darken-4.alert-success, .darken-4.label-success {
  background-color: #004023 !important;
}

.bg-success.lighten-4, .lighten-4.alert-success, .lighten-4.label-success {
  background-color: #0dff90 !important;
}

.bg-waring {
  background-color: #f39c12 !important;
}

.text-waring {
  color: #f39c12 !important;
}

:not(.jstree) .text-waring i:before,
:not(.jstree) i.text-waring:before {
  background: linear-gradient(180deg, #f39c12 0%, #976008 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-waring {
  border: 1px solid #f39c12;
  border-color: #f39c12 !important;
}

.bg-waring.darken-1 {
  background-color: #e08e0b !important;
}

.bg-waring.lighten-1 {
  background-color: #f4a62a !important;
}

.bg-waring.darken-2 {
  background-color: #c87f0a !important;
}

.bg-waring.lighten-2 {
  background-color: #f5b043 !important;
}

.bg-waring.darken-3 {
  background-color: #b06f09 !important;
}

.bg-waring.lighten-3 {
  background-color: #f7ba5b !important;
}

.bg-waring.darken-4 {
  background-color: #976008 !important;
}

.bg-waring.lighten-4 {
  background-color: #f8c573 !important;
}

.bg-danger, .alert-danger,
.alert-error, .label-danger {
  background-color: #f56954 !important;
}

.text-danger {
  color: #f56954 !important;
}

:not(.jstree) .text-danger i:before,
:not(.jstree) i.text-danger:before {
  background: linear-gradient(180deg, #f56954 0%, #d6270d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-danger {
  border: 1px solid #f56954;
  border-color: #f56954 !important;
}

.bg-danger.darken-1, .darken-1.alert-danger,
.darken-1.alert-error, .darken-1.label-danger {
  background-color: #f4543c !important;
}

.bg-danger.lighten-1, .lighten-1.alert-danger,
.lighten-1.alert-error, .lighten-1.label-danger {
  background-color: #f67e6c !important;
}

.bg-danger.darken-2, .darken-2.alert-danger,
.darken-2.alert-error, .darken-2.label-danger {
  background-color: #f23f24 !important;
}

.bg-danger.lighten-2, .lighten-2.alert-danger,
.lighten-2.alert-error, .lighten-2.label-danger {
  background-color: #f89384 !important;
}

.bg-danger.darken-3, .darken-3.alert-danger,
.darken-3.alert-error, .darken-3.label-danger {
  background-color: #ef2b0e !important;
}

.bg-danger.lighten-3, .lighten-3.alert-danger,
.lighten-3.alert-error, .lighten-3.label-danger {
  background-color: #f9a89c !important;
}

.bg-danger.darken-4, .darken-4.alert-danger,
.darken-4.alert-error, .darken-4.label-danger {
  background-color: #d6270d !important;
}

.bg-danger.lighten-4, .lighten-4.alert-danger,
.lighten-4.alert-error, .lighten-4.label-danger {
  background-color: #fbbeb4 !important;
}

.bg-gray {
  background-color: #d2d6de !important;
}

.text-gray {
  color: #d2d6de !important;
}

:not(.jstree) .text-gray i:before,
:not(.jstree) i.text-gray:before {
  background: linear-gradient(180deg, #d2d6de 0%, #97a0b3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-gray {
  border: 1px solid #d2d6de;
  border-color: #d2d6de !important;
}

.bg-gray.darken-1 {
  background-color: #c3c9d3 !important;
}

.bg-gray.lighten-1 {
  background-color: #e1e3e9 !important;
}

.bg-gray.darken-2 {
  background-color: #b5bbc8 !important;
}

.bg-gray.lighten-2 {
  background-color: #eff1f4 !important;
}

.bg-gray.darken-3 {
  background-color: #a6aebe !important;
}

.bg-gray.lighten-3 {
  background-color: #fefefe !important;
}

.bg-gray.darken-4 {
  background-color: #97a0b3 !important;
}

.bg-gray.lighten-4 {
  background-color: white !important;
}

.bg-navy {
  background-color: #001f3f !important;
}

.text-navy {
  color: #001f3f !important;
}

:not(.jstree) .text-navy i:before,
:not(.jstree) i.text-navy:before {
  background: linear-gradient(180deg, #001f3f 0%, black 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-navy {
  border: 1px solid #001f3f;
  border-color: #001f3f !important;
}

.bg-navy.darken-1 {
  background-color: #001226 !important;
}

.bg-navy.lighten-1 {
  background-color: #002c59 !important;
}

.bg-navy.darken-2 {
  background-color: #00060c !important;
}

.bg-navy.lighten-2 {
  background-color: #003872 !important;
}

.bg-navy.darken-3 {
  background-color: black !important;
}

.bg-navy.lighten-3 {
  background-color: #00458c !important;
}

.bg-navy.darken-4 {
  background-color: black !important;
}

.bg-navy.lighten-4 {
  background-color: #0051a5 !important;
}

.bg-teal {
  background-color: #39cccc !important;
}

.text-teal {
  color: #39cccc !important;
}

:not(.jstree) .text-teal i:before,
:not(.jstree) i.text-teal:before {
  background: linear-gradient(180deg, #39cccc 0%, #217e7e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-teal {
  border: 1px solid #39cccc;
  border-color: #39cccc !important;
}

.bg-teal.darken-1 {
  background-color: #30bbbb !important;
}

.bg-teal.lighten-1 {
  background-color: #4dd1d1 !important;
}

.bg-teal.darken-2 {
  background-color: #2ba7a7 !important;
}

.bg-teal.lighten-2 {
  background-color: #62d6d6 !important;
}

.bg-teal.darken-3 {
  background-color: #269393 !important;
}

.bg-teal.lighten-3 {
  background-color: #76dcdc !important;
}

.bg-teal.darken-4 {
  background-color: #217e7e !important;
}

.bg-teal.lighten-4 {
  background-color: #8ae1e1 !important;
}

.bg-purple {
  background-color: #948efb !important;
}

.text-purple {
  color: #948efb !important;
}

:not(.jstree) .text-purple i:before,
:not(.jstree) i.text-purple:before {
  background: linear-gradient(180deg, #948efb 0%, #372bf8 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-purple {
  border: 1px solid #948efb;
  border-color: #948efb !important;
}

.bg-purple.darken-1 {
  background-color: #7d75fa !important;
}

.bg-purple.lighten-1 {
  background-color: #aba7fc !important;
}

.bg-purple.darken-2 {
  background-color: #655df9 !important;
}

.bg-purple.lighten-2 {
  background-color: #c3bffd !important;
}

.bg-purple.darken-3 {
  background-color: #4e44f8 !important;
}

.bg-purple.lighten-3 {
  background-color: #dad8fe !important;
}

.bg-purple.darken-4 {
  background-color: #372bf8 !important;
}

.bg-purple.lighten-4 {
  background-color: #f1f1fe !important;
}

.bg-orange {
  background-color: #ff851b !important;
}

.text-orange {
  color: #ff851b !important;
}

:not(.jstree) .text-orange i:before,
:not(.jstree) i.text-orange:before {
  background: linear-gradient(180deg, #ff851b 0%, #b45400 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-orange {
  border: 1px solid #ff851b;
  border-color: #ff851b !important;
}

.bg-orange.darken-1 {
  background-color: #ff7702 !important;
}

.bg-orange.lighten-1 {
  background-color: #ff9335 !important;
}

.bg-orange.darken-2 {
  background-color: #e76b00 !important;
}

.bg-orange.lighten-2 {
  background-color: #ffa04e !important;
}

.bg-orange.darken-3 {
  background-color: #ce6000 !important;
}

.bg-orange.lighten-3 {
  background-color: #ffae68 !important;
}

.bg-orange.darken-4 {
  background-color: #b45400 !important;
}

.bg-orange.lighten-4 {
  background-color: #ffbc81 !important;
}

.bg-maroon {
  background-color: #D81B60 !important;
}

.text-maroon {
  color: #D81B60 !important;
}

:not(.jstree) .text-maroon i:before,
:not(.jstree) i.text-maroon:before {
  background: linear-gradient(180deg, #D81B60 0%, #7d1038 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-maroon {
  border: 1px solid #D81B60;
  border-color: #D81B60 !important;
}

.bg-maroon.darken-1 {
  background-color: #c11856 !important;
}

.bg-maroon.lighten-1 {
  background-color: #e4286d !important;
}

.bg-maroon.darken-2 {
  background-color: #ab154c !important;
}

.bg-maroon.lighten-2 {
  background-color: #e73f7c !important;
}

.bg-maroon.darken-3 {
  background-color: #941342 !important;
}

.bg-maroon.lighten-3 {
  background-color: #ea568c !important;
}

.bg-maroon.darken-4 {
  background-color: #7d1038 !important;
}

.bg-maroon.lighten-4 {
  background-color: #ed6c9b !important;
}

.bg-black {
  background-color: #111111 !important;
}

.text-black {
  color: #111111 !important;
}

:not(.jstree) .text-black i:before,
:not(.jstree) i.text-black:before {
  background: linear-gradient(180deg, #111111 0%, black 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-black {
  border: 1px solid #111111;
  border-color: #111111 !important;
}

.bg-black.darken-1 {
  background-color: #040404 !important;
}

.bg-black.lighten-1 {
  background-color: #1e1e1e !important;
}

.bg-black.darken-2 {
  background-color: black !important;
}

.bg-black.lighten-2 {
  background-color: #2b2b2b !important;
}

.bg-black.darken-3 {
  background-color: black !important;
}

.bg-black.lighten-3 {
  background-color: #373737 !important;
}

.bg-black.darken-4 {
  background-color: black !important;
}

.bg-black.lighten-4 {
  background-color: #444444 !important;
}

.bg-yellow {
  background-color: #f39c12 !important;
}

.text-yellow {
  color: #f39c12 !important;
}

:not(.jstree) .text-yellow i:before,
:not(.jstree) i.text-yellow:before {
  background: linear-gradient(180deg, #f39c12 0%, #976008 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-yellow {
  border: 1px solid #f39c12;
  border-color: #f39c12 !important;
}

.bg-yellow.darken-1 {
  background-color: #e08e0b !important;
}

.bg-yellow.lighten-1 {
  background-color: #f4a62a !important;
}

.bg-yellow.darken-2 {
  background-color: #c87f0a !important;
}

.bg-yellow.lighten-2 {
  background-color: #f5b043 !important;
}

.bg-yellow.darken-3 {
  background-color: #b06f09 !important;
}

.bg-yellow.lighten-3 {
  background-color: #f7ba5b !important;
}

.bg-yellow.darken-4 {
  background-color: #976008 !important;
}

.bg-yellow.lighten-4 {
  background-color: #f8c573 !important;
}

.bg-yellow-light {
  background-color: #ffff00 !important;
}

.text-yellow-light {
  color: #ffff00 !important;
}

:not(.jstree) .text-yellow-light i:before,
:not(.jstree) i.text-yellow-light:before {
  background: linear-gradient(180deg, #ffff00 0%, #999900 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
}

.border-yellow-light {
  border: 1px solid #ffff00;
  border-color: #ffff00 !important;
}

.bg-yellow-light.darken-1 {
  background-color: #e6e600 !important;
}

.bg-yellow-light.lighten-1 {
  background-color: #ffff1a !important;
}

.bg-yellow-light.darken-2 {
  background-color: #cccc00 !important;
}

.bg-yellow-light.lighten-2 {
  background-color: #ffff33 !important;
}

.bg-yellow-light.darken-3 {
  background-color: #b3b300 !important;
}

.bg-yellow-light.lighten-3 {
  background-color: #ffff4d !important;
}

.bg-yellow-light.darken-4 {
  background-color: #999900 !important;
}

.bg-yellow-light.lighten-4 {
  background-color: #ffff66 !important;
}

.bg-custom-bluegray-1 {
  background: #262933;
  color: #fff;
}

.bg-custom-bluegray-2 {
  background: #313541;
  color: #fff;
}

.bg-custom-bluegray-3 {
  background: #52596d;
  color: #fff;
}

.bg-none {
  background: transparent !important;
  color: 333333;
}

/* custom utility classes */
/* Attaching actions to non-clickable elements */
[data-ember-action] {
  cursor: pointer;
}

/* adminlte theme */
/*
 * Variables
 */
/*
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
*/
/*
 * Layout
 */
.content-wrapper {
  display: flex;
}
.content-wrapper .content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content-wrapper .content > .row {
  flex: 1;
}
.content-wrapper .content .content-left,
.content-wrapper .content .content-right {
  max-height: calc(100vh - 50px - 1rem);
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .content-wrapper .content .content-left,
  .content-wrapper .content .content-right {
    max-height: calc(100vh - 100px - 2rem);
  }
}
.content-wrapper .content .content-left .card-wrapper,
.content-wrapper .content .content-right .card-wrapper {
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
.content-wrapper .content .content-left .card-wrapper > .card,
.content-wrapper .content .content-left > .card,
.content-wrapper .content .content-right .card-wrapper > .card,
.content-wrapper .content .content-right > .card {
  flex-grow: 1;
  min-height: 0;
}
.content-wrapper .content .content-left .card-wrapper > .card.collapsed-card,
.content-wrapper .content .content-left > .card.collapsed-card,
.content-wrapper .content .content-right .card-wrapper > .card.collapsed-card,
.content-wrapper .content .content-right > .card.collapsed-card {
  flex-grow: 0;
}

.main-header {
  z-index: 1110;
}

.control-sidebar {
  z-index: 999;
}

#control-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 998;
}
.control-sidebar-slide-open #control-sidebar-overlay {
  display: block;
}

.flex-no-shrink {
  flex-shrink: 0 !important;
}

.flex-no-grow {
  flex-grow: 0 !important;
}

select.form-control:not([size]):not([multiple]) {
  /*
    height: calc(calc(2.25rem + 2px) - 3px);
  */
}

/*
@include media-breakpoint-up(md) {
  .card-body.scrollbar {
    height: calc(100vh - 11.1rem);
    min-height: 300px;
    .card-tabs & {
      height: calc(100vh - 11.1rem - 0.75rem);
      min-height: calc(300px;
    }
  }
}
*/
/*
  // to delete
.card {
  .card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card-title {
      flex-grow: 1;
    }
    > .card-tools {
      display: flex;
      align-items: center;
      gap: .75rem;
      margin: -.5rem 0;
      position: unset;
      top: unset;
      right: unset;
    }
  }
}
*/
.card .card-header {
  padding: 0 0.5rem !important;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  justify-items: end;
}
.card .card-header > * {
  margin: 0.5rem 0;
}
.card .card-header .card-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .card-header .card-tools {
  display: flex;
  align-items: center;
  /*
        column-gap: 0.75rem;
  */
  position: unset;
  top: unset;
  right: unset;
}
.card .card-header .card-tools > *:not(.badge):not(button) {
  padding: 0;
  margin: 0;
}
.card .card-header .tab-scroller {
  margin: 0;
}
.card .card-header .nav {
  align-items: center;
}

.card {
  border-radius: 0.5rem;
}
.card.card-tabs {
  background: transparent !important;
  box-shadow: none;
}
.card.card-tabs > .card-body {
  background: transparent !important;
}
.card.card-tabs > .card-header,
.card.card-tabs > .card-body,
.card.card-tabs > .card-footer {
  padding: 0;
}
.card.card-tabs > .card-header {
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0.5rem 0.5rem 0 0;
}
.card.card-tabs > .card-footer {
  margin-top: 1rem;
  margin-bottom: 0;
  margin-left: -1rem;
  margin-right: -1rem;
}
.card.card-tabs > .card-header,
.card.card-tabs > .card-footer {
  /*
  to delete
        .nav-link {
          padding-top: calc(0.5rem + 4px);
          padding-bottom: calc(0.5rem + 4px);
          &.active {
            padding-top: calc(0.5rem + 2px);
            padding-bottom: calc(0.5rem + 5px);
          }
        }
  */
}
.card.card-tabs > .card-footer .nav-tabs .nav-item {
  flex-grow: 1;
  text-align: center;
}
.card.card-tabs .card {
  /*
        .card-title {
          margin: .5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-grow: 1;
        }
  */
}
.card.card-tabs .card .card-header {
  border-radius: 0.5rem 0.5rem 0 0;
  /*
  // to delete
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          justify-content: space-between;
          > .card-tools {
            right: .5rem;
            top: .25rem;
          }
  */
}
.card.card-tabs .card .card-header .nav {
  flex-wrap: nowrap;
  /*
            overflow-x: scroll;
            overflow-y: hidden;
            margin-right: .5rem;
  */
}
.card.card-tabs .card .card-body {
  border-radius: 0.5rem 0.5rem 0 0;
}
.card.card-tabs .card .nav-tabs {
  margin-left: auto;
}

.card {
  /*
    // 3dview - but breaks other content
    .content > &:only-child {
      width: 100%;
      .card-body {
        display: flex;
      }
    }
  */
}
div:last-of-type:not(:only-of-type) > .card:only-child, .card:last-child:not(:only-child), .content > .card:only-child, .content-right > .card:last-child, .tab-pane > .card:last-child {
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .content-left > .card:last-child {
    margin-bottom: 0 !important;
  }
}

.container-3dview-wrap,
.container-3dview {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-bottom: 0 !important;
}

.container-3dview-wrap > .card {
  flex: 1 0 auto;
}
.container-3dview-wrap > .card > .card-body {
  display: flex;
  flex-direction: column;
}

.container-3dview {
  flex: 1 0 auto;
}

.container-3dview canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*
 * New overrides
 */
.dropdown-menu .nav-link {
  border: 0;
  padding: 0.125rem 1rem;
}

.nav-tabs .nav-link {
  border-radius: 0;
}

.nav-sidebar > .nav-item .nav-icon {
  vertical-align: top;
}

.nav-sidebar .nav-link > p > .right {
  right: 0;
  top: 0.5rem;
  font-size: 1.4rem;
}

.nav-item .nav-icon,
.btn-group-presets i,
.dashboard-header .nav-item:not(:first-of-type):not(:last-of-type) i,
.dashboard-footer .nav-item i {
  background: linear-gradient(45deg, #1a40b1, #28f3ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-title i {
  margin: 0 0.25rem;
}

/*
 * Old Overrides
 */
.main-header {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.main-header .nav-link {
  height: 2.5rem;
}
.main-header .nav-link i {
  font-size: 1rem;
  line-height: 1.5;
}

.fixed .content-wrapper {
  padding-top: 50px;
}

.brand-image {
  float: none;
  max-height: 40px;
  margin-top: 0;
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse .brand-image {
    width: calc(4rem - 0.5rem);
    margin: 9px 0.25rem;
  }
}

.user-panel .image {
  padding-left: 1rem;
}
.user-panel img {
  width: 2rem;
}

/*
 * Media Queries
 * ----------
 */
@media (max-width: 767.98px) {
  .main-header .logo {
    display: none;
  }
  .fixed .content-wrapper,
  .fixed .left-side,
  .fixed .right-side {
    padding-top: 50px;
  }
  .content-header > .breadcrumb {
    display: none;
  }
}
/*
 * Plugins
 * ----------
 */
.pace .pace-progress {
  background: #8aa4af;
}

.pace .pace-activity {
  border-top-color: #8aa4af;
  border-left-color: #8aa4af;
}

/*
 * Overwrites
 * ----------
 */
/*
 * Component: Card
 */
.card {
  /* border: 0px solid rgba(0, 0, 0, 0.125); */
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}

/*
 * Component: control-sidebar
 */
.control-sidebar {
  position: fixed;
  color: #aeb3c3;
}
.control-sidebar,
.control-sidebar + .control-sidebar-bg {
  background: #1b1d24;
}
.control-sidebar .nav-tabs.control-sidebar-tabs {
  border-bottom: #14161b;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li > a {
  background: #101115;
  color: #aeb3c3;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li > a, .control-sidebar .nav-tabs.control-sidebar-tabs > li > a:hover, .control-sidebar .nav-tabs.control-sidebar-tabs > li > a:focus {
  border-left-color: #0c0d10;
  border-bottom-color: #0c0d10;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li > a:hover, .control-sidebar .nav-tabs.control-sidebar-tabs > li > a:focus, .control-sidebar .nav-tabs.control-sidebar-tabs > li > a:active {
  background: #14161b;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li > a:hover {
  color: #fff;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li.active {
  border-top: 0;
}
.control-sidebar .nav-tabs.control-sidebar-tabs > li.active > a, .control-sidebar .nav-tabs.control-sidebar-tabs > li.active > a:hover, .control-sidebar .nav-tabs.control-sidebar-tabs > li.active > a:focus, .control-sidebar .nav-tabs.control-sidebar-tabs > li.active > a:active {
  background: #1b1d24;
  color: #fff;
}
.control-sidebar .tab-content {
  padding: 0.5rem 1rem;
}
.control-sidebar .control-sidebar-heading,
.control-sidebar .control-sidebar-subheading {
  color: #fff;
}
.control-sidebar .control-sidebar-menu > li > a:hover {
  background: #17181e;
}
.control-sidebar .control-sidebar-menu > li > a .menu-info > p {
  color: #aeb3c3;
}

/*
 * Themify
 */
/* Disable text selection and highlighting and callout for iOs */
body {
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
}

body.skin-sgb-night {
  background-color: #1f222d;
}

.skin-sgb .wrapper {
  background-color: #f3f3f3;
  color: #212529;
}
.skin-sgb-night .wrapper {
  background-color: #1f222d;
  color: #c1c6d5;
}

.skin-sgb .table {
  color: #212529;
}
.skin-sgb-night .table {
  color: #c1c6d5;
}

.skin-sgb .main-header.navbar {
  background-color: #e6e6e6 !important;
}
.skin-sgb .main-header.navbar .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.skin-sgb .main-header.navbar .nav-link:hover, .skin-sgb .main-header.navbar .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.skin-sgb-night .main-header.navbar {
  background-color: #262a37 !important;
}
.skin-sgb-night .main-header.navbar .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.skin-sgb-night .main-header.navbar .nav-link:hover, .skin-sgb-night .main-header.navbar .nav-link:focus {
  color: rgba(255, 255, 255, 0.8);
}

.skin-sgb .brand-link {
  background-color: #262a37;
}
.skin-sgb .brand-link:hover, .skin-sgb .brand-link:focus {
  background-color: #262a37;
}
.skin-sgb-night .brand-link {
  background-color: #262a37;
}
.skin-sgb-night .brand-link:hover, .skin-sgb-night .brand-link:focus {
  background-color: #262a37;
}

.skin-sgb .main-sidebar {
  background-color: #2a2f3e;
}
.skin-sgb .main-sidebar .user-panel a:hover {
  color: #fff;
}
.skin-sgb .main-sidebar .nav-sidebar .nav-item .nav-link {
  border-radius: 0;
  margin-bottom: 0;
}
.skin-sgb .main-sidebar .nav-sidebar .nav-item .nav-link:active, .skin-sgb .main-sidebar .nav-sidebar .nav-item .nav-link:focus {
  color: #b5bbcc;
}
.skin-sgb .main-sidebar .nav-sidebar .nav-item.menu-open > .nav-link, .skin-sgb .main-sidebar .nav-sidebar .nav-item:hover > .nav-link {
  color: #fff;
  background-color: #505974;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.skin-sgb .main-sidebar .nav-sidebar .nav-item > .nav-link.active {
  color: #b5bbcc;
  background-color: #3b4256;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.skin-sgb .main-sidebar .nav-sidebar .nav-item > .nav-treeview {
  background: #434b62;
}
.skin-sgb .main-sidebar .nav-header {
  color: #7984a3;
  background: #1b1e27;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
}
.skin-sgb .main-sidebar .sidebar a {
  color: #b5bbcc;
}
.skin-sgb .main-sidebar .sidebar a:hover {
  text-decoration: none;
}
.skin-sgb .main-sidebar .nav-treeview > .nav-item > .nav-link:hover {
  color: #fff;
  background-color: #505974;
}
.skin-sgb .main-sidebar .nav-treeview > .nav-item > .nav-link.active, .skin-sgb .main-sidebar .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #b5bbcc;
  background-color: #313647;
}
.skin-sgb-night .main-sidebar {
  background-color: #2a2f3e;
}
.skin-sgb-night .main-sidebar .user-panel a:hover {
  color: #fff;
}
.skin-sgb-night .main-sidebar .nav-sidebar .nav-item .nav-link {
  border-radius: 0;
  margin-bottom: 0;
}
.skin-sgb-night .main-sidebar .nav-sidebar .nav-item .nav-link:active, .skin-sgb-night .main-sidebar .nav-sidebar .nav-item .nav-link:focus {
  color: #b5bbcc;
}
.skin-sgb-night .main-sidebar .nav-sidebar .nav-item.menu-open > .nav-link, .skin-sgb-night .main-sidebar .nav-sidebar .nav-item:hover > .nav-link {
  color: #fff;
  background-color: #505974;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.skin-sgb-night .main-sidebar .nav-sidebar .nav-item > .nav-link.active {
  color: #b5bbcc;
  background-color: #3b4256;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.skin-sgb-night .main-sidebar .nav-sidebar .nav-item > .nav-treeview {
  background: #434b62;
}
.skin-sgb-night .main-sidebar .nav-header {
  color: #7984a3;
  background: #1b1e27;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
}
.skin-sgb-night .main-sidebar .sidebar a {
  color: #b5bbcc;
}
.skin-sgb-night .main-sidebar .sidebar a:hover {
  text-decoration: none;
}
.skin-sgb-night .main-sidebar .nav-treeview > .nav-item > .nav-link:hover {
  color: #fff;
  background-color: #505974;
}
.skin-sgb-night .main-sidebar .nav-treeview > .nav-item > .nav-link.active, .skin-sgb-night .main-sidebar .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #b5bbcc;
  background-color: #313647;
}

.skin-sgb-night .main-sidebar {
  background: transparent;
  box-shadow: none !important;
}
.skin-sgb-night .main-sidebar .sidebar {
  background: #2a2f3e;
  margin-top: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.skin-sgb .content-wrapper {
  background-color: #f3f3f3;
}
.skin-sgb-night .content-wrapper {
  background-color: #1f222d;
}

.skin-sgb .main-footer {
  color: #555;
  border-top: 1px solid #dee2e6;
  background: #ffffff;
}
.skin-sgb-night .main-footer {
  color: #c1c6d5;
  border-top: 1px solid #33394a;
  background: #2a2f3e;
}

.main-footer {
  margin-left: 0 !important;
}

.sidebar-mini.sidebar-collapse .main-footer {
  margin-left: 0 !important;
}

.sidebar {
  height: calc(100% - 8.25rem);
}

.skin-sgb .card {
  background-color: transparent;
}
.skin-sgb-night .card {
  background-color: #262a37;
}
.skin-sgb .card > .card-header {
  background-color: white;
}
.skin-sgb-night .card > .card-header {
  background-color: #262a37;
}
.skin-sgb .card > .card-body {
  background-color: #ffffff;
}
.skin-sgb-night .card > .card-body {
  background-color: #303546;
}
.skin-sgb .card > .card-footer {
  background-color: white;
}
.skin-sgb-night .card > .card-footer {
  background-color: #262a37;
}

.skin-sgb .card.card-tabs > .card-header {
  background-color: white;
}
.skin-sgb-night .card.card-tabs > .card-header {
  background-color: #262a37;
}
.card.card-tabs > .card-header:after {
  display: block;
  position: absolute;
  height: 5px;
  bottom: -5px;
  left: 0;
  right: 0;
}
.skin-sgb .card.card-tabs > .card-header:after {
  background-color: white;
}
.skin-sgb-night .card.card-tabs > .card-header:after {
  background-color: #262a37;
}
.skin-sgb .card.card-tabs > .card-body {
  background-color: #ffffff;
}
.skin-sgb-night .card.card-tabs > .card-body {
  background-color: #303546;
}
.skin-sgb .card.card-tabs > .card-footer {
  background-color: white;
}
.skin-sgb-night .card.card-tabs > .card-footer {
  background-color: #262a37;
}

.card-section-title {
  display: inline-block;
  margin: 1rem 0;
  padding: 0 0.5rem;
  min-width: 6rem;
  background: #f8ba00;
  background: linear-gradient(125deg, #f8ba00 0%, #f18f00 100%);
  border-radius: 0.375rem;
}
.skin-sgb .card-section-title {
  color: #fff;
}
.skin-sgb-night .card-section-title {
  color: #2a2f3e;
}

.card.subcard .card-header {
  border-radius: 0.375rem !important;
  max-width: 8rem;
  background: #f8ba00;
  background: linear-gradient(125deg, #f8ba00 0%, #f18f00 100%);
  position: relative;
  top: -0.625rem;
}
.card.subcard .card-title {
  font-size: 0.875rem;
  margin: 0.125rem 0.5rem !important;
}
.skin-sgb .card.subcard .card-title {
  color: #fff;
}
.skin-sgb-night .card.subcard .card-title {
  color: #2a2f3e;
}

.btn-group-presets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  grid-gap: 0.5rem;
}
.btn-group-presets .btn-wrap {
  /* overflow: hidden; */
}
.btn-group-presets .btn {
  width: 100%;
  border-radius: 0.5rem !important;
  padding: 1rem 0;
}
.btn-group-presets .btn i {
  font-size: 1.5rem;
}
.btn-group-presets .btn.btn-warning .title {
  background: #f8ba00;
  background: linear-gradient(125deg, #f8ba00 0%, #f18f00 100%);
  color: #1F2D3D;
}
.btn-group-presets .title {
  border-radius: 0.25rem;
  margin: -0.5rem 0.5rem 0 0.5rem;
  padding: 0 0.25rem;
  font-size: 0.875em;
  /*
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  */
  position: relative;
  text-align: center;
  /*
      @include themify($themes) {
        background-color: themed('sgb-residental-title-bg');
      }
  */
  background-color: #576180;
}

.btn-cust-dark,
.btn-cust-dark-nav {
  color: #c1c6d5;
}

.card-dashboard .sgb-chart {
  border-radius: 0.5rem 0.5rem 0 0;
  margin-top: 1rem;
  /* padding: 0 .325rem .325rem .325rem; */
}
.skin-sgb .card-dashboard .sgb-chart {
  background-color: transparent;
}
.skin-sgb-night .card-dashboard .sgb-chart {
  background-color: #222632;
}
.card-dashboard .sgb-chart h4 {
  display: inline-block;
  position: relative;
  top: -0.5rem;
  left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 0.125rem 0.5rem;
}
.skin-sgb .card-dashboard .sgb-chart h4 {
  background-color: transparent;
}
.skin-sgb-night .card-dashboard .sgb-chart h4 {
  background-color: #606c8c;
}

.skin-sgb .table th,
.skin-sgb .table td {
  border-color: #dee2e6;
}
.skin-sgb-night .table th,
.skin-sgb-night .table td {
  border-color: #454e65;
}

.skin-sgb .table-bordered {
  border-color: #dee2e6;
}
.skin-sgb-night .table-bordered {
  border-color: #454e65;
}
.skin-sgb .table-bordered th,
.skin-sgb .table-bordered td {
  border-color: #dee2e6;
}
.skin-sgb-night .table-bordered th,
.skin-sgb-night .table-bordered td {
  border-color: #454e65;
}

.skin-sgb .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.skin-sgb-night .table-striped tbody tr:nth-of-type(odd) {
  background-color: #313647;
}

@media (max-width: 767.98px) {
  table th,
  table td {
    min-width: calc(25vw - 12px);
  }
}
.skin-sgb .nav-tabs .nav-link {
  color: #212529;
}
.skin-sgb-night .nav-tabs .nav-link {
  color: #c1c6d5;
}
.skin-sgb .nav-tabs .nav-link:hover {
  color: #5a6570;
}
.skin-sgb-night .nav-tabs .nav-link:hover {
  color: #a9b0c4;
}
.nav-tabs .nav-link.active {
  margin-bottom: -1px;
  padding-top: calc(0.5rem - 2px);
  padding-bottom: calc(0.5rem + 2px);
}
.skin-sgb .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #ffffff;
  border-top: 2px solid #f39c12;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid #ffffff;
}
.skin-sgb-night .nav-tabs .nav-link.active {
  color: #cdd1dd;
  background-color: #303546;
  border-top: 2px solid #f39c12;
  border-left: 1px solid #313647;
  border-right: 1px solid #313647;
  border-bottom: 1px solid #303546;
}
.skin-sgb .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
}
.skin-sgb-night .nav-tabs .nav-item.show .nav-link {
  color: #cdd1dd;
  background-color: transparent;
}

.skin-sgb .card.card-tabs > .card-header .nav-link.active,
.skin-sgb .card.card-tabs > .card-footer .nav-link.active {
  background-color: #f3f3f3;
  /*
              border-bottom: 1px solid themed('main-bg');
  */
}
.skin-sgb-night .card.card-tabs > .card-header .nav-link.active,
.skin-sgb-night .card.card-tabs > .card-footer .nav-link.active {
  background-color: #1f222d;
  /*
              border-bottom: 1px solid themed('main-bg');
  */
}

.skin-sgb-night .content-left .card.card-tabs > .card-header .nav-link.active {
  background-color: #303546;
}
.skin-sgb-night .content-right .card.card-tabs > .card-header .nav-link.active {
  background-color: #434b62;
}

.skin-sgb .dropdown-menu .nav-link {
  color: #212529;
}
.skin-sgb-night .dropdown-menu .nav-link {
  color: #c1c6d5;
}
.skin-sgb .dropdown-menu .nav-link:hover {
  color: #5a6570;
}
.skin-sgb-night .dropdown-menu .nav-link:hover {
  color: #a9b0c4;
}

/*
.dropdown-menu {
  .nav-link {
    @include themify($themes) {
      color: $dropdown-link-color !important;
      background-color: transparent !important;
    }
    @include hover {
      @include themify($themes) {
        color: $dropdown-link-hover-color !important;
        background-color: $dropdown-link-hover-bg !important;
      }
    }
  }
}
*/
.skin-sgb .form-control-plaintext {
  color: #212529;
}
.skin-sgb-night .form-control-plaintext {
  color: #c1c6d5;
}

.skin-sgb .models-table-wrapper .table-footer {
  border-color: #dee2e6;
}
.skin-sgb-night .models-table-wrapper .table-footer {
  border-color: #454e65;
}

.accordion .card .card-header {
  /*
        padding: .5rem !important;
  */
}
.accordion .card .card-header h5 {
  font-size: initial;
}
.accordion .card .card-header a {
  color: inherit;
}

.user-menu .user-header {
  height: 150px;
  padding: 0.75rem;
  text-align: center;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.skin-sgb .user-menu .user-header {
  background-color: #3c8dbc;
  color: rgba(255, 255, 255, 0.8);
}
.skin-sgb-night .user-menu .user-header {
  background-color: #657092;
  color: rgba(255, 255, 255, 0.8);
}
.user-menu .user-header img {
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.user-menu .user-header p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.x-toggle-component {
  justify-content: left;
}
.x-toggle-component label.on-label,
.x-toggle-component label.off-label {
  font-weight: normal !important;
  padding: 0;
  position: absolute;
  top: 0.5rem;
  z-index: 1;
}
.x-toggle-component label.on-label {
  left: 1.2rem;
}
.skin-sgb .x-toggle-component label.on-label {
  color: #fff;
}
.skin-sgb-night .x-toggle-component label.on-label {
  color: #fff;
}
.x-toggle-component label.off-label {
  left: 3.5rem;
}
.skin-sgb .x-toggle-component label.off-label {
  color: #212529;
}
.skin-sgb-night .x-toggle-component label.off-label {
  color: #3b4256;
}
.x-toggle-component.small label.on-label,
.x-toggle-component.small label.off-label {
  top: 0.35rem;
}

.x-toggle-container {
  padding: 0;
  margin: 0;
}
.x-toggle-container.medium {
  width: 4.375rem;
  height: 2.3rem;
}
.x-toggle-container.small {
  width: 3.5rem;
  height: 1.75rem;
}

label > .x-toggle-btn::after,
label > .x-toggle-btn::before {
  z-index: 2;
}

label > .x-toggle-default.medium {
  /*
    width: 5rem;
    height: 2.2rem;
  */
  width: 4.375rem;
  height: 2.3rem;
}

label > .x-toggle-default.small {
  width: 3.5rem;
  height: 1.75rem;
}

.skin-sgb .x-toggle:checked + label > .x-toggle-default.x-toggle-btn {
  background-color: #337ab7;
}
.skin-sgb-night .x-toggle:checked + label > .x-toggle-default.x-toggle-btn {
  background-color: #5a6583;
}

.x-toggle-onoff {
  display: inline-flex;
  vertical-align: middle;
}
.x-toggle-onoff label.on-label {
  color: #fff !important;
}
.x-toggle-onoff label.off-label {
  color: #fff !important;
  left: 2.8rem;
}
.x-toggle-onoff .x-toggle + label > .x-toggle-default.x-toggle-btn {
  background-color: #c82333;
}
.x-toggle-onoff .x-toggle:checked + label > .x-toggle-default.x-toggle-btn {
  background-color: #218838;
}

.skin-sgb .bootstrap-datetimepicker-widget {
  color: #212529;
  background-color: #ffffff;
}
.skin-sgb-night .bootstrap-datetimepicker-widget {
  color: #c1c6d5;
  background-color: #5a6583;
}
.skin-sgb .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  color: #e9ecef;
}
.skin-sgb-night .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  color: #6c757d;
}
.skin-sgb .bootstrap-datetimepicker-widget table td.day:hover, .skin-sgb .bootstrap-datetimepicker-widget table td.hour:hover, .skin-sgb .bootstrap-datetimepicker-widget table td.minute:hover, .skin-sgb .bootstrap-datetimepicker-widget table td.second:hover,
.skin-sgb .bootstrap-datetimepicker-widget table td span:hover {
  color: #e9ecef;
}
.skin-sgb-night .bootstrap-datetimepicker-widget table td.day:hover, .skin-sgb-night .bootstrap-datetimepicker-widget table td.hour:hover, .skin-sgb-night .bootstrap-datetimepicker-widget table td.minute:hover, .skin-sgb-night .bootstrap-datetimepicker-widget table td.second:hover,
.skin-sgb-night .bootstrap-datetimepicker-widget table td span:hover {
  color: #6c757d;
}
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td {
  border: 0;
}
.skin-sgb .bootstrap-datetimepicker-widget table th {
  color: #212529;
}
.skin-sgb-night .bootstrap-datetimepicker-widget table th {
  color: #c1c6d5;
}
.skin-sgb .bootstrap-datetimepicker-widget table td.day {
  color: #212529;
}
.skin-sgb-night .bootstrap-datetimepicker-widget table td.day {
  color: #c1c6d5;
}
.skin-sgb .bootstrap-datetimepicker-widget table td.new {
  color: black;
}
.skin-sgb-night .bootstrap-datetimepicker-widget table td.new {
  color: #7681a1;
}
.bootstrap-datetimepicker-widget table td.active {
  background-color: #f39c12;
}
.bootstrap-datetimepicker-widget table td.active:hover, .bootstrap-datetimepicker-widget table td.active:focus {
  background-color: #f39c12;
}
.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #f39c12;
}
.skin-sgb .bootstrap-datetimepicker-widget .timepicker-hour,
.skin-sgb .bootstrap-datetimepicker-widget .timepicker-minute,
.skin-sgb .bootstrap-datetimepicker-widget .timepicker-second {
  color: #212529;
}
.skin-sgb-night .bootstrap-datetimepicker-widget .timepicker-hour,
.skin-sgb-night .bootstrap-datetimepicker-widget .timepicker-minute,
.skin-sgb-night .bootstrap-datetimepicker-widget .timepicker-second {
  color: #c1c6d5;
}

.skin-sgb .jstree-proton .jstree-wholerow-hovered {
  background: #adb5bd;
  background-image: none;
}
.skin-sgb-night .jstree-proton .jstree-wholerow-hovered {
  background: #a9b0c4;
  background-image: none;
}
.skin-sgb .jstree-proton .jstree-wholerow-clicked {
  background-color: #6c757d;
  background-image: none;
}
.skin-sgb-night .jstree-proton .jstree-wholerow-clicked {
  background-color: #919ab3;
  background-image: none;
}

.skin-sgb .dropdown-menu {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.15);
}
.skin-sgb-night .dropdown-menu {
  background-color: #5a6583;
  border-color: #454e65;
}

.dropdown-scrollable .dropdown-menu {
  overflow-y: scroll;
  max-height: 75vh;
}

.skin-sgb .dropdown-divider {
  border-top-color: #e9ecef;
}
.skin-sgb-night .dropdown-divider {
  border-top-color: #454e65;
}

.skin-sgb .dropdown-item {
  color: #212529;
}
.skin-sgb .dropdown-item:hover, .skin-sgb .dropdown-item:focus {
  color: #16181b;
  background-color: #f8f9fa;
}
.skin-sgb .dropdown-item.active, .skin-sgb .dropdown-item:active {
  background-color: #007bff;
}
.skin-sgb .dropdown-item.disabled, .skin-sgb .dropdown-item:disabled {
  color: #6c757d;
}
.skin-sgb-night .dropdown-item {
  color: #c1c6d5;
}
.skin-sgb-night .dropdown-item:hover, .skin-sgb-night .dropdown-item:focus {
  color: #16181b;
  background-color: #c1c6d5;
}
.skin-sgb-night .dropdown-item.active, .skin-sgb-night .dropdown-item:active {
  background-color: #e67819;
}
.skin-sgb-night .dropdown-item.disabled, .skin-sgb-night .dropdown-item:disabled {
  color: #9da5bc;
}

.skin-sgb .dropdown-header {
  color: #6c757d;
}
.skin-sgb-night .dropdown-header {
  color: #a9b0c4;
}

.skin-sgb .dropdown-item-text {
  color: #212529;
}
.skin-sgb-night .dropdown-item-text {
  color: #c1c6d5;
}

.skin-sgb .btn-default {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #ddd;
}
.skin-sgb .btn-default:hover, .skin-sgb .btn-default:focus, .skin-sgb .btn-default:active {
  background-color: #e7e7e7;
}
.skin-sgb-night .btn-default {
  color: #d9dde5;
  background-color: #5a6583;
  border-color: #505974;
}
.skin-sgb-night .btn-default:hover, .skin-sgb-night .btn-default:focus, .skin-sgb-night .btn-default:active {
  background-color: #505974;
}

.skin-sgb .btn-default.disabled:hover, .skin-sgb .btn-default.disabled:focus, .skin-sgb .btn-default.disabled:active {
  background-color: #f4f4f4;
  cursor: default !important;
}
.skin-sgb-night .btn-default.disabled:hover, .skin-sgb-night .btn-default.disabled:focus, .skin-sgb-night .btn-default.disabled:active {
  background-color: #5a6583;
  cursor: default !important;
}

.skin-sgb .form-control {
  color: #495057;
  background-color: #ffffff;
  border-color: #ced4da;
}
.skin-sgb .form-control::placeholder {
  color: #6c757d;
}
.skin-sgb-night .form-control {
  color: #d9dde5;
  background-color: #5a6583;
  border-color: #3b4256;
}
.skin-sgb-night .form-control::placeholder {
  color: #b5bbcc;
}

.skin-sgb .input-group-text {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ced4da;
}
.skin-sgb-night .input-group-text {
  color: #c1c6d5;
  background-color: #5a6583;
  border-color: #3b4256;
}

.skin-sgb .hr {
  border-top-color: rgba(0, 0, 0, 0.1);
}
.skin-sgb-night .hr {
  border-top-color: #505974;
}

.skin-sgb .text-muted {
  color: #6c757d !important;
}
.skin-sgb-night .text-muted {
  color: #919ab3 !important;
}

.skin-sgb .object-status-tiles-residental .grid-item {
  /*
        .title {
          background-color: themed('sgb-residental-title-bg');
        }
  */
}
.skin-sgb .object-status-tiles-residental .grid-item .tile {
  border-color: #e4e4e4;
  background-color: transparent;
  background: radial-gradient(circle, rgb(59, 66, 86) 0%, rgb(70, 77, 103) 100%);
}
.skin-sgb .object-status-tiles-residental .grid-item .tile.hover {
  border-color: #e4e4e4;
  background-color: #f4f4f4;
  background: #f4f4f4;
}
.skin-sgb-night .object-status-tiles-residental .grid-item {
  /*
        .title {
          background-color: themed('sgb-residental-title-bg');
        }
  */
}
.skin-sgb-night .object-status-tiles-residental .grid-item .tile {
  border-color: #434b62;
  background-color: #434b62;
  background: radial-gradient(circle, rgb(59, 66, 86) 0%, rgb(70, 77, 103) 100%);
}
.skin-sgb-night .object-status-tiles-residental .grid-item .tile.hover {
  border-color: #4c546e;
  background-color: #4c546e;
  background: #4c546e;
}

.skin-sgb .floorplan .object-status-tiles-residental .grid-item .tile {
  border-color: #e4e4e4;
  background-color: rgba(255, 255, 255, 0.75);
}
.skin-sgb .floorplan .object-status-tiles-residental .grid-item .tile.hover {
  border-color: #e4e4e4;
  background-color: rgba(244, 244, 244, 0.75);
}
.skin-sgb-night .floorplan .object-status-tiles-residental .grid-item .tile {
  border-color: #434b62;
  background-color: rgba(67, 75, 98, 0.6039215686);
}
.skin-sgb-night .floorplan .object-status-tiles-residental .grid-item .tile.hover {
  border-color: #434b62;
  background-color: rgba(67, 75, 98, 0.8392156863);
}

.card .accordion-item .btn {
  text-align: left;
}
.skin-sgb .card .accordion-item {
  background-color: transparent;
  border-color: #dee2e6;
}
.skin-sgb .card .accordion-item .card-header {
  background-color: rgba(59, 66, 86, 0.1);
}
.skin-sgb-night .card .accordion-item {
  background-color: rgba(0, 0, 0, 0.075);
  border-color: transparent;
}
.skin-sgb-night .card .accordion-item .card-header {
  background-color: rgba(0, 0, 0, 0.075);
}

/*
.card-header {
  @include themify($themes) {
    background-color: themed('card-header-bg');
  }
}
*/
.CodeMirror {
  height: auto;
}

.CodeMirror-scroll {
  max-height: 250px;
}

.skin-sgb .CodeMirror {
  color: #495057;
  background-color: #ffffff;
}
.skin-sgb-night .CodeMirror {
  color: #212529;
  background-color: #bec3d2;
}

.skin-sgb .CodeMirror-gutters {
  background: #f7f7f7;
}
.skin-sgb-night .CodeMirror-gutters {
  background: #a0a7bd;
}

.skin-sgb .CodeMirror-linenumber {
  color: #495057;
}
.skin-sgb-night .CodeMirror-linenumber {
  color: #d9dde5;
}

.google-visualization-tooltip {
  width: auto !important;
  height: auto !important;
}

.skin-sgb-night .google-chart svg g:first-of-type rect:last-of-type {
  fill: none !important;
}
.skin-sgb-night .google-chart svg g:first-of-type rect:not(:last-of-type) {
  fill: none;
}
.skin-sgb-night .google-chart svg g:first-of-type rect:nth-of-type(odd) {
  fill: #313541;
}
.skin-sgb-night .google-chart svg g:first-of-type path {
  stroke: #474d5f;
}
.skin-sgb-night .google-chart svg g:first-of-type text {
  fill: #cbcdd1;
}

.skin-sgb .google-chart svg g:not(:first-of-type) text {
  fill: #212529;
}
.skin-sgb-night .google-chart svg g:not(:first-of-type) text {
  fill: #c1c6d5;
}

.skin-sgb-night .card-header {
  border-bottom: 0;
}
.skin-sgb-night .table th,
.skin-sgb-night .table td {
  border-color: transparent;
}
.skin-sgb-night .dropdown-menu {
  border-color: transparent;
}
.skin-sgb-night .nav-tabs .nav-link.active {
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.skin-sgb-night .form-control {
  border-color: transparent;
}
.skin-sgb-night .btn-default {
  border-color: transparent;
}

.card-footer .dropup .dropdown-toggle::after {
  display: none;
}

/* pwa modal */
.modal-pwa {
  text-align: center;
  top: 20%;
}
.modal-pwa .modal-body {
  text-align: center;
}
.modal-pwa .modal-footer {
  display: block;
  text-align: center;
}

/* simple modal */
.modal {
  top: 50px;
  bottom: 50px;
  height: calc(100% - 100px);
}
.modal .iro__colorPicker {
  margin-left: auto;
  margin-right: auto;
}
.modal svg {
  margin-left: auto;
  margin-right: auto;
}

.modal-header,
.modal-footer {
  border-color: rgba(0, 0, 0, 0.2);
}

.skin-sgb .modal-content {
  background-color: transparent;
  color: #212529;
}
.skin-sgb-night .modal-content {
  background-color: #434b62;
  color: #c1c6d5;
}
.skin-sgb .modal-content .close {
  color: #212529;
}
.skin-sgb-night .modal-content .close {
  color: #c1c6d5;
}

/* ember-bootstrap modal */
#ember-bootstrap-wormhole .modal {
  z-index: 1110;
}

/* small screen UI */
@media (max-width: 767.98px) {
  .sidebar {
    height: calc(100% - 9.25rem);
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    overflow: auto;
  }
  .content-right {
    padding: 0;
  }
  body.skin-sgb-night {
    background: #303546;
  }
  .skin-sgb-night .main-header.navbar {
    background-color: #2a2f3e !important;
  }
  .skin-sgb-night .content-wrapper {
    background: #303546;
    padding-top: 34px;
  }
  .content {
    overflow-y: auto;
  }
  .content > .row {
    margin: 0;
  }
  .content-wrapper .content .content-left,
  .content-wrapper .content .content-right {
    max-height: none;
  }
  .main-footer {
    height: 3rem;
    position: fixed;
    bottom: 1.5rem;
    left: 7.5px;
    right: 7.5px;
    padding: 0;
    border: none !important;
    border-radius: 0.75rem;
    z-index: 1200;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  .main-footer .sgb-home-helper.top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5019607843);
    border-radius: 1rem 1rem 0 0;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .main-footer .sgb-home-helper.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5019607843);
    border-radius: 0 0 1rem 1rem;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .main-footer .nav-tabs {
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .main-footer .nav-tabs .nav-item {
    flex: 1;
    text-align: center;
    flex-grow: 0;
    flex-basis: 25%;
  }
  .main-footer .nav-tabs .nav-item:nth-child(2) {
    position: relative;
    top: -0.5rem;
    overflow: visible;
    border-radius: 1rem;
    flex-grow: 0;
    padding: 0;
    width: 4rem;
    height: 4rem;
    flex-basis: 4rem;
  }
  .main-footer .nav-tabs .nav-item:nth-child(2) .nav-link {
    padding: 0;
  }
  .main-footer .nav-tabs .nav-link {
    padding: 0.25rem 1rem;
  }
  .main-footer .nav-tabs .nav-link.active {
    border-top: none;
    margin: 0;
    padding: 0.25rem 1rem;
    background: transparent !important;
  }
  .main-footer .dropdown-toggle::after {
    display: none !important;
  }
  .main-footer .sgb-home {
    display: inline-block;
    width: 4rem;
    height: 4rem;
  }
  .main-footer .sgb-home img {
    padding: 0.25rem 0 0.25rem 0.5rem;
    width: 4rem;
    height: 4rem;
  }
  .main-footer .nav-tabs .nav-link .far {
    font-size: 1.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
  }
  .main-footer .dropdown-menu {
    right: 0;
    left: auto;
  }
}
/*
@media only screen  and (max-device-width: 992px) {
  .content-wrapper {
    .content {
      .content-left.hide-on-screen {
        display: none !important;
        & + .content-right {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}
*/
/* Add to HomeScreen */
.a2hs-button {
  position: absolute;
  top: 0.5rem;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2000;
  margin: 0 auto;
  max-width: 10rem;
}

/* notify alert bg */
.skin-sgb-night .bg-info,
.skin-sgb-night .alert-info,
.skin-sgb-night .label-info {
  background-color: #5a6583 !important;
}

@media screen and (min-width: 320px) and (max-width: 480px), screen and (min-height: 320px) and (max-height: 480px) and (orientation: landscape) {
  .ember-notify-default {
    display: none;
  }
}
/* back button */
.main-header .back-button .nav-link,
.main-header .menu-button .nav-link {
  padding-right: 0.5rem;
}
.main-header .back-button .far,
.main-header .menu-button .far {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: bold;
}
.main-header .menu-button .closed {
  display: none;
}
.sidebar-collapse .main-header .menu-button .opened {
  display: none;
}
.sidebar-collapse .main-header .menu-button .closed {
  display: block;
}

/* dimmer indicator */
.dimmer-indicator-wrap {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 20%;
  bottom: 12px;
  width: 60%;
  height: 4px;
  margin: 0 auto;
}
.dimmer-indicator-wrap .dimmer-indicator {
  background: #f8ba00;
  height: 4px;
}

.floorplan .dimmer-indicator-wrap {
  bottom: 2px;
  height: 2px;
}
.floorplan .dimmer-indicator-wrap .dimmer-indicator {
  height: 2px;
}

.transition-height {
  transition: height 0.25s ease-in-out;
}

.transition-opacity {
  transition: opacity 0.25s ease-in-out;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

#pane-presets .accordion-card-tools {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
}
#pane-presets .device-actions-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#pane-presets .device-actions-btngrp {
  max-width: 3.5rem;
  margin: 0 auto 1rem auto;
}
#pane-presets .liquid-container {
  overflow: visible;
  z-index: 10;
}
#pane-presets .liquid-child {
  overflow: visible;
}

.presetItem-actions-col .x-toggle-component {
  justify-content: flex-end;
}

#pane-presets .card-wrap > .card-header {
  padding: 0 !important;
}
#pane-presets .card-wrap > .card-body .card-header {
  padding: 0 !important;
}
.close {
  line-height: inherit;
}

.hvac-display {
  width: 75%;
  background: #262933;
  padding: 0.5rem;
  margin: 0 auto;
}
@media (max-width: 575.98px) {
  .hvac-display {
    width: 100%;
  }
}
.hvac-display .btn {
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.hvac-display .display-button {
  background: #52596d;
  text-align: center;
  text-transform: uppercase;
}
.hvac-display .display-button.off {
  background: #f11514;
}
.hvac-display .display-button.heat {
  background: #f11514;
}
.hvac-display .display-button.cool {
  background: #3c8dbc;
}
.hvac-display .display-button.fan {
  background: #22c323;
}
.hvac-display .temp-control-button {
  min-height: 2rem;
  font-size: 2.5rem;
  line-height: 4.5rem;
  text-align: center;
}
.hvac-display .temp-control-button.heat {
  background: #f11514;
}
.hvac-display .temp-control-button.cool {
  background: #3c8dbc;
}
.hvac-display .display-temp {
  /*
      line-height: 2rem;
      text-align: center;
  */
}
.hvac-display .display-temp.now {
  padding: 1.75rem 0 1rem 0.5rem;
}
.hvac-display .display-temp.now .label,
.hvac-display .display-temp.now .value {
  display: block;
}
.hvac-display .display-temp.set {
  padding-left: 8%;
}
.hvac-display .display-temp.set .label {
  display: block;
  line-height: 1;
  padding-left: 1.6rem;
}
.hvac-display .display-temp.set .value {
  font-size: 5rem;
  line-height: 1;
}
.hvac-display .display-temp.set .unit {
  vertical-align: top;
}

.wallplate-display {
  position: relative;
  min-height: 5rem;
  border-radius: 0.375rem;
}
.wallplate-display .wallplate-display-icon {
  min-height: 10rem;
  font-size: 4rem;
  line-height: 10rem;
  text-align: center;
}
.wallplate-display .wallplate-bell-icon {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
}
.wallplate-display .wallplate-bell-icon .btn {
  min-height: 3rem;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 0.5rem;
}

#pane-settings .card .card {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

#pane-wallplate-settings .grid-container .grid-item {
  display: flex;
  align-items: center;
}
#pane-wallplate-settings .grid-container .grid-item > i {
  margin: 0 0.5rem;
}

.skin-sgb-night .btn-outline-primary {
  color: #cbcdd1;
  border-color: #3b4256;
}

.sticky {
  position: sticky !important;
  top: 0px;
  z-index: 5;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  body,
  .wrapper,
  .content-wrapper,
  .content,
  .content .scrollbar {
    overflow: visible;
  }
  .content-right .tab-content-main > #pane-residental > .card:first-of-type > .card-header {
    display: none;
  }
  /*
    .sticky {
      top: 50px;
    }
  */
  /*
  to delete
    .card {
      &.card-tabs{
        .card {
          .card-header {
            .nav {
              flex-basis: 100%;
            }
          }
          .card-title {
            flex-basis: 100%;
            margin: 0.5rem;
            text-align: center;
            flex-grow: 1;
          }
        }
      }
    }
  */
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.625rem !important;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%23495057'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 0.625rem;
  background-position: calc(100% - 0.5rem) center;
  background-repeat: no-repeat;
}

.skin-sgb-night select {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%23d9dde5'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 0.625rem;
  background-position: calc(100% - 0.5rem) center;
  background-repeat: no-repeat;
}

label {
  font-weight: 400 !important;
}

.form-group > label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@supports (appearance: none) or (-moz-appearance: none) or (-webkit-appearance: none) {
  input[type=checkbox] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3333333333) 100%);
    border: 1px solid #c1c6d5;
    border-radius: 40px;
    box-sizing: content-box;
    color: currentColor;
    height: 0.75rem;
    padding: 2px 2px 2px 2px;
    transition-duration: 300ms;
    transition-property: border-color, color;
    /* Safari needed border-color. */
    transition-timing-function: ease;
    width: 40px;
    min-width: 40px;
  }
  input[type=checkbox]:focus {
    outline: 0;
  }
  input[type=checkbox]:checked {
    border-color: #22c323;
  }
  input[type=checkbox]::after {
    background-color: #c1c6d5;
    border-radius: 12px;
    color: currentColor;
    content: "";
    display: block;
    height: 0.75rem;
    transform: translateX(0px);
    transition: transform 300ms ease;
    width: 0.75rem;
  }
  input[type=checkbox]:checked::after {
    transform: translateX(28px);
  }
  input[type=checkbox].small {
    border-radius: 32px;
    height: 0.625rem;
    width: 32px;
    min-width: 32px;
  }
  input[type=checkbox].small::after {
    border-radius: 10px;
    height: 0.625rem;
    width: 0.625rem;
  }
  input[type=checkbox].small:checked::after {
    transform: translateX(22px);
  }
}
.card-header .nav-tabs {
  flex-wrap: nowrap;
}

/* iOs notch devices */
@media only screen and (orientation: portrait) and (max-width: 767.98px) {
  .wrapper,
  .main-sidebar {
    padding-top: max(env(safe-area-inset-top), 0px);
  }
  .main-header {
    padding-top: calc(max(env(safe-area-inset-top), 0px) + 0.3125rem);
  }
  /*
      .sticky {
        top: calc(max(env(safe-area-inset-top), 0px) + 50px);
      }
  */
  .modal {
    top: calc(max(env(safe-area-inset-top), 0px) + 50px);
  }
}
.tab-scroller {
  display: flex;
  align-items: center;
  overflow: hidden;
  /*
    gap: .5rem;
  */
}
.tab-scroller .tab-scroller-left,
.tab-scroller .tab-scroller-right {
  cursor: pointer;
  display: none;
}
.tab-scroller .tab-scroller-left {
  padding-right: 0.5rem;
}
.tab-scroller .tab-scroller-right {
  padding-left: 0.5rem;
}
.tab-scroller .tab-scroller-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 700px;
}
.tab-scroller .nav-tabs {
  position: relative;
  left: 0;
}

/*
.skin-sgb-night {
  .content-right {
    > .card.card-tabs {
      > .card-header {
        background-color: chromatic(#3b4256, shade, 5);
        border-radius: 0;
        margin-bottom: 0;
        z-index: 5;

        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: .5rem;
          width: 100%;
          pointer-events: none;
          background: linear-gradient(to bottom, #303546 0%, #30354600 100%);
        }
      }

      > .card-header .nav-link.active,
      > .card-footer .nav-link.active {
        background-color: #303546;
        border-bottom: 1px solid #303546;
      }
    }
  }
}
*/
.card .card-header .card-tools {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem 0 0 -0.5rem;
  /*
    width: calc(100% + 0.5rem);
  */
}
.card .card-header .card-tools > * {
  margin: 0.5rem 0 0 0.5rem !important;
}

@media (max-width: 767.98px) {
  .card .card-header {
    flex-wrap: wrap;
  }
  .card .card-header .card-tools {
    display: flex;
    flex-wrap: wrap;
    margin: -0.5rem 0 0 -0.5rem;
    /*
      width: calc(100% + 0.5rem);
    */
  }
  .card .card-header .card-tools > * {
    margin: 0.5rem 0 0 0.5rem !important;
  }
}
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
.login-logo a,
.register-logo a {
  color: #444;
}
.login-logo img,
.register-logo img {
  width: 88%;
  max-width: 256px;
}

.login-page,
.register-page {
  background: #d2d6de;
}

.login-box,
.register-box {
  width: 360px;
  margin: 0 auto;
  margin-top: calc((100vh - 350px) / 2);
}
.login-box .form-group,
.register-box .form-group {
  position: relative;
}

.login-box-body,
.register-box-body {
  background: transparent;
  padding: 20px;
  border-top: 0;
  color: #666;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #777;
}
.login-box-body label,
.register-box-body label {
  color: #aaa;
}

.login-box-msg,
.register-box-msg {
  color: #aaa;
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

/*
remove More... options from tabs
scrollable
card-header::before {
    display: block;
    position: absolute;
    width: .5rem;
    height: .5rem;
    content: "";
    border: solid #c1c6d5;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 1rem;
    top: calc(50% - .375rem);
}

*/
.card-tabs-main {
  flex-grow: 1;
}

@media (max-width: 767.98px) {
  .card-tabs-main > .card-header {
    /* padding-top: .25rem !important; */
    z-index: 1;
  }
  .card-tabs-main > .card-header:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 0.5rem;
    width: 100%;
    pointer-events: none;
    background: linear-gradient(to bottom, #303546 0%, rgba(48, 53, 70, 0) 100%) !important;
  }
  .card-tabs-main > .card-header .tab-scroller {
    flex-grow: 1;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: #434b62;
  }
  .card-tabs-main > .card-header .tab-scroller .tab-scroller-wrapper {
    flex-grow: 1;
  }
  .card-tabs-main > .card-header .tab-scroller .tab-scroller-wrapper .nav-tabs .nav-item {
    flex-grow: 1;
    text-align: center;
  }
  .card-tabs-main > .card-header .tab-scroller .tab-scroller-wrapper .nav-tabs .nav-link.active {
    background-color: #303546;
  }
}
/* tab scroller light */
.scroll-residental-device-details > .card > .card-header {
  /* padding: 0 !important; */
}
.scroll-residental-device-details > .card > .card-header .tab-scroller {
  padding: 0 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background: #434b62;
}

.sticky-element {
  position: fixed !important;
  left: 0px;
  right: 0px;
  z-index: 5;
  flex-wrap: nowrap !important;
}

.offline-indicator {
  background: red;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  font-size: 0.8rem;
  width: 100%;
}

.text-xl {
  font-size: 1.5rem;
}

.card.card-stylish {
  border-radius: 0.5rem;
  margin-top: 1rem;
  /* padding: 0 .325rem .325rem .325rem; */
}
.skin-sgb .card.card-stylish {
  background-color: transparent !important;
}
.skin-sgb-night .card.card-stylish {
  background-color: #222632 !important;
}
.card.card-stylish .card-header,
.card.card-stylish .card-body {
  background-color: transparent;
}
.card.card-stylish .card-title {
  display: inline-block;
  position: relative;
  top: -0.5rem;
  left: 0;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 0.125rem 0.5rem;
  margin: 0 0 -0.5rem 0;
  flex-grow: 0;
  background: #f8ba00;
  background: linear-gradient(125deg, #f8ba00 0%, #f18f00 100%);
  color: #1F2D3D;
}

.toggle-onoff {
  display: block;
  margin: auto;
}

.modal-dialog .dropdown-menu {
  max-height: 80vh;
  overflow: scroll;
}

/* ember-models-table */
.table-layout-fixed table {
  table-layout: fixed;
}
.table-layout-fixed table th.narrow, .table-layout-fixed table td.narrow {
  width: 15%;
}

.navbar-nav .dropdown-menu {
  margin-top: 0.75rem;
}

/* adminlte custom themes */
.sidebar-sgb {
  background-color: #fff;
}
.sidebar-sgb .user-panel a:hover {
  color: #fff;
}
.sidebar-sgb .nav-sidebar .nav-item .nav-link {
  border-radius: 0;
  margin-bottom: 0;
}
.sidebar-sgb .nav-sidebar .nav-item .nav-link:active, .sidebar-sgb .nav-sidebar .nav-item .nav-link:focus {
  color: #fff;
}
.sidebar-sgb .nav-sidebar .nav-item.menu-open > .nav-link, .sidebar-sgb .nav-sidebar .nav-item:hover > .nav-link {
  color: #fff;
  background-color: #fff;
  border-left: 3px solid #fff;
  padding-left: calc(1rem - 3px);
}
.sidebar-sgb .nav-sidebar .nav-item > .nav-link.active {
  color: #fff;
  background-color: #fff;
  border-left: 3px solid #fff;
  padding-left: calc(1rem - 3px);
}
.sidebar-sgb .nav-sidebar .nav-item > .nav-treeview {
  background: #fff;
}
.sidebar-sgb .nav-header {
  color: #cccccc;
  background: #ececec;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
}
.sidebar-sgb .sidebar a {
  color: #fff;
}
.sidebar-sgb .sidebar a:hover {
  text-decoration: none;
}
.sidebar-sgb .nav-treeview > .nav-item > .nav-link:hover {
  color: #fff;
  background-color: #fff;
}
.sidebar-sgb .nav-treeview > .nav-item > .nav-link.active, .sidebar-sgb .nav-treeview > .nav-item > .nav-link.active:hover {
  color: #fff;
  background-color: #fff;
}

/* component styles */
/* https://github.com/ebryn/ember-component-css */
/* ember-cli-sass-pods */
.has-error .form-control:focus,
.has-success .form-control:focus {
  box-shadow: none;
}

.has-error .form-control:focus {
  border-color: tomato;
}

.has-success .form-control:focus {
  border-color: #1fbb14;
}

.validated-input {
  position: relative;
}

.validated-input .valid-input {
  position: absolute;
  right: 30px;
  top: 11px;
  font-size: 12px;
  color: #646262;
}

.validated-input .input-error {
  font-size: 12px;
}

.validated-input .input-error .error {
  padding: 8px 5px 0 0;
  color: #ff411f;
  text-align: left;
}

.iconpicker {
  clear: both;
}
.iconpicker .iconpicker-items {
  background: transparent;
  padding: 0;
}
.iconpicker .iconpicker-item {
  box-shadow: none;
  width: 1rem;
  height: 1rem;
  padding: 0.6rem;
  margin: 0 0.5rem 0.5rem 0;
  font-size: 1rem;
  line-height: 1;
}
.iconpicker .iconpicker-item.iconpicker-selected {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.iconpicker-popover.popover {
  background: transparent;
  border: none;
  bow-shadow: none;
  width: 100%;
}
.iconpicker-popover.popover .popover-title {
  background: transparent;
  border: 0;
  padding: 0;
}
.iconpicker-popover.popover .popover-title input[type=search].iconpicker-search {
  margin-bottom: 10px;
}

/* addon: ember-notify */
/*
 * Given an ember-notify instance {{ember-notify classPrefix="custom-notify"}}
 * the following styles will position the notify box in the top center of the screen.
 * They also include custom animations for bouncing in and out.
 * These styles were shamelessly copied from https://github.com/Blooie/ember-cli-notifications/tree/master/addon/styles
 */
.custom-notify {
  position: fixed;
  top: 10px;
  right: 0;
  left: 0;
  z-index: 1060;
  width: 80%;
  max-width: 400px;
  margin: auto;
}

.custom-notify .callout {
  font: 14px Helvetica, sans-serif;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 2px;
  max-height: 800px;
  color: #fff;
  animation: notification-hide 250ms cubic-bezier(0.33859, -0.42, 1, -0.22), notification-shrink 250ms 250ms cubic-bezier(0.5, 0, 0, 1);
  -webkit-animation: notification-hide 250ms cubic-bezier(0.33859, -0.42, 1, -0.22), notification-shrink 250ms 250ms cubic-bezier(0.5, 0, 0, 1);
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.custom-notify .ember-notify-show {
  animation: notification-show 180ms cubic-bezier(0.175, 0.885, 0.32, 1.27499);
  -webkit-animation: notification-show 180ms cubic-bezier(0.175, 0.885, 0.32, 1.27499);
}

.custom-notify .custom-notify-icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 30px;
  color: rgba(255, 255, 255, 0.74);
  text-align: center;
}

.custom-notify .custom-notify-icon span {
  position: relative;
  top: 10px;
}

.custom-notify .message {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 30px;
}

.custom-notify .close {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.3;
  cursor: pointer;
}

.custom-notify .close:hover,
.custom-notify .close:focus {
  opacity: 1;
}

.custom-notify .error {
  background-color: #e74c3c;
}

.custom-notify .error .custom-notify-icon {
  background-color: #ba2c1d;
}

.custom-notify .error .close {
  color: #ba2c1d;
}

.custom-notify .success {
  background-color: #64ce83;
}

.custom-notify .success .custom-notify-icon {
  background-color: #3da95c;
}

.custom-notify .success .close {
  color: #3da95c;
}

.custom-notify .info {
  background-color: #3ea2ff;
}

.custom-notify .info .custom-notify-icon {
  background-color: #067cea;
}

.custom-notify .info .close {
  color: #067cea;
}

.custom-notify .warning {
  background-color: #ff7f48;
}

.custom-notify .warning .custom-notify-icon {
  background-color: #f44e06;
}

.custom-notify .warning .close {
  color: #f44e06;
}

/* animations used above */
@keyframes notification-show {
  0% {
    opacity: 0;
    transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}
@-webkit-keyframes notification-show {
  0% {
    opacity: 0;
    -webkit-transform: perspective(450px) translate(0, -30px) rotateX(90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: perspective(450px) translate(0, 0) rotateX(0deg);
  }
}
@keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: 800px;
    margin-bottom: 2px;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    transform: scale(0.8);
  }
}
@-webkit-keyframes notification-shrink {
  0% {
    opacity: 0;
    max-height: 800px;
    margin-bottom: 2px;
    -webkit-transform: scale(0.8);
  }
  100% {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;
    -webkit-transform: scale(0.8);
  }
}
@keyframes notification-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}
@-webkit-keyframes notification-hide {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.8);
  }
}
/* addon: intl-tel-input */
.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}
.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}
@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}
.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}
.iti__country {
  padding: 5px 10px;
  outline: none;
}
.iti__dial-code {
  color: #999;
}
.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}
.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}
.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}
.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}
.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}
.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}
.iti__flag.iti__be {
  width: 18px;
}
.iti__flag.iti__ch {
  width: 15px;
}
.iti__flag.iti__mc {
  width: 19px;
}
.iti__flag.iti__ne {
  width: 18px;
}
.iti__flag.iti__np {
  width: 13px;
}
.iti__flag.iti__va {
  width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}
.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}
.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}
.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}
.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}
.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}
.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}
.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}
.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}
.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}
.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}
.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}
.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}
.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}
.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}
.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}
.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}
.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}
.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}
.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}
.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}
.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}
.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}
.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}
.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}
.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}
.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}
.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}
.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}
.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("images/flags@2x.png");
  }
}

.iti__flag.iti__np {
  background-color: transparent;
}

/* addon: ember-modal-dialog */
.ember-modal-dialog {
  z-index: 51;
  position: fixed;
}

.ember-modal-dialog.emd-in-place {
  position: static;
}

.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ember-modal-wrapper.emd-animatable.emd-wrapper-target-attachment-center {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ember-modal-wrapper.emd-animatable.emd-wrapper-target-attachment-center .ember-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ember-modal-wrapper.emd-animatable .ember-modal-dialog {
  position: relative;
}

.ember-modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.ember-modal-dialog {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px #222;
  padding: 10px;
}

.ember-modal-overlay.translucent {
  background-color: rgba(128, 128, 128, 0.77);
}

/* addon: webfont */
/* addon: webfont */
.iconfont {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont:before {
  position: relative;
  top: 16px;
}

/* vendor: fontello */
.jstree-anchor [class^=icon-]:before, .jstree-anchor [class*=" icon-"]:before,
.jstree-anchor [class^=fa-]:before, .jstree-anchor [class*=" fa-"]:before,
.jstree-customicons [class^=icon-]:before,
.jstree-customicons [class*=" icon-"]:before,
.jstree-customicons [class^=fa-]:before,
.jstree-customicons [class*=" fa-"]:before {
  font-size: 1rem;
  line-height: 1.4;
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

[class^=icon-], [class*=" icon-"] {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

[class^=icon-]:before, [class*=" icon-"]:before {
  width: auto;
  margin-right: 0;
  margin-left: 0;
}

.far {
  font-weight: 300;
}

/* custom modules */
/* hack for bootstrap-switch */
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  box-sizing: content-box;
  line-height: 1.5;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 16rem;
}

.capture {
  max-width: 1000px;
  margin: 50px auto;
  padding: 20px;
  background-color: #efefef;
}

.capture .app {
  width: 100%;
  position: relative;
}

.capture .app #start-camera {
  display: none;
  border-radius: 3px;
  max-width: 400px;
  color: #fff;
  background-color: #448AFF;
  text-decoration: none;
  padding: 15px;
  opacity: 0.8;
  margin: 50px auto;
  text-align: center;
}

.capture .app video#camera-stream {
  display: none;
  width: 100%;
}

.capture .app img#snap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: none;
}

.capture .app #error-message {
  width: 100%;
  background-color: #ccc;
  color: #9b9b9b;
  font-size: 28px;
  padding: 200px 100px;
  text-align: center;
  display: none;
}

.capture .app .controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 30px;
  display: none;
}

.capture .app .controls a {
  border-radius: 50%;
  color: #fff;
  background-color: #111;
  text-decoration: none;
  padding: 15px;
  line-height: 0;
  opacity: 0.7;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.capture .app .controls a:hover {
  opacity: 1;
}

.capture .app .controls a.disabled {
  background-color: #555;
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.capture .app .controls a.disabled:hover {
  opacity: 0.5;
}

.capture .app .controls a i {
  font-size: 18px;
}

.capture .app .controls #take-photo i {
  font-size: 32px;
}

.capture .app canvas {
  display: none;
}

.capture .app video#camera-stream.visible,
.capture .app img#snap.visible,
.capture .app #error-message.visible {
  display: block;
}

.capture .app .controls.visible {
  display: flex;
}

@media (max-width: 1000px) {
  .capture {
    margin: 40px;
  }
  .capture .app #start-camera.visible {
    display: block;
  }
  .capture .app .controls a i {
    font-size: 16px;
  }
  .capture .app .controls #take-photo i {
    font-size: 24px;
  }
}
@media (max-width: 600px) {
  .capture {
    margin: 10px;
  }
  .capture .app #error-message {
    padding: 80px 50px;
    font-size: 18px;
  }
  .capture .app .controls a i {
    font-size: 12px;
  }
  .capture .app .controls #take-photo i {
    font-size: 18px;
  }
}
.cbutton {
  margin: 0;
  color: #f39c12;
  font-size: 1rem;
}

.cbutton::after {
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
}

.cbutton--effect-radomir::after {
  box-shadow: inset 0 0 0 20px rgba(111, 148, 182, 0);
}

.cbutton__text {
  display: none;
}

.dg button,
.dg input,
.dg optgroup,
.dg select,
.dg textarea {
  color: initial;
}
.dg .cr.boolean .property-name {
  width: 75%;
}
.dg .cr.boolean .c {
  width: 25%;
  text-align: center;
}
.dg .cr.function .property-name {
  width: 75%;
}
.dg .cr.function .c {
  width: 25%;
}
.dg .c input[type=checkbox] {
  margin-top: 5px;
  color: #aaa;
  height: 0.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
}
.dg .c input[type=checkbox]:checked {
  border-color: #f39c12;
}
.dg .c input[type=checkbox]:checked::after {
  transform: translateX(1rem);
}
.dg .c input[type=checkbox]::after {
  height: 0.5rem;
  width: 0.5rem;
}
.dg .c select {
  width: 100%;
  border: none;
  margin-top: 0 !important;
  color: #eee;
}
.dg .c select option {
  appearance: none;
  background: #2a2a2a;
  color: #eee;
  padding: 0 2px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.direct-chat-messages {
  height: auto;
  min-height: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.5rem;
  transition: min-height 0.5s ease-in-out;
}
.direct-chat-messages.contacts-open {
  min-height: 250px;
  transition: min-height 0.5s ease-in-out;
}

.direct-chat-contacts {
  height: auto;
  min-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}
.direct-chat-contacts .CodeMirror {
  height: 100%;
  min-height: 100px;
}

.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
  min-width: 50%;
}

@media (max-width: 575.98px) {
  .ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 576px) {
  .ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
    transform: translate(-25%, -50%);
  }
}
@media (min-width: 768px) {
  .ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
    transform: translate(-30%, -50%);
  }
}
@media (min-width: 992px) {
  .ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center .ember-modal-dialog {
    transform: translate(-35%, -50%);
  }
}
/* ember-models-table */
.models-table-wrapper .column-id {
  width: 5%;
  min-width: 100px;
}
.models-table-wrapper .column-actions {
  width: 1%;
}
.models-table-wrapper th.column-actions {
  /* Hide the text. */
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.models-table-wrapper .column-actions-1 {
  min-width: 2.5rem;
}
.models-table-wrapper .column-actions-2 {
  min-width: 4rem;
}
.models-table-wrapper .column-actions-3 {
  min-width: 5.5rem;
}
.models-table-wrapper .table-footer {
  border: 1px solid;
  padding: 0.25rem;
}
.models-table-wrapper .table-footer .table-summary {
  padding: 0.4rem 1rem;
}
.models-table-wrapper .table-footer .table-summary .btn {
  padding-top: 0;
  padding-bottom: 0;
}
.models-table-wrapper .table-footer .table-nav {
  text-align: right;
}
.models-table-wrapper .globalSearch {
  margin-bottom: 1rem;
}
.models-table-wrapper .filterString .btn.disabled, .models-table-wrapper .filterString .btn:disabled, .models-table-wrapper .globalSearch .btn.disabled, .models-table-wrapper .globalSearch .btn:disabled {
  display: none;
}
.models-table-wrapper .form-control {
  height: 2.2rem;
}
.models-table-wrapper .input-group-append .btn {
  height: 2.1rem;
}
.models-table-wrapper .columns-dropdown > .btn {
  padding: 0;
  border: 0;
}

.floorplan {
  position: relative;
}
.floorplan .card-section-title {
  display: none;
}
.floorplan img {
  opacity: 0.3;
}
.floorplan .floorplan-svg {
  width: 100%;
}
.floorplan .floorplan-svg svg path {
  fill: #d2d6de;
  fill-opacity: 0.5;
}
.floorplan .object-status-tiles-residental {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  /* override grid behaviour */
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  grid-template-columns: unset;
  grid-gap: unset;
}
.floorplan .object-status-tiles-residental .grid-item {
  width: 13.75%;
  min-width: 13.75%;
  max-width: 13.75%;
  margin: 0.25%;
}
.floorplan .object-status-tiles-residental .grid-item .tile {
  min-height: 0;
  padding: 5px;
  color: #d2d6de;
}
.floorplan .object-status-tiles-residental .grid-item .tile > i {
  font-size: 20px;
}
.floorplan .object-status-tiles-residental .grid-item .tile .icons > i {
  font-size: 16px;
}
.floorplan .object-status-tiles-residental .grid-item .title {
  font-size: 0.75em;
  font-weight: normal;
  margin-top: 0;
}

@media (max-width: 575.98px) {
  .floorplan .object-status-tiles-residental .grid-item p {
    display: none;
  }
  .floorplan .object-status-tiles-residental .grid-item .title {
    display: none;
  }
  .floorplan .object-status-tiles-residental .grid-item .icon-markers {
    display: none;
  }
  .floorplan .object-status-tiles-residental .grid-item .object-status-actions {
    display: none;
  }
}
.skin-sgb .floorplan .floorplan-svg {
  width: 100%;
}
.skin-sgb .floorplan .floorplan-svg svg path {
  fill: #444;
  fill-opacity: 0.5;
}

.file-upload {
  display: block;
}
.file-upload .input-group-append {
  padding: 1px;
}

.irs--flat {
  margin: 0 0.75rem;
}

.irs--flat .irs-bar {
  background-color: #f8ba00;
  background-image: -webkit-linear-gradient(left, #f18f00, #f8ba00);
  background-image: -o-linear-gradient(left, #f18f00, #f8ba00);
  background-image: linear-gradient(to right, #f18f00, #f8ba00);
}

.irs--flat .irs-handle > i:first-child {
  background-color: #da8c10;
}

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #f18f00;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  background-color: #f8ba00;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #f8ba00;
}

.skin-sgb-night .irs--flat .irs-from,
.skin-sgb-night .irs--flat .irs-to,
.skin-sgb-night .irs--flat .irs-single {
  color: #212529;
}
.skin-sgb-night .irs--flat .irs-min,
.skin-sgb-night .irs--flat .irs-max {
  color: #cbcdd1;
  background-color: #5d657c;
}
.skin-sgb-night .irs--flat .irs-line {
  background-color: #5d657c;
}
.skin-sgb-night .irs--flat .irs-grid-text {
  color: #cbcdd1;
}

/* small slider */
.irs--flat.irs-sm {
  margin: 0;
  height: 36;
}
.irs--flat.irs-sm .irs-bar {
  height: 8px;
  background-color: #f8ba00;
  background-image: linear-gradient(to right, #f18f00, #f8ba00);
}
.irs--flat.irs-sm .irs-line {
  height: 8px;
  border-radius: 6px;
}
.irs--flat.irs-sm .irs-bar--single {
  border-radius: 6px 0 0 6px;
}
.irs--flat.irs-sm .irs-handle {
  top: 20px;
  width: 18px;
  height: 18px;
  background-color: #f8ba00;
  border-radius: 10px;
}
.irs--flat.irs-sm .irs-handle > i:first-child {
  background: transparent;
}
.irs--flat.irs-sm .irs-handle.state_hover > i:first-child, .irs--flat.irs-sm .irs-handle:hover > i:first-child {
  background-color: transparent;
}

.irs--flat.irs-blue .irs-line {
  background-color: #0081f5;
  background-image: linear-gradient(to right, #007ff3, #244bc3);
}
.irs--flat.irs-blue .irs-bar {
  background-color: transparent;
  background-image: none;
}
.irs--flat.irs-blue .irs-handle {
  background-color: #0081f5;
}

.irs--flat.irs-red .irs-line {
  background-color: #b32b35;
  background-image: linear-gradient(to right, #6e0a00, #e11100);
}
.irs--flat.irs-red .irs-bar {
  background-color: transparent;
  background-image: none;
}
.irs--flat.irs-red .irs-handle {
  background-color: #b32b35;
}

.irs--flat.irs-green .irs-line {
  background-color: #57b54e;
  background-image: linear-gradient(to right, #005300, #00c100);
}
.irs--flat.irs-green .irs-bar {
  background-color: transparent;
  background-image: none;
}
.irs--flat.irs-green .irs-handle {
  background-color: #57b54e;
}

.irs--flat.irs-yellow .irs-line {
  background-color: #ff9300;
  background-image: linear-gradient(to right, #886100, #efaa00);
}
.irs--flat.irs-yellow .irs-bar {
  background-color: transparent;
  background-image: none;
}
.irs--flat.irs-yellow .irs-handle {
  background-color: #ff9300;
}

.irs--flat.irs-sm .irs-from,
.irs--flat.irs-sm .irs-to,
.irs--flat.irs-sm .irs-single,
.irs--flat.irs-red .irs-from,
.irs--flat.irs-red .irs-to,
.irs--flat.irs-red .irs-single,
.irs--flat.irs-green .irs-from,
.irs--flat.irs-green .irs-to,
.irs--flat.irs-green .irs-single,
.irs--flat.irs-blue .irs-from,
.irs--flat.irs-blue .irs-to,
.irs--flat.irs-blue .irs-single,
.irs--flat.irs-yellow .irs-from,
.irs--flat.irs-yellow .irs-to,
.irs--flat.irs-yellow .irs-single {
  background: transparent;
  color: #c1c6d5;
  font-size: 1rem;
}

.jstree-proton .jstree-open .jstree-themeicon {
  background-position: -105px -71px;
}
.jstree-proton .jstree-closed .jstree-themeicon {
  background-position: -261px -7px;
}
.jstree-proton .jstree-leaf .jstree-themeicon {
  background-position: -261px -39px;
}

@media (max-width: 768px) {
  .jstree-proton-responsive .jstree-node {
    min-height: 30px;
    line-height: 30px;
    margin-top: 0;
  }
  .jstree-proton-responsive .jstree-icon,
  .jstree-proton-responsive .jstree-icon:empty {
    margin-top: 0px;
    height: 30px;
    line-height: 30px;
  }
  .jstree-proton-responsive .jstree-anchor {
    height: 30px;
    line-height: 30px;
    margin: 0;
  }
  .jstree-proton-responsive .jstree-customicons-ul .jstree-customicons {
    padding-top: 4px;
  }
  .jstree-proton-responsive .jstree-wholerow-ul .jstree-wholerow {
    height: unset;
  }
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: absolute;
  left: calc(50vw - 40px);
  top: calc(50vh - 40px);
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.login-logo,
.register-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
}
.login-logo a,
.register-logo a {
  color: #444;
}
.login-logo img,
.register-logo img {
  width: 88%;
  max-width: 256px;
}

.login-page,
.register-page {
  background: #d2d6de;
}

.login-box,
.register-box {
  width: 360px;
  margin: 0 auto;
  margin-top: calc((100vh - 350px) / 2);
}
.login-box .form-group,
.register-box .form-group {
  position: relative;
}

.login-box-body,
.register-box-body {
  background: transparent;
  padding: 20px;
  border-top: 0;
  color: #666;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
  color: #777;
}
.login-box-body label,
.register-box-body label {
  color: #aaa;
}

.login-box-msg,
.register-box-msg {
  color: #aaa;
  margin: 0;
  text-align: center;
  padding: 0 20px 20px 20px;
}

.object-status-tiles-residental {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-gap: 0.5rem;
  margin: 0;
  /* popper js dropdown overflows otherwise */
  /* removed because of overflow-y issue */
  /* overflow-x: hidden; */
}
.object-status-tiles-residental.groups {
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
}
.object-status-tiles-residental.floors {
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
}
.object-status-tiles-residental .grid-item {
  position: relative;
  /*
  // it breaks dropdown-menu inside grid-item
  overflow: hidden;
  */
}
.object-status-tiles-residental .grid-item .tile {
  display: block;
  background: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  position: relative;
  min-height: 7rem;
}
.object-status-tiles-residental .grid-item .tile.hover {
  cursor: pointer;
}
.object-status-tiles-residental .grid-item .tile.clickable {
  cursor: pointer;
}
.object-status-tiles-residental .grid-item .tile.group {
  /*
          min-height: 8.25rem;
          padding: 0.25rem 0.5rem 0.75rem 0.5rem;
  */
  min-height: 7rem;
  padding: 0.25rem 0.5rem;
}
.object-status-tiles-residental .grid-item .tile > i {
  font-size: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.object-status-tiles-residental .grid-item .tile > p i {
  font-size: inherit;
}
.object-status-tiles-residental .grid-item .tile .icons-wrap {
  align-items: center;
  justify-content: center;
  min-height: 2.75rem;
}
.object-status-tiles-residental .grid-item .tile .icons > i {
  font-size: 21px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.object-status-tiles-residental .grid-item .title {
  font-size: 0.75rem;
  line-height: 1.2;
  /* margin-top: 5px; */
  text-align: center;
  position: relative;
  margin: -0.5rem 0.25rem 0 0.25rem;
  border-radius: 5px;
  padding: 0.25rem;
  background-color: #576180;
  /* max-height: 2rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}
.object-status-tiles-residental .grid-item .tile.selected {
  border: 1px solid #f18f00 !important;
}
.object-status-tiles-residental .grid-item .tile.selected + .title {
  background: #f8ba00;
  background: linear-gradient(180deg, #f8ba00 0%, #f18f00 100%);
  color: #1F2D3D;
}
.object-status-tiles-residental .grid-item p {
  font-size: 0.75rem;
  margin: 5px 0;
  line-height: 1.2;
  min-height: 1.2em;
}
.object-status-tiles-residental .grid-item p:first-of-type {
  margin-top: 0;
}
.object-status-tiles-residental .grid-item p:last-of-type {
  margin-bottom: 0;
}
.object-status-tiles-residental .grid-item .icon-markers {
  position: absolute;
  top: 0;
  left: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-wrap: wrap;
  max-height: 7rem;
}
.object-status-tiles-residental .grid-item .icon-markers.position-left {
  left: 0.5rem;
  right: unset;
  align-content: flex-start;
}
.object-status-tiles-residental .grid-item .icon-markers.position-right {
  left: unset;
  right: 0.5rem;
  align-content: flex-end;
}
.object-status-tiles-residental .grid-item .icon-marker {
  position: absolute;
  /*
        &.position-flex:not(.d-none):first-of-type {
          margin-top: 0;
        }
        &.position-flex:not(.d-none):last-of-type {
          margin-bottom: 0;
        }
  */
}
.object-status-tiles-residental .grid-item .icon-marker.position-flex {
  position: unset;
  margin: 0.5rem 0 0 0;
}
.object-status-tiles-residental .grid-item .icon-marker.position-1 {
  top: 0.5rem;
  left: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-2 {
  top: 1.875rem;
  left: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-3 {
  top: 3.25rem;
  left: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-4 {
  top: 4.625rem;
  left: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-5 {
  top: 6rem;
  left: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-6 {
  top: 0.5rem;
  right: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-7 {
  top: 1.875rem;
  right: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-8 {
  top: 3.25rem;
  right: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-9 {
  top: 4.625rem;
  right: 0.5rem;
}
.object-status-tiles-residental .grid-item .icon-marker.position-10 {
  top: 6rem;
  right: 0.5rem;
}

.object-status-actions {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
}
.object-status-actions .btn {
  font-size: 1.2rem;
  line-height: 1rem;
  padding: 0;
}
.object-status-actions .dropdown-toggle::after {
  display: none;
}

.skin-sgb-night .icon-marker.text-gray {
  color: rgba(210, 214, 222, 0.3137254902) !important;
}
.skin-sgb-night .object-status-actions .btn {
  color: #cbcdd1;
}

.combined-sensor-info {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 4px;
  margin-left: 1rem;
  margin-bottom: 1;
}
.combined-sensor-info i {
  font-size: 1.8rem;
}
.combined-sensor-info p {
  text-align: left;
  margin: 0 !important;
}

.object-status-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-gap: 1px;
  margin: 0;
}
.object-status-tiles .grid-item {
  position: relative;
  overflow: hidden;
  padding: 3px 5px;
  min-height: 3.8rem;
  max-height: 3.8rem;
  text-align: center;
}
.object-status-tiles .grid-item i {
  font-size: 100%;
  display: inline-block;
}
.object-status-tiles .grid-item h4 {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
}
.object-status-tiles .grid-item p {
  font-size: 0.75rem;
  margin: 0;
  line-height: 1.2;
}
.object-status-tiles .grid-item p.sm {
  font-size: 0.75rem !important;
  line-height: 1.2 !important;
  margin: 0 !important;
}
.object-status-tiles .grid-item p:only-of-type {
  margin-top: 5px;
  font-size: inherit;
  line-height: inherit;
}
.object-status-tiles .list-counter {
  margin: 5px 0 0 0;
}
.object-status-tiles .list-counter > li {
  margin: 0;
  min-width: 1rem;
  font-size: 0.75rem;
}
.object-status-tiles .btn-sm, .object-status-tiles .btn-group-sm > .btn {
  padding: 3px 8px;
  display: block;
  margin: 3px auto;
  width: 80%;
}

.scroll-residental-device-details .card .card-header {
  flex-wrap: wrap;
}
.scroll-residental-device-details .card .card-header .card-title {
  /* margin-bottom: 1rem; */
  text-align: center;
}
@media (max-width: 767.98px) {
  .scroll-residental-device-details .card .card-header .card-title {
    text-align: center;
  }
}

.scroll-residental-device-details > .card > .card-body {
  padding: 0;
}
.scroll-residental-device-details .tab-pane > .card > .card-header .card-title {
  display: none;
}
.scroll-residental-device-details #pane-device-rulesets > .card > .card-header .card-title {
  display: block;
}

.c-group-header {
  position: relative;
  text-align: center;
  padding: 0.5rem 0 0.25rem 0;
  border-radius: 0.5rem 0.5rem 0 0;
  background: #434b62;
}
.c-group-header .c-header {
  display: flex;
  box-sizing: content-box;
}
.c-group-header .c-header .nav-button {
  font-size: 1.25rem;
  line-height: 1;
  margin: 0 0.5rem;
  margin-top: 0.375rem;
  /*
        &:first-child {
          margin-left: 1rem;
        }

        &:last-child {
          margin-right: 1rem;
        }
  */
}
.c-group-header .c-header .nav-button a {
  color: currentColor;
}
.c-group-header .c-header .nav-button a:hover {
  color: rgba(255, 255, 255, 0.8);
}
.c-group-header .c-header .nav-button.back-button {
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 0;
}
.c-group-header .c-header .nav-button.back-button-placeholder {
  width: 1.375rem;
}
.c-group-header .c-header .nav-button.onoff-placeholder {
  width: 2.375rem;
}
.c-group-header .c-header .c-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.125;
  flex-grow: 1;
  margin: 0 0.5rem;
}
.c-group-header .c-header + .c-body {
  margin-top: 0.25rem;
}
.c-group-header .c-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
}
.c-group-header .c-body > div {
  flex-grow: 1;
  padding: 0 0.25rem 0 0.25rem;
}
.c-group-header .c-body p {
  margin: 0;
}
.c-group-header .c-body i {
  display: inline-block;
  margin-right: 0.125rem;
  font-size: 1rem;
}
.c-group-header .c-body .list-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.6875rem;
  margin: 0;
}
.c-group-header .c-body .list-counter .list-inline-item {
  margin: 0;
  min-width: 1.3125rem;
  min-height: 1.3125rem;
  font-size: 0.8rem;
  line-height: 1.325rem;
  text-align: center;
}
.c-group-header .c-body .icon-markers .icon-marker {
  position: relative;
  min-width: 2rem;
}
.c-group-header .c-body .icon-markers .icon-marker i {
  line-height: 1.5;
}
.c-group-header .c-body .icon-markers .icon-marker .badge {
  position: relative;
  top: -5px;
  right: 10px;
  font-size: 0.6rem;
  font-weight: 300;
  padding: 2px 4px;
  margin-right: -6px;
  background-color: rgba(131, 139, 162, 0.7098039216);
  color: #fff;
}

.c-group-header:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0.5rem;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(to bottom, #303546 0%, rgba(48, 53, 70, 0) 100%);
}

@media (max-width: 767.98px) {
  .c-group-header:after {
    height: 0;
  }
}
.scroll-residental-device-details .c-group-header {
  flex-grow: 1;
  width: 100%;
  margin: 0 -0.5rem;
  border-radius: unset;
  background: unset;
}
.scroll-residental-device-details .c-group-header .c-header .nav-button {
  margin-top: 0.125rem;
}
.scroll-residental-device-details .c-group-header .c-title {
  font-size: 1.125rem;
  line-height: 1;
}
.scroll-residental-device-details .c-group-header:after {
  height: 0;
}

.sortable-objects-wrap {
  /* background: rgba(0, 0, 0, 0.075); */
  background: #3c4150;
}

.sortable-objects {
  display: flex;
  flex-wrap: wrap;
}

.sortObject {
  width: 10%;
  flex-grow: 0;
}

.sortObject-inner {
  height: 6rem;
  background: #5d657c;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.25rem solid #f39c12;
  text-align: center;
  line-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.sortObject-inner.empty {
  border-color: rgba(0, 0, 0, 0.25);
}
.sortObject-inner i {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 0.25rem;
}

.grid-wrap {
  width: 800px;
  position: relative;
  margin: 0 auto;
}

.bfp-grid-wrap {
  height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: stretch;
  grid-gap: 0;
}

.grid-snap {
  /*  touch-action: none; */
  background-color: rgba(82, 89, 109, 0.6);
  /*  border: 1px solid #474d5f; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  position: absolute;
  width: 12.5%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1rem;
  z-index: 1;
}
.grid-snap i {
  font-size: 20px;
  margin-bottom: 0.25rem;
}
.grid-snap.active {
  border: 1px solid #f39c12;
  z-index: 2;
}

.grid-snap-inner {
  margin: 0.5rem;
  flex-grow: 1;
}

.floorplan-svg {
  width: 100%;
}

.bfp-grid-wrap {
  background: rgba(0, 0, 0, 0.1);
  width: 1000px;
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  height: 500px;
}
.bfp-grid-wrap .grid-background {
  width: 100%;
}
.bfp-grid-wrap .grid-snaps {
  width: 100%;
  position: absolute;
  height: 500px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: stretch;
  grid-gap: 0;
}
.bfp-grid-wrap .grid-snap {
  /*  touch-action: none; */
  background-color: rgba(82, 89, 109, 0.6);
  /*  border: 1px solid #474d5f; */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  position: absolute;
  width: 10%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1rem;
  z-index: 1;
}
.bfp-grid-wrap .grid-snap i {
  font-size: 20px;
  margin-bottom: 0.25rem;
}
.bfp-grid-wrap .grid-snap.active {
  border: 1px solid #f39c12;
  z-index: 2;
}
.bfp-grid-wrap .grid-snap-inner {
  margin: 0.5rem;
  flex-grow: 1;
}

.swal2-container {
  z-index: 1160;
}

.ember-basic-dropdown {
  position: relative;
}

.ember-basic-dropdown, .ember-basic-dropdown-content, .ember-basic-dropdown-content * {
  box-sizing: border-box;
}

.ember-basic-dropdown-content {
  position: absolute;
  width: auto;
  z-index: 1000;
  background-color: #ffffff;
}

.ember-basic-dropdown-content--left {
  left: 0;
}

.ember-basic-dropdown-content--right {
  right: 0;
}

.ember-basic-dropdown-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  pointer-events: none;
}

.ember-basic-dropdown-content-wormhole-origin {
  display: inline;
}

.ember-power-select-dropdown * {
  box-sizing: border-box;
}

.ember-power-select-trigger {
  position: relative;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  line-height: 2;
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-height: 2em;
  user-select: none;
  -webkit-user-select: none;
  color: inherit;
  /* Minimum clearfix for modern browsers */
}
.ember-power-select-trigger:after {
  content: "";
  display: table;
  clear: both;
}

.ember-power-select-trigger:focus,
.ember-power-select-trigger--active {
  border-top: 1px solid #66afe9;
  border-bottom: 1px solid #66afe9;
  border-right: 1px solid #66afe9;
  border-left: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.ember-basic-dropdown-trigger--below.ember-power-select-trigger[aria-expanded=true],
.ember-basic-dropdown-trigger--in-place.ember-power-select-trigger[aria-expanded=true] {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ember-basic-dropdown-trigger--above.ember-power-select-trigger[aria-expanded=true] {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ember-power-select-placeholder {
  color: #999999;
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ember-power-select-status-icon {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-style: solid;
  border-width: 7px 4px 0 4px;
  border-color: #999 transparent transparent transparent;
}
.ember-basic-dropdown-trigger[aria-expanded=true] .ember-power-select-status-icon {
  transform: rotate(180deg);
}

.ember-power-select-clear-btn {
  position: absolute;
  cursor: pointer;
}

.ember-power-select-trigger-multiple-input {
  font-family: inherit;
  font-size: inherit;
  border: none;
  display: inline-block;
  line-height: inherit;
  -webkit-appearance: none;
  outline: none;
  padding: 0;
  float: left;
  background-color: transparent;
  text-indent: 2px;
  /* There's a browser bug where this selectos cannot be chained with commas */
}
.ember-power-select-trigger-multiple-input:disabled {
  background-color: #eeeeee;
}
.ember-power-select-trigger-multiple-input::placeholder {
  opacity: 1;
  color: #999999;
}
.ember-power-select-trigger-multiple-input::-webkit-input-placeholder {
  opacity: 1;
  color: #999999;
}
.ember-power-select-trigger-multiple-input::-moz-placeholder {
  opacity: 1;
  color: #999999;
}
.ember-power-select-trigger-multiple-input::-ms-input-placeholder {
  opacity: 1;
  color: #999999;
}

.ember-power-select-multiple-options {
  padding: 0;
  margin: 0;
}

.ember-power-select-multiple-option {
  border: 1px solid gray;
  border-radius: 4px;
  color: #333333;
  background-color: #e4e4e4;
  padding: 0 4px;
  display: inline-block;
  line-height: 1.45;
  float: left;
  margin: 2px 0 2px 3px;
}

.ember-power-select-multiple-remove-btn {
  cursor: pointer;
}
.ember-power-select-multiple-remove-btn:not(:hover) {
  opacity: 0.5;
}

.ember-power-select-search {
  padding: 4px;
}

.ember-power-select-search-input {
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  padding: 0 5px;
}
.ember-power-select-search-input:focus {
  border: 1px solid #66afe9;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0;
}

.ember-power-select-dropdown {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  line-height: 2;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.172549) 0px 6px 12px 0px;
  overflow: hidden;
  color: inherit;
}

.ember-power-select-dropdown.ember-basic-dropdown-content--above {
  transform: translateY(calc(-1 * 3px));
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ember-power-select-dropdown.ember-basic-dropdown-content--below, .ember-power-select-dropdown.ember-basic-dropdown-content--in-place {
  transform: translateY(3px);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ember-power-select-dropdown.ember-basic-dropdown-content--in-place {
  width: 100%;
}

.ember-power-select-options {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-user-select: none;
}
.ember-power-select-options[role=listbox] {
  overflow-y: auto; /* in firefox in windows this can cause a word-break issue. Try `overflow-y: scroll` if that happens */
  -webkit-overflow-scrolling: touch;
  max-height: 14em;
}

.ember-power-select-option {
  cursor: pointer;
  padding: 0 8px;
}

.ember-power-select-group[aria-disabled=true] {
  color: #999999;
  cursor: not-allowed;
}

.ember-power-select-group[aria-disabled=true] .ember-power-select-option,
.ember-power-select-option[aria-disabled=true] {
  color: #999999;
  pointer-events: none;
  cursor: not-allowed;
}

.ember-power-select-option[aria-selected=true] {
  background-color: #f5f5f5;
}

.ember-power-select-option[aria-current=true] {
  background-color: #f5f5f5;
  color: inherit;
}

.ember-power-select-group-name {
  cursor: default;
  font-weight: bold;
}

.ember-power-select-trigger[aria-disabled=true] {
  background-color: #eeeeee;
}

.ember-power-select-trigger {
  padding: 0 16px 0 0;
}

.ember-power-select-selected-item, .ember-power-select-placeholder {
  margin-left: 8px;
}

.ember-power-select-status-icon {
  right: 5px;
}

.ember-power-select-clear-btn {
  right: 25px;
}

.ember-power-select-group .ember-power-select-group .ember-power-select-group-name {
  padding-left: 24px;
}
.ember-power-select-group .ember-power-select-group .ember-power-select-option {
  padding-left: 40px;
}
.ember-power-select-group .ember-power-select-option {
  padding-left: 24px;
}
.ember-power-select-group .ember-power-select-group-name {
  padding-left: 8px;
}

.ember-power-select-trigger[dir=rtl] {
  padding: 0 0 0 16px;
}
.ember-power-select-trigger[dir=rtl] .ember-power-select-selected-item, .ember-power-select-trigger[dir=rtl] .ember-power-select-placeholder {
  margin-right: 8px;
}
.ember-power-select-trigger[dir=rtl] .ember-power-select-multiple-option {
  float: right;
}
.ember-power-select-trigger[dir=rtl] .ember-power-select-trigger-multiple-input {
  float: right;
}
.ember-power-select-trigger[dir=rtl] .ember-power-select-status-icon {
  left: 5px;
  right: initial;
}
.ember-power-select-trigger[dir=rtl] .ember-power-select-clear-btn {
  left: 25px;
  right: initial;
}

.ember-power-select-dropdown[dir=rtl] .ember-power-select-group .ember-power-select-group .ember-power-select-group-name {
  padding-right: 24px;
}
.ember-power-select-dropdown[dir=rtl] .ember-power-select-group .ember-power-select-group .ember-power-select-option {
  padding-right: 40px;
}
.ember-power-select-dropdown[dir=rtl] .ember-power-select-group .ember-power-select-option {
  padding-right: 24px;
}
.ember-power-select-dropdown[dir=rtl] .ember-power-select-group .ember-power-select-group-name {
  padding-right: 8px;
}

:root {
  --sat: max(env(safe-area-inset-top), 0px);
  --sar: max(env(safe-area-inset-right), 0px);
  --sab: max(env(safe-area-inset-bottom), 0px);
  --sal: max(env(safe-area-inset-left), 0px);
}

:root {
  --admin-dashboard-header-height:50px;
  --admin-dashboard-indicator-height:0px;
  --admin-dashboard-main-offset: calc(var(--admin-dashboard-header-height) + var(--admin-dashboard-indicator-height));
}

body {
  padding-top: var(--sat);
}

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr;
  /* Side nav is hidden on mobile */
  grid-template-rows: var(--admin-dashboard-header-height) 1fr 50px;
  grid-template-areas: "header" "main" "footer";
  height: calc(100vh - var(--sat) - var(--admin-dashboard-indicator-height));
  grid-gap: 10px;
  transition: all 0.3s ease-in-out;
}

.dashboard-header {
  grid-area: header;
  background-color: #648ca6;
  z-index: 1038;
}

/* Mobile-first side nav styles */
.dashboard-side-left {
  grid-area: sideleft;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 220px;
  position: fixed;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 1034;
  transform: translateX(-250px);
  transition: all 0.3s ease-in-out;
  /* This class is toggled on pushmenu */
}
.sidebar-open .dashboard-side-left {
  transform: translateX(0);
}

.dashboard-main {
  grid-area: main;
  /*
    display: flex;
    flex-direction: column;
  */
}

.dashboard-side-right {
  grid-area: sideright;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  top: var(--admin-dashboard-main-offset);
  right: 0;
  overflow-y: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 1033;
  transform: translateX(250px);
  transition: all 0.3s ease-in-out;
  background-color: #1b1d24;
  /* This class is toggled on pushmenu */
}
.control-sidebar-slide-open .dashboard-side-right {
  transform: translateX(0);
}
.dashboard-side-right .tab-content {
  padding: 0.5rem 1rem;
}

.dashboard-footer {
  grid-area: footer;
  background-color: #648ca6;
}

/* layout */
.dashboard-header,
.dashboard-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

/* Non-mobile styles, 992px breakpoint */
@media (min-width: 992px) {
  /* Show the sidenav */
  .dashboard-container {
    grid-template-columns: min-content 1fr;
    /* Show the side nav for non-mobile screens */
    grid-template-areas: "sideleft header" "sideleft main" "sideleft footer";
  }
  .dashboard-side-left {
    display: flex;
    flex-direction: column;
    width: 220px;
    transition: width 0.5s ease;
    /* Fixed position on mobile */
    position: relative;
    transform: translateX(0);
  }
}
/* Fixed header */
.fixed-header.dashboard-container {
  grid-template-rows: 1fr 50px;
  grid-template-areas: "main" "footer";
}
.fixed-header .dashboard-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--admin-dashboard-header-height);
}

.dashboard-main {
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px;
}

.dashboard-side-left .dashboard-side-left-menu-wrap {
  background: #2a2f3e;
  border-radius: 0 0.5rem 0 0;
  overflow-y: scroll;
  flex-grow: 1;
}
.dashboard-side-left .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}
.dashboard-side-left .nav .nav-header {
  flex-shrink: 0;
  color: #7984a3;
  background-color: #1b1e27;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.75rem 1rem;
  white-space: nowrap;
  max-height: 4rem;
  transition: max-height 0.3s ease, padding 0.3s ease;
}
.dashboard-side-left .nav .nav-item {
  white-space: nowrap;
}
.dashboard-side-left .nav .nav-item > .nav-link {
  color: #b5bbcc;
  margin-bottom: 0;
}
.dashboard-side-left .nav .nav-item > .nav-link.active {
  color: #b5bbcc;
  background-color: #3b4256;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.dashboard-side-left .nav .nav-item:hover > .nav-link {
  color: #fff;
  background-color: #505974;
  border-left: 3px solid #ff8d00;
  padding-left: calc(1rem - 3px);
}
.dashboard-side-left a {
  color: #b5bbcc;
}

/* brand link */
.dashboard-brand-link {
  display: flex;
  flex-direction: column;
  min-height: var(--admin-dashboard-header-height);
  max-height: var(--admin-dashboard-header-height);
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  background-color: #262a37;
}
.dashboard-brand-link .dashboard-brand-image {
  width: 50%;
  max-width: 110px;
}

/* user info */
.dashboard-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
}
.dashboard-user-info .image {
  padding: 0 1rem;
}
.dashboard-user-info .image img {
  display: block;
  width: 2rem;
}
.dashboard-user-info .info {
  transition: opacity 0.5s ease;
}

.dashboard-container {
  color: #c1c6d5;
}

.dashboard-main {
  padding: 0 10px 0 0;
}

/* header */
.dashboard-header {
  background-color: #262a37;
}
.dashboard-header .navbar-nav {
  flex-direction: row;
}
.dashboard-header .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 1rem;
}
.dashboard-header .navbar-nav .nav-link i {
  font-size: 1rem;
  line-height: 1.5;
}
.dashboard-header .navbar-nav .pushmenu .nav-link {
  padding: 0;
}
.dashboard-header .navbar-nav .pushmenu .nav-link i {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

/* footer */
.dashboard-footer {
  color: #c1c6d5;
  border-top: 1px solid #33394a;
  background: #2a2f3e;
}

/* content */
/*
// TODO: requires rethink...
.dashboard-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-gap: 10px;
  overflow: hidden;
  padding-right: 10px;

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
*/
.content-columns {
  height: 100%;
  overflow: hidden;
  margin-right: -5px;
  margin-left: -5px;
}
.content-columns .content-left,
.content-columns .content-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 5px;
  padding-left: 5px;
}
.content-columns .content-left > .card.card-tabs,
.content-columns .content-right > .card.card-tabs {
  overflow: hidden;
}

/* small screen UI */
@media (max-width: 991.98px) {
  .dashboard-side-left {
    padding-bottom: 60px;
  }
  .dashboard-side-right {
    top: calc(var(--sat) + var(--admin-dashboard-main-offset));
    z-index: 1038;
  }
}
@media (max-width: 767.98px) {
  .dashboard-container {
    grid-template-rows: fit-content(100px) 1fr;
    grid-template-areas: "header" "main";
    grid-gap: 0;
  }
  .content-columns {
    height: unset;
    min-height: 100%;
  }
  .dashboard-header {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .dashboard-header .c-group-header {
    flex-grow: 1;
    width: 100%;
    padding-top: 0.5rem;
    background: #2a2f3e;
    padding-bottom: 0;
  }
  .dashboard-header .c-group-header:before {
    content: "";
    background: #2a2f3e;
    display: block;
    width: 100%;
    height: calc(var(--sat) + var(--admin-dashboard-indicator-height));
    position: absolute;
    top: calc(0px - var(--sat) - var(--admin-dashboard-indicator-height));
    left: 0;
    right: 0;
    pointer-events: none;
  }
  .dashboard-header .c-group-header .c-body {
    /* background: #434b62; */
    background: #2a2f3e;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .dashboard-side-left {
    padding-bottom: 5.5rem;
  }
  .dashboard-side-left .dashboard-brand-link {
    display: none;
  }
  .dashboard-side-left .dashboard-side-left-menu-wrap {
    border-radius: 0 0.5rem 0.5rem 0;
    margin-top: 50px;
  }
  .dashboard-main {
    padding: 0;
    /*
        padding-bottom: 4.5rem;
    */
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .dashboard-main-spacer {
    height: 4.5rem;
  }
  .dashboard-footer {
    height: 3rem;
    position: fixed;
    bottom: 1.5rem;
    left: 7.5px;
    right: 7.5px;
    padding: 0;
    border: none !important;
    border-radius: 0.75rem;
    z-index: 1038;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  .dashboard-footer .sgb-home-helper.top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5019607843);
    border-radius: 1rem 1rem 0 0;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .dashboard-footer .sgb-home-helper.bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0.5rem;
    background: rgba(0, 0, 0, 0.5019607843);
    border-radius: 0 0 1rem 1rem;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  .dashboard-footer .nav-tabs {
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0 1rem;
  }
  .dashboard-footer .nav-tabs .nav-item {
    flex: 1;
    text-align: center;
    flex-grow: 0;
  }
  .dashboard-footer .nav-tabs .nav-item:nth-child(3) {
    position: relative;
    top: -0.5rem;
    overflow: visible;
    border-radius: 1rem;
    flex-grow: 0;
    padding: 0;
    width: 4rem;
    height: 4rem;
    flex-basis: 4rem;
  }
  .dashboard-footer .nav-tabs .nav-item:nth-child(3) .nav-link {
    padding: 0;
  }
  .dashboard-footer .nav-tabs .nav-link {
    padding: 0.25rem 1rem;
  }
  .dashboard-footer .nav-tabs .nav-link.active {
    border-top: none;
    margin: 0;
    padding: 0.25rem 1rem;
    background: transparent !important;
  }
  .dashboard-footer .dropdown-toggle::after {
    display: none !important;
  }
  .dashboard-footer .sgb-home {
    display: inline-block;
    width: 4rem;
    height: 4rem;
  }
  .dashboard-footer .sgb-home img {
    padding: 0.25rem 0 0.25rem 0.5rem;
    width: 4rem;
    height: 4rem;
  }
  .dashboard-footer .nav-tabs .nav-link .far {
    font-size: 1.5rem;
    line-height: 2.5rem;
    vertical-align: middle;
  }
  .dashboard-footer .dropdown-menu {
    right: 0;
    left: auto;
  }
}
/* sidebar-collapse */
@media (min-width: 992px) {
  .sidebar-collapse .dashboard-side-left {
    width: 60px;
  }
  .sidebar-collapse .dashboard-brand-link .dashboard-brand-image {
    width: 90%;
  }
  .sidebar-collapse .nav-sidebar .nav-header {
    max-height: 0;
    padding: 0;
  }
  .sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-collapse .dashboard-user-info .info {
    opacity: 0;
  }
}
.nav-sidebar .nav-link p,
.dashboard-user-info .info {
  transition: opacity 0.3s ease;
}

/* sidebar overlay */
#sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1032;
}

.sidebar-open #sidebar-overlay,
.control-sidebar-slide-open #sidebar-overlay {
  display: block;
}

/* content-left */
.content-left .card,
.content-left .card-tabs,
.content-left .card-body,
.content-left .tab-content-main,
.content-left .tab-pane {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 0 !important;
}

.content-right .card-wrapper {
  min-height: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

/* content-left hierarchy page */
.card.has-scrollbar {
  overflow: hidden;
}

/*
.far {
  font-weight: 200;
}
*/
.ember-power-select-trigger {
  border-radius: 0.25rem;
  line-height: inherit;
  padding: 0.375rem 0.75rem;
}

.ember-power-select-selected-item {
  margin-left: 0;
}

.skin-sgb-night .ember-power-select-trigger {
  color: #f2f3f4;
  background-color: #5d657c;
  border-color: transparent !important;
}
.skin-sgb-night .ember-power-select-dropdown {
  color: #f2f3f4;
  background-color: #5d657c;
  border-color: #6d7792 !important;
}
.skin-sgb-night .ember-power-select-status-icon {
  border-color: #f2f3f4 transparent transparent transparent;
}
.skin-sgb-night .ember-power-select-option[aria-selected=true] {
  background-color: #f2f3f4;
  color: #5d657c;
}
.skin-sgb-night .ember-power-select-option[aria-current=true] {
  background-color: #ff851b;
  color: #f2f3f4;
}

.table th,
.table td {
  padding: 0.25rem 0.5rem;
  vertical-align: middle;
}

.bg-custom-bluegray-1 {
  background: #262933;
  color: #fff;
}

.bg-custom-bluegray-2 {
  background: #313541;
  color: #fff;
}

.bg-custom-bluegray-3 {
  background: #52596d;
  color: #fff;
}

.bg-none {
  background: transparent !important;
  color: 333333;
}

.output-container {
  position: relative;
  flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
}

.output-sizer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
}

.splitter-container {
  width: 100%;
  height: 100%;
}

.splitter-pane {
  background: #ccc;
  float: left;
}

.splitter-bar {
  background: red;
  float: left;
  height: 100%;
}

.iro-mt-fix {
  margin-top: 1.55rem;
}

.iro-mt-fix2 {
  margin-top: 1.4rem;
}

/* jsqr */
.modal-jsqr canvas, .modal-jsqr video {
  /*
      display: block;
      margin: auto;
      max-width: 100%;
  */
  max-height: 40vh;
}

.modal-daily-report h2 {
  font-size: 1.125rem;
  font-weight: normal;
  margin: 1.5rem 0 0.5rem 0;
  text-transform: uppercase;
  color: #fff;
}
.modal-daily-report h3 {
  font-size: 1rem;
  font-weight: normal;
  margin: 1.5rem 0 0.5rem 0;
  color: #fff;
}
.modal-daily-report table {
  width: 100%;
}
.modal-daily-report table td:nth-of-type(1) {
  width: 25%;
}
.modal-daily-report table td:nth-of-type(2) {
  width: 50%;
}
.modal-daily-report table td:nth-of-type(3) {
  width: 25%;
}

/* network-indicator */
.dashboard-indicator {
  position: relative;
  z-index: 1039;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-indicator.danger {
  background-color: rgba(255, 0, 0, 0.8);
}
.dashboard-indicator.warning {
  background-color: rgba(243, 156, 18, 0.8);
}

/* toast */
#toast-container > div {
  padding: 10px 10px 10px 50px;
}

.toast-info {
  background-color: rgba(243, 156, 18, 0.9333333333);
}

/* firefox */
@supports (-moz-appearance: none) {
  select {
    -moz-appearance: none !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAfklEQVR4nO3PuQ0CURBEwU4AER4EwzGZgzUWDovYv8ZUSe33SwAAAAAAAGCrc5Lrgl2SnBY1bVZJXjvvtirmV5XB8a0yOL5V/hd/X3v9fyqD41tlcHyrDI5vle/jH8dc3F9lcHyrDI5vlc/455GHjlAZHN8qg+MBAAAAAIC13tSosU8elBxsAAAAAElFTkSuQmCC");
    background-position: calc(100% - 0px) center !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
}